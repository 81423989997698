import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import '@shared/plugins'
import variables from '@/variables'

Vue.prototype.$http = axios
Vue.prototype.$qs = qs
Vue.prototype.$variables = variables

Vue.config.productionTip = false

// eslint-disable-next-line no-unused-vars
let loggedin = true;

export default {
    CreateApp: (app) => {
        new Vue({
            render: h => h(app),
          }).$mount('#app')
    }
}