<template>
    <b-row class="justify-content-end">
            <b-navbar class="small px-0 py-1 m-0" toggleable="sm" type="dark" variant="">
                <b-navbar-nav class="ml-auto">
                    <template v-for="m in mainMenu">
                        <template v-if="m.children">
                            <div @mouseover="onOver(m.MENU_ID)" @mouseleave="onLeave(m.MENU_ID)" :key="m.MENU_ID">
                                <b-nav-item-dropdown class="dropdown-hover-show" :ref="m.MENU_ID" :text="m.BESKRIVELSE" right>
                                    <div v-for="c in m.children" :key="c.MENU_ID">
                                        <b-dropdown-item :href="c.URL">{{c.BESKRIVELSE}}</b-dropdown-item>
                                    </div>
                                </b-nav-item-dropdown>
                            </div>
                        </template>
                        <template v-else>
                            <b-nav-item :href="m.URL" :key="m.MENU_ID">{{m.BESKRIVELSE}}</b-nav-item>
                        </template>
                    </template>
                </b-navbar-nav>
            </b-navbar>
        </b-row>
</template>

<script>
    import MenuUtil from '@shared/utils/MenuUtil.js';

    export default {
        name: 'vdMenu',
        props: {
            app: {
                type: String,
                required: true
            }
        },
        data: function() {
            return {
                menuUtil: new MenuUtil(),
                mainMenu: []
            }
        },

        methods: {
            async initMenu() {
                this.mainMenu = await this.menuUtil.GetMenuStructure(this.app);
            },
            onOver(element) {
                this.$refs[element][0].visible = true;
            },
            onLeave(element) {
                this.$refs[element][0].visible = false;
            }
        },

        async mounted() {
            await this.initMenu();
        }
    }
</script>

<style lang="scss" scoped>
    @import '@shared/styles/mastra-func.scss';

    .nav-item {
        background-color: $primary;
        
        +.nav-item {
            margin-left: 1px !important;
        }
    }

    .small {
        font-size: 100%;
    }
</style>