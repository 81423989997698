/* eslint-disable */

// var j_ret_default_url = "../system/RetDefaultVaerdier.html";

// var retIcon = "../img/ret.png";
// var gemIcon = "../img/filesave.png";
// var fortrydIcon = "../img/undo.png";
// var kopierIcon = "../img/kopi.png";
// var sletIcon = "../img/slet.png";
// var colapseIcon = "../img/minus.gif";
// var expandIcon = "../img/plus.gif";
// var blankIcon = "../img/blank.gif";
// var input_reduktion = 5;

// var j_antal_gemXX = 0;

// var j_vis_raekker_alle = 99999;

// var j_x_popup_offset = 50;
// var j_y_popup_offset = 50;
// var j_count = 0;

// var ELpntr = false;

// Date.prototype.format = function (format) {
//   var returnStr = "";
//   var replace = Date.replaceChars;
//   for (var i = 0; i < format.length; i++) {
//     var curChar = format.charAt(i);
//     if (replace[curChar]) {
//       returnStr += replace[curChar].call(this);
//     } else {
//       returnStr += curChar;
//     }
//   }
//   return returnStr;
// };

// Date.replaceChars = {
//   shortMonths: [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "Maj",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Okt",
//     "Nov",
//     "Dec",
//   ],
//   longMonths: [
//     "Januar",
//     "Februar",
//     "Marts",
//     "April",
//     "Maj",
//     "Juni",
//     "Juli",
//     "August",
//     "September",
//     "Oktober",
//     "November",
//     "December",
//   ],
//   shortDays: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
//   longDays: [
//     "Søndag",
//     "Mandag",
//     "Tirsdag",
//     "Onsdag",
//     "Torsdag",
//     "Fredag",
//     "Lørdag",
//   ],

//   // Day
//   d: function () {
//     return (this.getDate() < 10 ? "0" : "") + this.getDate();
//   },
//   D: function () {
//     return Date.replaceChars.shortDays[this.getDay()];
//   },
//   j: function () {
//     return this.getDate();
//   },
//   l: function () {
//     return Date.replaceChars.longDays[this.getDay()];
//   },
//   N: function () {
//     return this.getDay() + 1;
//   },
//   S: function () {
//     return this.getDate() % 10 == 1 && this.getDate() != 11
//       ? "st"
//       : this.getDate() % 10 == 2 && this.getDate() != 12
//       ? "nd"
//       : this.getDate() % 10 == 3 && this.getDate() != 13
//       ? "rd"
//       : "th";
//   },
//   w: function () {
//     return this.getDay();
//   },
//   z: function () {
//     return "Not Yet Supported";
//   },
//   // Week
//   W: function () {
//     return "Not Yet Supported";
//   },
//   // Month
//   F: function () {
//     return Date.replaceChars.longMonths[this.getMonth()];
//   },
//   m: function () {
//     return (this.getMonth() < 9 ? "0" : "") + (this.getMonth() + 1);
//   },
//   M: function () {
//     return Date.replaceChars.shortMonths[this.getMonth()];
//   },
//   n: function () {
//     return this.getMonth() + 1;
//   },
//   t: function () {
//     return "Not Yet Supported";
//   },
//   // Year
//   L: function () {
//     return "Not Yet Supported";
//   },
//   o: function () {
//     return "Not Supported";
//   },
//   Y: function () {
//     return this.getFullYear();
//   },
//   y: function () {
//     return ("" + this.getFullYear()).substr(2);
//   },
//   // Time
//   a: function () {
//     return this.getHours() < 12 ? "am" : "pm";
//   },
//   A: function () {
//     return this.getHours() < 12 ? "AM" : "PM";
//   },
//   B: function () {
//     return "Not Yet Supported";
//   },
//   g: function () {
//     return this.getHours() % 12 || 12;
//   },
//   G: function () {
//     return this.getHours();
//   },
//   h: function () {
//     return (
//       ((this.getHours() % 12 || 12) < 10 ? "0" : "") +
//       (this.getHours() % 12 || 12)
//     );
//   },
//   H: function () {
//     return (this.getHours() < 10 ? "0" : "") + this.getHours();
//   },
//   i: function () {
//     return (this.getMinutes() < 10 ? "0" : "") + this.getMinutes();
//   },
//   s: function () {
//     return (this.getSeconds() < 10 ? "0" : "") + this.getSeconds();
//   },
//   // Timezone
//   e: function () {
//     return "Not Yet Supported";
//   },
//   I: function () {
//     return "Not Supported";
//   },
//   O: function () {
//     return (
//       (-this.getTimezoneOffset() < 0 ? "-" : "+") +
//       (Math.abs(this.getTimezoneOffset() / 60) < 10 ? "0" : "") +
//       Math.abs(this.getTimezoneOffset() / 60) +
//       "00"
//     );
//   },
//   T: function () {
//     var m = this.getMonth();
//     this.setMonth(0);
//     var result = this.toTimeString().replace(/^.+ \(?([^\)]+)\)?$/, "$1");
//     this.setMonth(m);
//     return result;
//   },
//   Z: function () {
//     return -this.getTimezoneOffset() * 60;
//   },
//   // Full Date/Time
//   c: function () {
//     return "Not Yet Supported";
//   },
//   r: function () {
//     return this.toString();
//   },
//   U: function () {
//     return this.getTime() / 1000;
//   },
// };

let exports = {};

// export default class AjaxUtil {
// exports.startSoeg_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoeg('f1')", 50);
// };

// exports.startSoegW_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegW('f1')", 50);
// };

// exports.startSoegWMD_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegWMD('f1')", 50);
// };

// exports.startSoegWPos_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegWPos('f1')", 50);
// };

// exports.startSoegRet_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegRet('f1')", 50);
// };

// exports.startSoegRetPos_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegRetPos('f1')", 50);
// };

// exports.startSoegUpd_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegUpd('f1')", 50);
// };

// exports.startSoegMD_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegMD('f1')", 50);
// };

// exports.startSoegIndlaes_f1 = () => {
//   ventOn();
//   window.setTimeout("startSoegIndlaes('f1')", 50);
// };

// exports.genopfrisk_f1 = () => {
//   ventOn();
//   window.setTimeout("genopfrisk_x('f1')", 50);
// };

// exports.genopfrisk_x = (c_f) => {
//   submitSoegIndlaes(0, j_raekke_fra, j_raekke_til, 1, c_f, 27);
//   ventOff();
// };

// exports.gemOpdater_f1 = () => {
//   ventOn();
//   window.setTimeout("gemOpdater('f1')", 50);
// };

// exports.ventOff = () => {
//   document.getElementById("ajaxSpinner").style.display = "none";
// };

// exports.ventOn = () => {
//   document.getElementById("ajaxSpinner").style.display = "block";
// };

// exports.startSoeg = (c_f) => {
//   submitSoeg(0, 1, j_vis_raekker, 1, c_f, 1);
//   ventOff();
// };

// exports.startSoegW = (c_f) => {
//   submitSoeg(0, 1, j_vis_raekker, 1, c_f, 25);
//   ventOff();
// };

// exports.startSoegWMD = (c_f) => {
//   submitSoegMD(0, 1, j_vis_raekker, 1, c_f, 32);
//   ventOff();
// };

// exports.startSoegWPos = (c_f) => {
//   submitSoeg(0, j_raekke_fra, j_raekke_til, 1, c_f, 25);
//   ventOff();
// };

// exports.startSoegRet = (c_f) => {
//   submitSoegUdenId(0, 1, j_vis_raekker, 1, c_f, 24);
//   ventOff();
// };

// exports.startSoegRetPos = (c_f) => {
//   submitSoeg(0, j_raekke_fra, j_raekke_til, 1, c_f, 24);
//   ventOff();
// };

// exports.startSoegUpd = (c_f) => {
//   submitSoeg(0, 1, j_vis_raekker, 1, c_f, 10);
//   ventOff();
// };

// exports.startSoegMD = (c_f) => {
//   submitSoegMD(0, 1, j_vis_raekker, 1, c_f, 11);
//   ventOff();
// };

// exports.startSoegIndlaes = (c_f) => {
//   submitSoegIndlaes(0, 1, j_vis_raekker, 1, c_f, 27);
//   ventOff();
// };

exports.makeRequest = (
  url,
  parameters,
  c_f,
  c_req_id,
  c_field_id,
  c_rownum
) => {
  var http_request = false;
  if (window.XMLHttpRequest) {
    // Mozilla, Safari,...
    http_request = new XMLHttpRequest();

    if (http_request.overrideMimeType) {
      // set type accordingly to anticipated content type
      http_request.overrideMimeType("text/html");
    }
  } else if (window.ActiveXObject) {
    // IE
    try {
      http_request = new ActiveXObject("Msxml2.XMLHTTP");
    } catch (e) {
      try {
        http_request = new ActiveXObject("Microsoft.XMLHTTP");
      } catch (e) {}
    }
  }

  if (!http_request) {
    alert("Cannot create XMLHTTP instance");
    return false;
  }

  var j_f = c_f; //  Sæt aktuel form
  var url1 = url + parameters; // samlet URL
  var url2 = url1.substr(0, url1.indexOf("?") + 1);
  var parameters2 = url1.substr(url1.indexOf("?") + 1);

  if (c_req_id == 8) {
    // Menu bar
    var c_path = document.location.pathname;
    url2 = "/ords/mastra/osa.im_trans.get_menu?";

    parameters2 = "i_div_id=" + parameters;

    if (c_path.indexOf("/mastraudv/") >= 0)
      parameters2 = "i_test=1&i_div_id=" + parameters;
    
    var mastra_type = GetCookieExt("mastra_type");

    if (mastra_type == "i") parameters2 += "&i_app=iMastra";

    if (mastra_type == "k") parameters2 += "&i_app=kMastra";

    if (mastra_type == "n") parameters2 += "&i_app=Noegletal";
  }

  parameters2 = parameters2
    .replace(/</g, "%3C")
    .replace(/>/g, "%3E")
    .replace(/'/g, "%27")
    .replace(/ /g, "%20")
    .replace(/"/g, "%22");

  if (c_req_id == 3 || c_req_id == 5 || c_req_id == 19 || c_req_id == 7) {
    var dd = new Date();

    parameters2 += "&i_timestamp=" + dd.getMilliseconds();
  }

  if (parameters2.length) {
    http_request.open("GET", url2 + parameters2, false); // boolean: true (asynchronous) false (synchronous);
  } else {
    http_request.open("GET", url2, false); // boolean: true (asynchronous) false (synchronous);
  }

  if(typeof loggedin == 'undefined' || loggedin){
    http_request.send(parameters2);
  

  if (http_request.readyState == 4) {
    if (http_request.status == 200) {
      var result = http_request.responseText;
      switch (c_req_id) {
        case 1: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContents(result);
          break;
        case 2: // Udfylder felterne et rette-skærmbillede
          exports.fillContents(result);
          break;
        case 3: // Returnere én værdi  (ikke XML)
          exports.quickContents(parameters, result);
          break;
        case 4: // Opfyldning af List-of-values
          exports.lovContents(parameters, result, c_field_id, c_rownum);
          break;
        case 5: // Håndterer sletning
          exports.sletContents(result);
          break;
        case 6:
          exports.cookieContents(parameters, result);
          break;
        case 7: // Eksekverer procedurer
          exports.eksekverContents(result);
          break;
        case 8: // Henter menu
          exports.menuContents(parameters, result, c_field_id);
          break;
        case 9: //  Simpel select returnerer en værdi
          var resdom = exports.parseXml(result);
          var fejl = exports.getError(resdom);
          if (fejl == 0) {
            if (resdom.getElementsByTagName(c_field_id).length != 0) {
              return resdom.getElementsByTagName(c_field_id)[0].childNodes[0]
                .nodeValue;
            } else {
              return undefined; // no data found
            }
          } else {
            var c_resstr = exports.serializeDom(resdom);
            alert("Modtaet XML " + c_resstr);
            var result = "<div>";
            result =
              result +
              "SQL:" +
              resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
              "<br><br>";
            result =
              result +
              "FEJL:" +
              resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
              "<br><br>";
            result = result + "</div>";
            document.getElementById(j_span).innerHTML = result;
          }
          break;
        case 10: // Søgning som i 1, men retur-felterne kan være input-felter til at rette i
          exports.showContentsUpd(result);
          break;
        case 11: // Søgning i søgeskærmbilleder master/detail, udfylder span
          exports.showContentsMD(result);
          break;
        case 12: // Søgning i søgeskærmbilleder master/detail, udfylder span
          exports.showContentsRetMD(result);
          exports.ventOff();
          break;
        case 13: // Opfyldning af List-of-values
          exports.lovContentsMD(parameters, result, c_field_id);
          break;
        case 14:
          exports.opretContentsMD(result);
          break;
        case 15:
          exports.sletContentsMD(result);
          break;
        case 16:
          exports.opdaterContentsMD(result, c_rownum);
          break;
        case 17:
          exports.opretContentsMDM(result);
          break;
        case 18:
          exports.sletContentsMDM(result);
          break;
        case 19:
          return result;
          break;
        case 20: // Søgning i søgeskærmbilleder udfylder span
          exports.showContentsRet(result);
          exports.ventOff();
          break;
        case 21:
          exports.opdaterContents(result, c_rownum);
          break;
        case 22:
          exports.opretContents(result);
          break;
        case 23:
          exports.sletContentsM(result);
          break;
        case 24: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentsSoegRet(result);
          break;
        case 25: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentsW(result);
          break;
        case 26: // Søgning i søgeskærmbilleder udfylder span
          exports.showContentsRetForm(result);
          exports.ventOff();
          break;
        case 27: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentsSoegRetIndlaes(result);
          break;
        case 28: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentExcel(result);
          break;
        case 29:
          exports.opdaterContentsIndlaes(result, c_rownum);
          break;
        case 30: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentNN(result);
          break;
        case 31:
          exports.checkPasswordAge(result);
          break;
        case 32: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentsWMD(result);
          break;
        case 33:
          exports.opdaterContentsM(result, c_rownum);
          break;
        case 34:
          exports.sletContentsMM(result);
          break;
        case 35: // Søgning i søgeskærmbilleder , udfylder span
          exports.showContentExcelMD(result);
          break;
        case 36:
          exports.beskedContents(result);
          break;
        case 37: // Søgning i søgeskærmbilleder master/detail, udfylder span
          exports.showContentsMDproc(result);
          break;
        case 38:
          return result;
          break;
        default:
          alert("Her burde vi ikke komme ned, c_req_id = " + c_req_id);
      }
    } else if (http_request.status == 404) {
      alert("Fejl i databaseprogram. Kontakt venligst Mastra Support");
    } else if(http_request.status == 401) {
      loggedin = false;
    } else {
      alert(
        "Denne forespørgsel på " +
          c_req_id +
          " kunne ikke udføres - check det indtastede." +
          http_request.readyState +
          " " +
          http_request.status
      );
    }
  } else {
    alert("Forkert readyState, readyState=" + http_request.readyState);
  }
}
};

exports.visFelt = (c_id) => {
  if (document.getElementById(c_id + "_Q") != null) {
    if (document.getElementById(c_id + "_Q").checked) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

exports.showContents = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);
    var result = new Array();
    //
    //  Kolonneoverskrift
    //
    result.push(
      '<div  class="resultTable" id="resultTable"><table cellspacing="1" cellpadding="2">'
    );
    result.push("<tr>");
    for (var j = 0; j < j_kol_tekst.length; j++) {
      var k = j + 1;

      if (typeof j_hide != "undefined" && j_hide[j] == 1) {
        var style1 = "display: none;";
      } else {
        var style1 = "";
      }

      if (typeof j_kol_width != "undefined" && j_kol_width[j] != null)
        var style2 = "width:" + j_kol_width[j] + ";";
      else var style2 = "";

      style = 'style="' + style1 + style2 + '"';

      if (j_kol_type[j] == null && j < j_sort.length) {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            "<a href=\u0022javascript:sorter(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst[j] +
            "</th>"
        );
      } else {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            j_kol_tekst[j] +
            "</th>"
        );
      }
    }

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret</th>");
    }

    if (typeof kopierOpret != "undefined") {
      result.push("<th>Kopi</th>");
    }

    result.push("</tr>");

    var x01 = resdom.getElementsByTagName(j_vis_kol[1]);

    //
    //  Data
    //
    var c_counter = 0;
    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;
      result.push(
        '<tr class="trNormal" onmouseover="highlightRow(this);" onmouseout="normalRow(this)">'
      );
      for (var j = 0; j < j_vis_kol.length; j++) {
        if (j_kol_type[j] == null) {
          if (j_vis_kol[j] != null) {
            //
            //     Fremvisning
            //

            if (typeof j_hide != "undefined" && j_hide[j] == 1) {
              var style = "display: none";
            } else {
              var style = "";
            }

            var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
              .childNodes[0];

            if (typeof j_css_class != "undefined" && j_css_class[j])
              var c_class = j_css_class[j];
            else var c_class = "left";

            if (typeof j_title != "undefined" && j_title[j] == 1) {
              var title = titleText(resdom, j, i);
            } else {
              var title = "";
            }

            if (!testnull) {
              result.push(
                "<td id='" +
                  j_vis_kol[j] +
                  i +
                  "' class=\u0022" +
                  c_class +
                  "\u0022 title='" +
                  title +
                  "' style='" +
                  style +
                  "'></td>"
              );
            } else {
              if (typeof j_kol_width != "undefined" && j_kol_width[j] != null)
                result.push(
                  "<td id='" +
                    j_vis_kol[j] +
                    i +
                    "' class=\u0022" +
                    c_class +
                    "\u0022 title='" +
                    title +
                    "' style='" +
                    style +
                    "'><div style='width:" +
                    j_kol_width[j] +
                    "; overflow: auto'>" +
                    resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                      .nodeValue +
                    "</div></td>"
                );
              else
                result.push(
                  "<td id='" +
                    j_vis_kol[j] +
                    i +
                    "' class=\u0022" +
                    c_class +
                    "\u0022 title='" +
                    title +
                    "' style='" +
                    style +
                    "'>" +
                    resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                      .nodeValue +
                    "</td>"
                );
            }
          }
        } else {
          if (j_kol_type[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //

            if (
              resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td  class=\u0022left\u0022>" +
                  "<input type=\u0022CHECKBOX\u0022  style=\u0022width: 95%\u0022  id='" +
                  j_vis_kol[j] +
                  i +
                  "'" +
                  " onClick=\u0022" +
                  j_id_funk[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022 />" +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input type=\u0022CHECKBOX\u0022   style=\u0022width: 95%\u0022  id='" +
                  j_vis_kol[j] +
                  i +
                  "'" +
                  " onClick=\u0022" +
                  j_id_funk[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022 checked />" +
                  "</td>"
              );
            }
          }
          if (j_kol_type[j] == "LINK") {
            //
            //     LINK
            //
            var l_style = "";
            if (typeof linkFunction != "undefined") {
              l_style = linkFunction(resdom, i, j);
            }

            if (typeof link_gif != "undefined")
              var gif = link_gif(resdom, i, j);
            else var gif = j_id_gif[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a style='" +
                l_style +
                "' href=\u0022javascript:" +
                j_id_funk[j] +
                "('" +
                j_f +
                "','" +
                resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                "'," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 alt=\u0022" +
                j_alt_tekst[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }
        }
      }

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push('<td class="left" width="30px"></td>');
        } else {
          result.push(
            "<td align=\u0022center\u0022 width=\u002230px\u0022><a href=\u0022javascript:retRaekke('" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) + "]," + RaekkeTil
          );
          result.push(');"><img src="' + retIcon + '" border="0"/></a></td>');
        }
      }

      if (typeof kopierOpret != "undefined") {
        result.push(
          "<td align=\u0022center\u0022 ><a href=\u0022javascript:opretRaekkeKopi('" +
            j_f +
            "',["
        );
        for (var jj = 0; jj < j_p_key.length; jj++) {
          result.push(
            "'" +
              resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                .nodeValue +
              "',"
          );
        }
        result_str = result.pop();
        result.push(
          result_str.substr(0, result_str.length - 1) + "]," + RaekkeTil
        );
        result.push(');"><img src="' + kopierIcon + '" border="0"/></a></td>');
      }

      result.push("</tr>");
    } // for-loop end

    result.push("</table></div>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    result.push(
      "<div style='position: relative' class=\u0022resultsNavigation\u0022>"
    );
    if (
      (Math.min(RaekkeFra, antal) != 1 ||
        Math.min(RaekkeTil, antal) != antal) &&
      antal > 0
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      result.push(
        "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
          "\u0022javascript: visAlle();\u0022 " +
          " />Vis alle"
      );
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlle();\u0022 " +
            " />Vis alle"
        );
      }
    }

    if (typeof j_udtraek_til_excel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; position: relative; left: 380px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
          "\u0022javascript:submitSoegExcel();\u0022 " +
          " value=\u0022Udtræk til Excel\u0022/>"
      );
    }

    if (typeof j_udtraek_til_nn != "undefined") {
      result.push(udtraek_til_nn_html());
    }

    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");

    for (var i = 0; i < c_counter; i++) {
      for (var j = 0; j < j_vis_kol.length; j++) {
        if (typeof j_post_function != "undefined" && j_post_function[j]) {
          eval(j_post_function[j] + "(" + i + ")");
        }
      }
    }
  } else if (fejl < 0) {
    ventOff();
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }

  if (navigator.appName != "Netscape") {
    if (document.getElementById("resultTable").scrollWidth > 900) {
      document.getElementById("resultTable").style.height =
        document.getElementById("resultTable").offsetHeight +
        parseInt(16) +
        "px";
    }
  }

  ventOff();
};

exports.showContentsW = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);
    var result = new Array();
    //
    //  Kolonneoverskrift
    //
    result.push(
      '<div  class="resultTable" id="resultTable"><table cellspacing="1" cellpadding="2">'
    );
    result.push("<tr>");

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret</th>");
    }

    if (typeof kopierOpret != "undefined") {
      result.push("<th>Kopi</th>");
    }

    for (var j = 0; j < j_kol_tekst.length; j++) {
      var k = j + 1;

      if (
        (typeof j_hide != "undefined" && j_hide[j] == 1) ||
        !visFelt(j_vis_kol[j])
      ) {
        var style = 'style="display: none"';
      } else {
        var style = 'style="white-space: nowrap"';
      }

      if (j_kol_type[j] == null && j < j_sort.length) {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            "<a href=\u0022javascript:sorter(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst[j] +
            "</th>"
        );
      } else {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            j_kol_tekst[j] +
            "</th>"
        );
      }
    }

    result.push("</tr>");

    var x01 = resdom.getElementsByTagName(j_tabelview);
    //
    //  Data
    //
    var c_counter = 0;
    j_count = 0;
    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;
      result.push(
        '<tr class="trNormal" onmouseout="normalRowS(this,!document.getElementById(' +
          "'GEM" +
          i +
          "'" +
          ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
          "'GEM" +
          i +
          "'" +
          ').style.display);">'
      );

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push(
            '<td class="center" width="50px"><a id=\u0022GEM' +
              i +
              '\u0022 style="display: none"/><a id=\u0022RET' +
              i +
              '\u0022 style="display: none"/></td>'
          );
        } else {
          result.push(
            "<td align=\u0022center\u0022 width=\u002250\u0022><div style='margin: 0px 0px 0px 2px; width: 50px'><a title='Ret' id=\u0022RET" +
              i +
              "\u0022 href=\u0022javascript:retRaekke('" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) +
              "]," +
              i +
              "," +
              parseInt(
                parseInt(Math.min(RaekkeTil, antal)) - parseInt(RaekkeFra) + 1
              )
          );
          result.push(');"><img src="' + retIcon + '" border="0"/></a>');

          result.push(
            "<a title='Gem' id=\u0022GEM" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Gem(" +
              i +
              ",["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + gemIcon + '" border="0"/></a>');

          if (typeof "Slet" != "undefined") {
            result.push(
              "<a title='Slet' id=\u0022SLET" +
                i +
                "\u0022 style='display: none' href=\u0022javascript: Slet(" +
                i +
                ",["
            );
            for (var jj = 0; jj < j_p_key.length; jj++) {
              result.push(
                "'" +
                  resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                    .nodeValue +
                  "',"
              );
            }

            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 1) + "]");
            result.push(');"><img src="' + sletIcon + '" border="0"/></a>');
          }

          result.push(
            "<a title='Fortryd' id=\u0022FORTRYD" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Fortryd(["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + fortrydIcon + '" border="0"/></a>');

          result.push("</div></td> ");
        }
      }

      for (var j = 0; j < j_vis_kol.length; j++) {
        if (typeof kopierOpret != "undefined") {
          result.push(
            "<td align=\u0022center\u0022 ><a href=\u0022javascript:opretRaekkeKopi('" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) + "]," + RaekkeTil
          );
          result.push(
            ');"><img src="' + kopierIcon + '" border="0"/></a></td>'
          );
        }

        if (j_kol_type[j] == null) {
          if (j_vis_kol[j] != null && visFelt(j_vis_kol[j])) {
            //
            //     Fremvisning
            //

            if (typeof j_hide != "undefined" && j_hide[j] == 1) {
              var style = 'style="display: none"';
            } else {
              var style = 'style="white-space: nowrap"';
            }

            var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
              .childNodes[0];

            if (!testnull) {
              if (j_input[j]) {
                result.push(
                  "<td " +
                    style +
                    ' class=\u0022test\u0022><input style="width: 100%; border: 0px;"  id="' +
                    j_vis_kol[j] +
                    i +
                    '" type="text" readonly value="" /> </td>'
                );
              } else {
                result.push(
                  "<td " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    i +
                    '" class=\u0022test\u0022> </td>'
                );
              }
            } else {
              if (j_input[j]) {
                result.push(
                  "<td " +
                    style +
                    ' class=\u0022test\u0022><input style="width: 100%; border: 0px;" id="' +
                    j_vis_kol[j] +
                    i +
                    '" type="text" readonly value="' +
                    resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                      .nodeValue +
                    '" /> </td>'
                );
              } else {
                result.push(
                  "<td " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    i +
                    '" class=\u0022test\u0022>' +
                    resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                      .nodeValue +
                    "</td>"
                );
              }
            }
          }
        } else {
          if (j_kol_type[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //
            if (
              resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input type=\u0022CHECKBOX\u0022  style=\u0022width: 95%\u0022  id='" +
                  j_vis_kol[j] +
                  i +
                  "'" +
                  " onClick=\u0022" +
                  j_id_funk[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022 />" +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input type=\u0022CHECKBOX\u0022   style=\u0022width: 95%\u0022  id='" +
                  j_vis_kol[j] +
                  i +
                  "'" +
                  " onClick=\u0022" +
                  j_id_funk[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022 checked />" +
                  "</td>"
              );
            }
          }

          if (j_kol_type[j] == "LINK") {
            //
            //     LINK
            //
            var l_style = "";
            if (typeof linkFunction != "undefined") {
              l_style = linkFunction(resdom, i, j);
            }

            result.push(
              "<td align=\u0022center\u0022>" +
                "&nbsp;" +
                "<a style='" +
                l_style +
                "' href=\u0022javascript:" +
                j_id_funk[j] +
                "('" +
                j_f +
                "','" +
                resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                "'," +
                RaekkeFra +
                "," +
                RaekkeTil +
                ");\u0022>" +
                "<img src=\u0022" +
                j_id_gif[j] +
                "\u0022 border=\u00220\u0022 alt=\u0022" +
                j_alt_tekst[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }
        }
      }

      result.push("</tr>");
      j_count++;
    } // for-loop end

    result.push("</table></div>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    result.push("<div class=\u0022resultsNavigation\u0022>");
    if (
      (Math.min(RaekkeFra, antal) != 1 ||
        Math.min(RaekkeTil, antal) != antal) &&
      antal > 0
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaetW(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaetW(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaetW(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaetW(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      result.push(
        "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
          "\u0022javascript: visAlleW();\u0022 " +
          " />Vis alle"
      );
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlleW();\u0022 " +
            " />Vis alle"
        );
      }
    }

    var offset = 0;

    if (typeof j_udtraek_til_excel != "undefined") {
      offset = offset + parseInt(1);
    }

    if (typeof j_ekstra_knap != "undefined") {
      offset = offset + parseInt(1);
    }

    if (typeof j_ekstra_knap1 != "undefined") {
      offset = offset + parseInt(1);
    }

    offset = 520 - offset * 100;

    if (typeof j_udtraek_til_excel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
          "\u0022javascript:submitSoegExcel();\u0022 " +
          " value=\u0022Udtræk til Excel\u0022/>"
      );
    }

    if (typeof j_ekstra_knap != "undefined") {
      result.push(
        "<input style=\u0022width:100px;  position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022ekstra\u0022 onClick=" +
          "\u0022javascript:ekstraKnap();\u0022 " +
          " value=\u0022" +
          j_ekstra_knap +
          "\u0022/>"
      );
    }

    if (typeof j_ekstra_knap1 != "undefined") {
      result.push(
        "<input style=\u0022width:100px;  position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022ekstra1\u0022 onClick=" +
          "\u0022javascript:ekstraKnap1();\u0022 " +
          " value=\u0022" +
          j_ekstra_knap1 +
          "\u0022/>"
      );
    }

    result.push("</div>");

    document.getElementById(j_span).innerHTML = result.join("");
  } else if (fejl < 0) {
    ventOff();
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }
  if (navigator.appName != "Netscape") {
    if (document.getElementById("resultTable").scrollWidth > 900) {
      document.getElementById("resultTable").style.height =
        document.getElementById("resultTable").offsetHeight +
        parseInt(16) +
        "px";
    }
  }

  ventOff();
};

exports.showContentsWMD = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);
    var result = new Array();
    //
    //  Kolonneoverskrift
    //
    result.push(
      '<div class="resultTable" id="resultTable"><table style="width: 900px" cellspacing="1" cellpadding="2">'
    );
    result.push("<tr>");

    result.push("<th></th>");

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret</th>");
    }

    if (typeof j_split_master != "undefined") {
      result.push("<th></th>");
    }

    if (typeof kopierOpret != "undefined") {
      result.push("<th>Kopi</th>");
    }

    for (var j = 0; j < j_kol_tekst_master.length; j++) {
      if (typeof j_split_master != "undefined" && j == j_split_master) {
        if (retteMulighedS(i, resdom)) {
          result.push("<th>" + "Opret<br/>" + j_tabel_detail_navn + "</th>");
        }
        result.push(
          '</tr><tr id="altHeader" style="display: none"><th colspan=3></th>'
        );
      }

      var k = j + 1;

      if (
        (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) ||
        !visFelt(j_vis_kol_master[j])
      ) {
        var style = 'style="display: none"';
      } else {
        var style = 'style="white-space: nowrap"';
      }

      if (j_kol_type_master[j] == null && j < j_sort_master.length) {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst_master[j] +
            "] body=[" +
            j_alt_tekst_master[j] +
            ']">' +
            "<a href=\u0022javascript:sorterMD(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst_master[j]
        );
      } else {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst_master[j] +
            "] body=[" +
            j_alt_tekst_master[j] +
            ']">' +
            j_kol_tekst_master[j]
        );
      }

      if (typeof j_combo_master != "undefined" && j_combo_master[j] == 1) {
        result.push(
          '<span id="' +
            j_vis_kol_master[j].toLowerCase() +
            '_indicator" style="display: none"><img src="../img/indicator_arrows.gif" alt="Vent..." />'
        );
      }

      result.push("</th>");
    }

    result.push("</tr>");

    var x01 = resdom.getElementsByTagName(j_vis_kol_master[0]);
    //
    //  Data
    //
    var c_counter = 0;
    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;

      result.push(
        '<tr class="trNormal" onmouseover="highlightRow(this);" onmouseout="normalRow(this)">'
      );

      if (
        resdom
          .getElementsByTagName("DETAILS")
          [i].getElementsByTagName("DETAIL")[0]
          .getElementsByTagName(j_p_key_detail[0] + "_PK")[0].childNodes[0]
      ) {
        result.push(
          "<td width=\u002210\u0022><a href='#" +
            i +
            "' style='text-decoration: none'"
        );
        result.push(
          "onclick=\u0022showHide('detailid" +
            i +
            "','linkid" +
            i +
            "'); \u0022"
        );
        result.push(
          "><span id='linkid" +
            i +
            "' class='linkspan'><img border=\u00220\u0022 src=\u0022../img/plus.gif\u0022></span></a></td>"
        );
      } else {
        result.push("<td width=\u002210\u0022>");
        result.push("</td>");
      }

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push(
            '<td class="center" width="50px"><a id=\u0022GEM' +
              i +
              '\u0022 style="display: none"/><a id=\u0022RET' +
              i +
              '\u0022 style="display: none"/></td>'
          );
        } else {
          result.push(
            "<td align=\u0022center\u0022 width=\u002250\u0022><div style='margin: 0px 0px 0px 2px; width: 50px'><a title='Ret' id=\u0022RET" +
              i +
              "\u0022 href=\u0022javascript:retRaekkeMD('" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key_master.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key_master[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) +
              "]," +
              i +
              "," +
              parseInt(
                parseInt(Math.min(RaekkeTil, antal)) - parseInt(RaekkeFra) + 1
              )
          );
          result.push(');"><img src="' + retIcon + '" border="0"/></a>');

          result.push(
            "<a title='Gem' id=\u0022GEM" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Gem(" +
              i +
              ",["
          );
          for (var jj = 0; jj < j_p_key_master.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key_master[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + gemIcon + '" border="0"/></a>');

          if (typeof "Slet" != "undefined") {
            result.push(
              "<a title='Slet' id=\u0022SLET" +
                i +
                "\u0022 style='display: none' href=\u0022javascript: Slet(" +
                i +
                ",["
            );
            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              result.push(
                "'" +
                  resdom.getElementsByTagName(j_p_key_master[jj])[i]
                    .childNodes[0].nodeValue +
                  "',"
              );
            }

            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 1) + "]");
            result.push(');"><img src="' + sletIcon + '" border="0"/></a>');
          }

          result.push(
            "<a title='Fortryd' id=\u0022FORTRYD" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Fortryd(["
          );
          for (var jj = 0; jj < j_p_key_master.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key_master[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + fortrydIcon + '" border="0"/></a>');

          result.push("</div></td> ");
        }
      }

      if (typeof j_split_master != "undefined") {
        result.push(
          "<td width=\u002210\u0022><a href='#" +
            i +
            "' style='text-decoration: none'"
        );
        result.push(
          "onclick=\u0022showHideH('splitid" +
            i +
            "','splitlinkid" +
            i +
            "'); \u0022"
        );
        result.push(
          "><span id='splitlinkid" +
            i +
            "' class='linkspan'><img border=\u00220\u0022 src=\u0022../img/plus.gif\u0022></span></a></td>"
        );
      }

      for (var j = 0; j < j_vis_kol_master.length; j++) {
        if (typeof j_split_master != "undefined" && j == j_split_master) {
          if (!retteMulighedS(i, resdom)) {
            result.push(
              '<td class="center" width="50px"><a id=\u0022GEM' +
                i +
                '\u0022 style="display: none"/><a id=\u0022RET' +
                i +
                '\u0022 style="display: none"/></td>'
            );
          } else {
            result.push(
              '<td align=\u0022center\u0022 width=\u002250\u0022><div style="margin: 0px 0px 0px 2px; width: 50px"><a onClick="addDetail(['
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              result.push(
                "'" +
                  resdom.getElementsByTagName(j_p_key_master[jj])[i]
                    .childNodes[0].nodeValue +
                  "',"
              );
            }
            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 1));
            result.push(
              '])"><img src="' + retIcon + '" border="0"/></a></div></td>'
            );
          }

          result.push(
            '</tr><tr id="splitid' +
              i +
              '" style="display: none"><td colspan=3></td>'
          );
        }

        if (j_kol_type_master[j] == null) {
          if (j_vis_kol_master[j] != null) {
            //
            //     Fremvisning
            //

            var testnull = resdom.getElementsByTagName(j_vis_kol_master[j])[i]
              .childNodes[0];
            if (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) {
              var style_text = "style='display: none;'";
            } else {
              var style_text = "";
            }

            if (
              typeof j_css_class_master != "undefined" &&
              j_css_class_master[j]
            )
              var c_class = j_css_class_master[j];
            else var c_class = "left";

            if (!testnull) {
              if (j_input_master[j]) {
                result.push(
                  "<td " +
                    style_text +
                    ' class=\u0022test\u0022><div><input style="width: 100%; border: 0px;"  id="' +
                    j_vis_kol_master[j] +
                    i +
                    '" type="text" readonly value="" />'
                );

                if (
                  typeof j_combo_master != "undefined" &&
                  j_combo_master[j] == 1
                ) {
                  if (j_combo_width_master[j]) {
                    var c_style =
                      'style="z-index: 2; width: ' +
                      j_combo_width_master[j] +
                      ';"';
                  } else {
                    var c_style = 'style="z-index: 2"';
                  }
                  result.push(
                    '<div id="' +
                      j_vis_kol_master[j] +
                      "_CHOICES" +
                      i +
                      '" class="autocomplete" ' +
                      c_style +
                      "></div>"
                  );
                }

                result.push("</div></td>");
              } else {
                result.push(
                  "<td " +
                    style_text +
                    ' id="' +
                    j_vis_kol_master[j] +
                    i +
                    '" class=\u0022test\u0022> </td>'
                );
              }
            } else {
              if (j_input_master[j]) {
                result.push(
                  "<td " +
                    style_text +
                    ' class=\u0022test\u0022><div><input style="width: 100%; border: 0px;" id="' +
                    j_vis_kol_master[j] +
                    i +
                    '" type="text" readonly value="' +
                    resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                      .childNodes[0].nodeValue +
                    '" /> '
                );

                if (
                  typeof j_combo_master != "undefined" &&
                  j_combo_master[j] == 1
                ) {
                  if (j_combo_width_master[j]) {
                    var c_style =
                      'style="z-index: 2; width: ' +
                      j_combo_width_master[j] +
                      ';"';
                  } else {
                    var c_style = 'style="z-index: 2"';
                  }
                  result.push(
                    '<div id="' +
                      j_vis_kol_master[j] +
                      "_CHOICES" +
                      i +
                      '" class="autocomplete" ' +
                      c_style +
                      "></div>"
                  );
                }

                result.push("</div></td>");
              } else {
                result.push(
                  "<td " +
                    style_text +
                    ' id="' +
                    j_vis_kol_master[j] +
                    i +
                    '" class=\u0022test\u0022>' +
                    resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                      .childNodes[0].nodeValue +
                    "</td>"
                );
              }
            }
          }
        } else {
          if (j_kol_type_master[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //
            if (
              resdom.getElementsByTagName(j_vis_kol_master[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022 style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022/>" +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022  style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022 checked/>" +
                  "</td>"
              );
            }
          }

          if (j_kol_type_master[j] == "LINK") {
            //
            //     LINK
            //
            if (typeof link_gif_master != "undefined")
              var gif = link_gif_master(
                j,
                resdom.getElementsByTagName("MASTER")[i]
              );
            else var gif = j_id_gif_master[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom
                .getElementsByTagName("MASTER")
                [i].getElementsByTagName(j_p_key_master[jj] + "_PK");
              result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
            }

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img id='LINK" +
                j +
                "_" +
                i +
                "' src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                j_alt_tekst_master[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }
        }
      }

      result.push("</tr>");

      var colsp = j_kol_tekst_master.length + parseInt(1);

      if (typeof j_hide_master != "undefined") {
        for (var k = 0; k < j_hide_master.length; k++)
          if (j_hide_master[k] == 1) colsp = colsp - 1;
      }

      result.push(
        '<tr id="detailid' +
          i +
          '" style="display:none"><td> </td><td colspan="' +
          colsp +
          '"><table cellspacing="1" cellpadding="2" class="detailTable"><tbody>'
      );
      var detdom = resdom
        .getElementsByTagName("DETAILS")
        [i].getElementsByTagName("DETAIL");

      result.push("<tr>");
      for (var a = 0; a < j_kol_tekst_detail.length; a++) {
        if (typeof j_hide_detail != "undefined" && j_hide_detail[a] == 1) {
          var c_style = "style='display:none'";
        } else {
          var c_style = "";
        }
        result.push(
          "<th " +
            c_style +
            " title=\u0022" +
            j_alt_tekst_detail[a] +
            "\u0022>" +
            j_kol_tekst_detail[a] +
            "</th>"
        );
      }
      result.push("</tr>");

      for (var a = 0; a < detdom.length; a++) {
        result.push("<tr>");
        for (var b = 0; b < j_vis_kol_detail.length; b++) {
          var detelmdom = detdom[a].getElementsByTagName(j_vis_kol_detail[b]);

          if (j_kol_type_detail[b] == null) {
            if (typeof j_hide_detail != "undefined" && j_hide_detail[b] == 1) {
              var c_style = "style='display:none'";
            } else {
              var c_style = "";
            }

            if (
              typeof j_css_class_detail != "undefined" &&
              j_css_class_detail[b]
            )
              var c_class = j_css_class_detail[b];
            else var c_class = "left";

            if (!detelmdom[0].childNodes[0]) {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "left\u0022></td>"
              );
            } else {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "\u0022>" +
                  detelmdom[0].childNodes[0].nodeValue +
                  "</td>"
              );
            }
          } else {
            if (j_kol_type_detail[b] == "CHECKBOX") {
              //
              //     CHECKBOX
              //
              if (!detelmdom[0].childNodes[0]) {
                result.push(
                  "<td class=\u0022left\u0022>" +
                    "<input disabled type=\u0022CHECKBOX\u0022" +
                    " onClick=\u0022" +
                    j_id_funk_detail[b] +
                    "('" +
                    j_f +
                    "','" +
                    "null" +
                    "'," +
                    RaekkeFra +
                    "," +
                    RaekkeTil +
                    ");\u0022/>" +
                    "</td>"
                );
              } else {
                if (detelmdom[0].childNodes[0].nodeValue == 0) {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022/>" +
                      "</td>"
                  );
                } else {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022 checked/>" +
                      "</td>"
                  );
                }
              }
            }

            if (j_kol_type_detail[b] == "LINK") {
              if (typeof link_gif_detail != "undefined")
                var gif = link_gif_detail(a, b, detdom[a]);
              else var gif = j_id_gif_detail[b];

              result.push(
                "<td align=\u0022center\u0022>" +
                  "&nbsp;" +
                  "<a title='a' href=\u0022javascript:" +
                  j_id_funk_detail[b] +
                  "('D','" +
                  j_f +
                  "',['"
              );

              for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                var detiddom = detdom[a].getElementsByTagName(
                  j_p_key_detail[jj] + "_PK"
                );

                if (detiddom[0].childNodes[0]) {
                  result.push(detiddom[0].childNodes[0].nodeValue + "','");
                } else {
                  result.push(" ");
                }
              }

              result_str = result.pop();

              if (
                typeof j_id_title_detail != "undefined" &&
                j_id_title_detail[b]
              ) {
                var c_p_key = "'";

                for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                  var detiddom = detdom[a].getElementsByTagName(
                    j_p_key_detail[jj] + "_PK"
                  );

                  if (detiddom[0].childNodes[0]) {
                    c_p_key =
                      c_p_key + detiddom[0].childNodes[0].nodeValue + "','";
                  }
                }
                var c_title = eval(
                  j_id_title_detail[b] +
                    "('D',[" +
                    c_p_key.substr(0, c_p_key.length - 2) +
                    "])"
                );
              } else {
                var c_title = j_alt_tekst_detail[b];
              }

              result.push(
                result_str.substr(0, result_str.length - 2) +
                  "]," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022>" +
                  "<img src=\u0022" +
                  gif +
                  "\u0022 border=\u00220\u0022 title=\u0022" +
                  c_title +
                  "\u0022>" +
                  "</a>" +
                  "</td>"
              );
            }
          }
        }

        result.push("</tr>");
      }

      result.push("</tbody></table></td></tr>");
    }

    result.push("</table></div>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    result.push("<div class=\u0022resultsNavigation\u0022>");
    if (
      (Math.min(RaekkeFra, antal) != 1 ||
        Math.min(RaekkeTil, antal) != antal) &&
      antal > 0
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaetWMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaetWMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaetWMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaetWMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      result.push(
        "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
          "\u0022javascript: visAlleWMD();\u0022 " +
          " />Vis alle"
      );
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlleWMD();\u0022 " +
            " />Vis alle"
        );
      }
    }

    var offset = 0;

    if (typeof j_udtraek_til_excel != "undefined") {
      offset = offset + parseInt(1);
    }

    if (typeof j_ekstra_knap != "undefined") {
      offset = offset + parseInt(1);
    }

    if (typeof j_ekstra_knap1 != "undefined") {
      offset = offset + parseInt(1);
    }

    offset = 520 - offset * 100;

    if (typeof j_udtraek_til_excel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
          "\u0022javascript:submitSoegExcel();\u0022 " +
          " value=\u0022Udtræk til Excel\u0022/>"
      );
    }

    if (typeof j_ekstra_knap != "undefined") {
      result.push(
        "<input style=\u0022width:100px;  position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022ekstra\u0022 onClick=" +
          "\u0022javascript:ekstraKnap();\u0022 " +
          " value=\u0022" +
          j_ekstra_knap +
          "\u0022/>"
      );
    }

    if (typeof j_ekstra_knap1 != "undefined") {
      result.push(
        "<input style=\u0022width:100px;  position: relative; left: " +
          offset +
          "px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022ekstra1\u0022 onClick=" +
          "\u0022javascript:ekstraKnap1();\u0022 " +
          " value=\u0022" +
          j_ekstra_knap1 +
          "\u0022/>"
      );
    }

    result.push("</div>");

    document.getElementById(j_span).innerHTML = result.join("");

    if (typeof autocompleterMaster != "undefined")
      for (i = 0; i < c_counter; i++) {
        autocompleterMaster(i);
      }

    j_count = c_counter;
  } else if (fejl < 0) {
    ventOff();
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }

  if (navigator.appName != "Netscape") {
    if (document.getElementById("resultTable").scrollWidth > 900) {
      document.getElementById("resultTable").style.height =
        document.getElementById("resultTable").offsetHeight +
        parseInt(16) +
        "px";
    }
  }

  ventOff();
};

exports.showContentsSoegRet = (c_result) => {
  var Browser = navigator.appName;
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);
    var result = new Array();
    //
    //  Kolonneoverskrift
    //

    if (Browser == "Netscape") {
      result.push(
        '<table style="width: 100%" frame cellspacing="1" cellpadding="4"  border="0">'
      );
    } else {
      result.push(
        '<table  style="width: 100%" cellspacing="1" cellpadding="2" border="0">'
      );
    }

    if (typeof j_multi_ret != "undefined") {
      result.push('<tr class="trNormal">');
      for (var j = 0; j < j_kol_tekst.length; j++) {
        if (j_hide[j] != 1) {
          if (j_input[j] == 1) {
            if (typeof j_input_width != "undefined" && j_input_width[j] != "")
              var styleEkstra = "width: " + j_input_width[j];
            else var styleEkstra = "";

            if (typeof j_align != "undefined" && j_align[j] != null)
              var l_text_align = j_align[j];
            else var l_text_align = "right";

            result.push(
              '<td style="padding: 0px 2px 0px 0px; background-color: #CCFF99; text-align:' +
                l_text_align +
                '"><input type="text" id="' +
                j_vis_kol[j] +
                '_1" style="background-color: #fff; margin: 1px;' +
                styleEkstra +
                '"/>'
            );
          } else
            result.push(
              '<td style="padding: 0px 2px 0px 0px; background-color: #E8F2F4">'
            );

          result.push("</td>");
        }
      }
      result.push(
        '<td style="background-color: #E8F2F4"></td><td align="center"><a title="Opdater de markerede rækker" href="javascript: multiRetGem(' +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ');"><img border="0" src="../img/filesave.png"></a></td>'
      );
      result.push("</tr>");
    }

    result.push("<tr>");
    for (var j = 0; j < j_kol_tekst.length; j++) {
      var k = j + 1;

      //       if (typeof(j_kol_width)!='undefined' && j_kol_width[j]!="") {
      //        ekstra_style='white-space:nowrap; width: '+j_kol_width[j];
      //       } else {
      //        ekstra_style='white-space:nowrap; ';
      //       }

      var ekstra_style = "";

      if (typeof j_hide != "undefined" && j_hide[j] == 1) {
        var style = 'style="display: none;' + ekstra_style + '"';
      } else {
        var style = 'style="' + ekstra_style + '"';
      }

      if (j_kol_type[j] == null && j < j_sort.length) {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            "<a href=\u0022javascript:sorter(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst[j] +
            "</th>"
        );
      } else if (j_kol_type[j] == "MARKER") {
        result.push(
          "<th><input type='checkbox' title='Markering' checked disabled  style='width: 16px' /></th>"
        );
      } else {
        result.push(
          "<th " +
            style +
            ' title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst[j] +
            "] body=[" +
            j_alt_tekst[j] +
            ']">' +
            j_kol_tekst[j] +
            "</th>"
        );
      }
    }

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret</th>");
    }

    if (typeof j_multi_ret != "undefined") {
      result.push('<th title="Multi-ret.">M.</th>');
    }

    result.push("</tr>");

    var x01 = resdom.getElementsByTagName(j_vis_kol[0]);
    //
    //  Data
    //
    j_count = 0;
    var c_counter = 0;

    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;
      result.push(
        '<tr class="trNormal" onmouseout="normalRowS(this,!document.getElementById(' +
          "'GEM" +
          i +
          "'" +
          ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
          "'GEM" +
          i +
          "'" +
          ').style.display);">'
      );
      for (var j = 0; j < j_vis_kol.length; j++) {
        if (j_kol_type[j] == null) {
          if (j_vis_kol[j] != null) {
            //
            //     Fremvisning
            //

            //          if (typeof(j_kol_width)!='undefined' && j_kol_width[j]!="") {
            //   	   var ekstra_style='white-space:nowrap; width: '+j_kol_width[j];
            //          } else {
            //           var ekstra_style='white-space:nowrap;';
            //	  }

            var ekstra_style = "";

            if (typeof j_hide != "undefined" && j_hide[j] == 1) {
              var style = 'style="display: none;' + ekstra_style + '"';
            } else {
              var style = 'style="' + ekstra_style + '"';
            }

            if (typeof j_title != "undefined" && j_title[j] == 1) {
              var title = titleText(resdom, j, i);
            } else {
              var title = "";
            }

            if (typeof j_css_class != "undefined" && j_css_class[j] != null) {
              var l_class = j_css_class[j];
            } else {
              var l_class = "left";
            }

            if (typeof j_align != "undefined" && j_align[j] != null)
              var l_text_align = j_align[j];
            else var l_text_align = "right";

            var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
              .childNodes[0];

            if (!testnull) {
              if (j_input[j]) {
                result.push(
                  "<td " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    "_TD" +
                    i +
                    '" class=\u0022test\u0022><input style="text-align: ' +
                    l_text_align +
                    '; width: 100%; border: 0px;"  id="' +
                    j_vis_kol[j] +
                    i +
                    '" type="text" readonly value="" /> </td>'
                );
              } else {
                result.push(
                  "<td " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    i +
                    '" class=\u0022test\u0022> </td>'
                );
              }
            } else {
              if (j_input[j]) {
                result.push(
                  "<td title='" +
                    title +
                    "' " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    "_TD" +
                    i +
                    '"  class=\u0022test\u0022><input style="text-align: ' +
                    l_text_align +
                    '; width: 100%; border: 0px;" id="' +
                    j_vis_kol[j] +
                    i +
                    '" type="text" readonly value="' +
                    resdom
                      .getElementsByTagName(j_vis_kol[j])
                      [i].childNodes[0].nodeValue.replace(/"/g, "&quot;") +
                    '" /> </td>'
                );
              } else {
                result.push(
                  "<td title='" +
                    title +
                    "' " +
                    style +
                    ' id="' +
                    j_vis_kol[j] +
                    i +
                    '" class="' +
                    l_class +
                    '">' +
                    resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                      .nodeValue +
                    "</td>"
                );
              }
            }
          }
        } else {
          if (j_kol_type[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //
            if (
              resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022center\u0022>" +
                  "<input style='width: 13px; height: 13px;' value='0' disabled  id=\u0022" +
                  j_vis_kol[j] +
                  i +
                  "\u0022 type=\u0022CHECKBOX\u0022" +
                  ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" unchecked />' +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022center\u0022>" +
                  "<input style='width: 13px; height: 13px;' value='1' disabled  id=\u0022" +
                  j_vis_kol[j] +
                  i +
                  "\u0022 type=\u0022CHECKBOX\u0022" +
                  ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" checked/>' +
                  "</td>"
              );
            }
          }

          if (j_kol_type[j] == "LINK") {
            //
            //     LINK
            //
            var l_style = "";
            if (typeof linkFunction != "undefined") {
              l_style = linkFunction(resdom, i, j);
            }

            if (typeof link_gif != "undefined")
              var gif = link_gif(resdom, i, j);
            else var gif = j_id_gif[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a style='" +
                l_style +
                "' href=\u0022javascript:" +
                j_id_funk[j] +
                "('" +
                j_f +
                "',["
            );
            for (var jj = 0; jj < j_p_key.length; jj++) {
              result.push(
                "'" +
                  resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                    .nodeValue +
                  "',"
              );
            }

            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 1) + "],");

            result.push(
              RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 alt=\u0022" +
                j_alt_tekst[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }

          if (j_kol_type[j] == "MARKER") {
            //
            //     MARKER
            //

            var found = false;
            for (var k = 0; k < markerListe.length; k++) {
              if (markerListe[k].index == +RaekkeFra + +i) found = true;
            }

            if (found)
              result.push(
                "<td><input type='checkbox' id='MARKER" +
                  i +
                  "' checked  onClick='marker(" +
                  (+RaekkeFra + +i) +
                  "," +
                  i +
                  ")'/></td>"
              );
            else
              result.push(
                "<td><input type='checkbox' id='MARKER" +
                  i +
                  "' onClick='marker(" +
                  (+RaekkeFra + +i) +
                  "," +
                  i +
                  ")'/></td>"
              );
          }
        }
      }

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push(
            '<td class="center" width="35px"><a id=\u0022GEM' +
              i +
              '\u0022 style="display: none"/><a id=\u0022RET' +
              i +
              '\u0022 style="display: none"/></td>'
          );
        } else {
          result.push(
            "<td align=\u0022center\u0022 width=\u002250px\u0022><div style='margin: 0px 0px 0px 2p'><a title='Ret' id=\u0022RET" +
              i +
              "\u0022 href=\u0022javascript:retRaekke('" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) +
              "]," +
              i +
              "," +
              parseInt(
                parseInt(Math.min(RaekkeTil, antal)) - parseInt(RaekkeFra) + 1
              )
          );
          result.push(');"><img src="' + retIcon + '" border="0"/></a>');

          result.push(
            "<a title='Gem' id=\u0022GEM" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Gem(" +
              i +
              ",["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + gemIcon + '" border="0"/></a>');

          if (typeof "Slet" != "undefined") {
            result.push(
              "<a title='Slet' id=\u0022SLET" +
                i +
                "\u0022 style='display: none' href=\u0022javascript: Slet(" +
                i +
                ",["
            );
            for (var jj = 0; jj < j_p_key.length; jj++) {
              result.push(
                "'" +
                  resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                    .nodeValue +
                  "',"
              );
            }
            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 1) + "]");
            result.push(');"><img src="' + sletIcon + '" border="0"/></a>');
          }

          result.push(
            "<a title='Fortryd' id=\u0022FORTRYD" +
              i +
              "\u0022 style='display: none' href=\u0022javascript: Fortryd(["
          );
          for (var jj = 0; jj < j_p_key.length; jj++) {
            result.push(
              "'" +
                resdom.getElementsByTagName(j_p_key[jj])[i].childNodes[0]
                  .nodeValue +
                "',"
            );
          }
          result_str = result.pop();
          result.push(result_str.substr(0, result_str.length - 1) + "]");
          result.push(');"><img src="' + fortrydIcon + '" border="0"/></a>');

          result.push("</div></td>");

          if (typeof j_multi_ret != "undefined") {
            result.push(
              '<td><input type="checkbox" id="MULTIRET' + i + '"/></td>'
            );
          }

          result.push("</tr>");
        }
      } else {
        result.push("</tr>");
      }
      j_count++;
    }

    result.push("</table>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    j_antal_gemXX = j_count;

    result.push(
      "<div style='position: relative; height: 20px' class=\u0022resultsNavigation\u0022>"
    );
    if (
      Math.min(RaekkeFra, antal) != 1 ||
      Math.min(RaekkeTil, antal) != antal
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaetRet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaetRet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaetRet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaetRet(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      if (typeof j_fjern_vis_alle != "undefined") {
      } else {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
            "\u0022javascript: visAlleRet();\u0022 " +
            " />Vis alle"
        );
      }
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlleRet();\u0022 " +
            " />Vis alle"
        );
      }
    }

    if (typeof j_udtraek_til_excel != "undefined") {
      if (typeof j_udtraek_til_nn != "undefined")
        result.push(
          "<input style=\u0022width:75px; position: absolute; left: 740px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
            "\u0022javascript:submitSoegExcel();\u0022 " +
            " value=\u0022Excel\u0022/>"
        );
      else
        result.push(
          "<input style=\u0022width:100px; position: absolute; left: 740px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
            "\u0022javascript:submitSoegExcel();\u0022 " +
            " value=\u0022Udtræk til Excel\u0022/>"
        );
    }

    if (typeof j_udtraek_til_nn != "undefined") {
      result.push(udtraek_til_nn_html());
    }

    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  } else if (fejl < 0) {
    ventOff();
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }
};

exports.multiRetGem = (Antal, RaekkeFra, RaekkeTil) => {
  for (var i = 0; i < j_antal_gemXX; i++)
    if ($("MULTIRET" + i).checked) {
      for (j = 0; j < j_vis_kol.length; j++) {
        if (j_input[j] == 1)
          $(j_vis_kol[j] + i).value = $(j_vis_kol[j] + "_1").value;
      }
      $("GEM" + i).click();
    }
};

exports.showHide = (EL, PM) => {
  ELpntr = document.getElementById(EL);
  if (ELpntr.style.display == "none") {
    document.getElementById(PM).innerHTML =
      ' <img border="0" src="' + colapseIcon + '"> ';
    ELpntr.style.display = "";
  } else {
    document.getElementById(PM).innerHTML =
      ' <img border="0" src="' + expandIcon + '"> ';
    ELpntr.style.display = "none";
  }
};

exports.showHideH = (EL, PM) => {
  ELpntr = document.getElementById(EL);
  if (ELpntr.style.display == "none") {
    document.getElementById(PM).innerHTML =
      ' <img border="0" src="' + colapseIcon + '"> ';
    ELpntr.style.display = "";
    $("altHeader").style.display = "";
  } else {
    document.getElementById(PM).innerHTML =
      ' <img border="0" src="' + expandIcon + '"> ';
    ELpntr.style.display = "none";
    $("altHeader").style.display = "none";
  }
};

exports.showContentsMD = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);

    //
    //  Kolonneoverskrift
    //
    var result = new Array();
    result.push('<table cellspacing="1" cellpadding="2">');
    result.push("<tr>");
    result.push("<th style='width: 15px'> </th>");
    for (var j = 0; j < j_kol_tekst_master.length; j++) {
      var k = j + 1;
      if (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) {
        var style_text = "display: none;";
      } else {
        var style_text = "";
      }
      if (j_kol_type_master[j] == null && j < j_sort_master.length) {
        result.push(
          "<th  style='" +
            style_text +
            "'><a href=\u0022javascript:sorterMD(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst_master[j] +
            "</th>"
        );
      } else {
        result.push(
          "<th style='" +
            style_text +
            "' title='" +
            j_alt_tekst_master[j] +
            "'>" +
            j_kol_tekst_master[j] +
            "</th>"
        );
      }
    }

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret&nbsp;");
      if (
        typeof j_tabel_master_navn != "undefined" &&
        typeof j_tabel_detail_navn != "undefined"
      ) {
        result.push(
          j_tabel_master_navn + "<br/>Ret/Opret&nbsp;" + j_tabel_detail_navn
        );
      }
    }

    result.push("</th></tr>");

    var x01 = resdom.getElementsByTagName(j_vis_kol_master[0]);
    //
    //  Data
    //
    var c_counter = 0;

    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;
      result.push(
        '<tr class="trNormal" onmouseover="highlightRow(this);" onmouseout="normalRow(this)">'
      );

      if (
        resdom
          .getElementsByTagName("DETAILS")
          [i].getElementsByTagName("DETAIL")[0]
          .getElementsByTagName(j_p_key_detail[0] + "_PK")[0].childNodes[0]
      ) {
        result.push(
          "<td width=\u002210\u0022><a href='#" +
            i +
            "' style='text-decoration: none'"
        );
        result.push(
          "onclick=\u0022showHide('detailid" +
            i +
            "','linkid" +
            i +
            "'); \u0022"
        );
        result.push(
          "><span id='linkid" +
            i +
            "' class='linkspan'><img border=\u00220\u0022 src=\u0022../img/plus.gif\u0022></span></a></td>"
        );
      } else {
        result.push("<td width=\u002210\u0022>");
        result.push("</td>");
      }

      for (var j = 0; j < j_vis_kol_master.length; j++) {
        if (j_kol_type_master[j] == null) {
          if (j_vis_kol_master[j] != null) {
            //
            //     Fremvisning
            //

            var testnull = resdom.getElementsByTagName(j_vis_kol_master[j])[i]
              .childNodes[0];
            if (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) {
              var style_text = "display: none;";
            } else {
              var style_text = "";
            }

            if (
              typeof j_css_class_master != "undefined" &&
              j_css_class_master[j]
            )
              var c_class = j_css_class_master[j];
            else var c_class = "left";

            if (!testnull) {
              result.push(
                "<td id='" +
                  j_vis_kol_master[j] +
                  i +
                  "' class=\u0022" +
                  c_class +
                  "\u0022 style='" +
                  style_text +
                  "'></td>"
              );
            } else {
              result.push(
                "<td id='" +
                  j_vis_kol_master[j] +
                  i +
                  "' class=\u0022" +
                  c_class +
                  "\u0022 style='" +
                  style_text +
                  "'>" +
                  resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                    .childNodes[0].nodeValue +
                  "</td>"
              );
            }
          }
        } else {
          if (j_kol_type_master[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //

            if (
              resdom.getElementsByTagName(j_vis_kol_master[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022 style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022/>" +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022  style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022 checked/>" +
                  "</td>"
              );
            }
          }

          if (j_kol_type_master[j] == "LINK") {
            //
            //     LINK
            //
            if (typeof link_gif_master != "undefined")
              var gif = link_gif_master(
                j,
                resdom.getElementsByTagName("MASTER")[i]
              );
            else var gif = j_id_gif_master[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom
                .getElementsByTagName("MASTER")
                [i].getElementsByTagName(j_p_key_master[jj] + "_PK");
              result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
            }

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img id='LINK" +
                j +
                "_" +
                i +
                "' src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                j_alt_tekst_master[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }

          if (j_kol_type_master[j] == "INFO") {
            //
            //     INFO
            //
            if (typeof link_gif_master != "undefined")
              var gif = link_gif_master(
                j,
                resdom.getElementsByTagName("MASTER")[i]
              );
            else var gif = j_id_gif_master[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom
                .getElementsByTagName("MASTER")
                [i].getElementsByTagName(j_vis_kol_master[j]);
              if (detiddom[0].childNodes[0])
                result.push(
                  "'" +
                    detiddom[0].childNodes[0].nodeValue.replace(/\\/g, "\\\\") +
                    "',"
                );
              else result.push("'" + "" + "',");
            }

            if (detiddom[0].childNodes[0])
              var temp = detiddom[0].childNodes[0].nodeValue;
            else var temp = "";

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img id='LINK" +
                j +
                "_" +
                i +
                "' src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                temp +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }

          if (j_kol_type_master[j] == "SPECIAL") {
            //
            //     SPECIAL
            //

            var z = j_id_funk_master[j] + "(" + i + ")";

            var zz = eval(z);

            result.push("<td>" + zz + "</td>");
          }
        }
      }

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push('<td class="center" width="30px"></td>');
        } else {
          result.push(
            "<td  align=\u0022center\u0022 width=\u002230px\u0022><a href=\u0022javascript:retRaekkeMD('M','" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key_master.length; jj++) {
            var detiddom = resdom
              .getElementsByTagName("MASTER")
              [i].getElementsByTagName(j_p_key_master[jj] + "_PK");
            result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) + "]," + RaekkeTil
          );
          result.push(
            ');"><img src="' + retIcon + '" border="0"/></a></td></tr>'
          );
        }
      }

      var colsp = j_kol_tekst_master.length + parseInt(1);

      if (typeof j_hide_master != "undefined") {
        for (var k = 0; k < j_hide_master.length; k++)
          if (j_hide_master[k] == 1) colsp = colsp - 1;
      }

      result.push(
        '<tr id="detailid' +
          i +
          '" style="display:none"><td> </td><td colspan="' +
          colsp +
          '"><table cellspacing="1" cellpadding="2" class="detailTable"><tbody>'
      );
      var detdom = resdom
        .getElementsByTagName("DETAILS")
        [i].getElementsByTagName("DETAIL");

      result.push("<tr>");
      for (var a = 0; a < j_kol_tekst_detail.length; a++) {
        if (typeof j_hide_detail != "undefined" && j_hide_detail[a] == 1) {
          var c_style = "style='display:none'";
        } else {
          var c_style = "";
        }
        result.push(
          "<th " +
            c_style +
            " title=\u0022cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst_detail[a] +
            "] body=[" +
            j_alt_tekst_detail[a] +
            "]\u0022>" +
            j_kol_tekst_detail[a] +
            "</th>"
        );
      }
      result.push("</tr>");

      for (var a = 0; a < detdom.length; a++) {
        result.push("<tr>");
        for (var b = 0; b < j_vis_kol_detail.length; b++) {
          var detelmdom = detdom[a].getElementsByTagName(j_vis_kol_detail[b]);

          if (j_kol_type_detail[b] == null) {
            if (typeof j_hide_detail != "undefined" && j_hide_detail[b] == 1) {
              var c_style = "style='display:none'";
            } else {
              var c_style = "";
            }

            if (
              typeof j_css_class_detail != "undefined" &&
              j_css_class_detail[b]
            )
              var c_class = j_css_class_detail[b];
            else var c_class = "left";

            if (!detelmdom[0].childNodes[0]) {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "left\u0022></td>"
              );
            } else {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "\u0022>" +
                  detelmdom[0].childNodes[0].nodeValue +
                  "</td>"
              );
            }
          } else {
            if (j_kol_type_detail[b] == "CHECKBOX") {
              //
              //     CHECKBOX
              //

              if (!detelmdom[0].childNodes[0]) {
                result.push(
                  "<td class=\u0022left\u0022>" +
                    "<input disabled type=\u0022CHECKBOX\u0022" +
                    " onClick=\u0022" +
                    j_id_funk_detail[b] +
                    "('" +
                    j_f +
                    "','" +
                    "null" +
                    "'," +
                    RaekkeFra +
                    "," +
                    RaekkeTil +
                    ");\u0022/>" +
                    "</td>"
                );
              } else {
                if (detelmdom[0].childNodes[0].nodeValue == 0) {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022/>" +
                      "</td>"
                  );
                } else {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022 checked/>" +
                      "</td>"
                  );
                }
              }
            }

            if (j_kol_type_detail[b] == "LINK") {
              if (typeof link_gif_detail != "undefined")
                var gif = link_gif_detail(a, b, detdom[a]);
              else var gif = j_id_gif_detail[b];

              result.push(
                "<td align=\u0022center\u0022>" +
                  "&nbsp;" +
                  "<a title='a' href=\u0022javascript:" +
                  j_id_funk_detail[b] +
                  "('D','" +
                  j_f +
                  "',['"
              );

              for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                var detiddom = detdom[a].getElementsByTagName(
                  j_p_key_detail[jj] + "_PK"
                );

                if (detiddom[0].childNodes[0]) {
                  result.push(detiddom[0].childNodes[0].nodeValue + "','");
                } else {
                  result.push(" ");
                }
              }

              result_str = result.pop();

              if (
                typeof j_id_title_detail != "undefined" &&
                j_id_title_detail[b]
              ) {
                var c_p_key = "'";

                for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                  var detiddom = detdom[a].getElementsByTagName(
                    j_p_key_detail[jj] + "_PK"
                  );

                  if (detiddom[0].childNodes[0]) {
                    c_p_key =
                      c_p_key + detiddom[0].childNodes[0].nodeValue + "','";
                  }
                }

                var c_title = eval(
                  j_id_title_detail[b] +
                    "('D',[" +
                    c_p_key.substr(0, c_p_key.length - 2) +
                    "])"
                );
              } else {
                var c_title = j_alt_tekst_detail[b];
              }

              result.push(
                result_str.substr(0, result_str.length - 2) +
                  "]," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022>" +
                  "<img src=\u0022" +
                  gif +
                  "\u0022 border=\u00220\u0022 title=\u0022" +
                  c_title +
                  "\u0022>" +
                  "</a>" +
                  "</td>"
              );
            }
          }
        }

        result.push("</tr>");
      }

      result.push("</tbody></table></td></tr>");
    }

    result.push("</table>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    result.push("<div class=\u0022resultsNavigation\u0022>");
    if (
      (Math.min(RaekkeFra, antal) != 1 ||
        Math.min(RaekkeTil, antal) != antal) &&
      antal > 0
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaetMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaetMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaetMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaetMD(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      result.push(
        "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
          "\u0022javascript: visAlleMD();\u0022 " +
          " />Vis alle"
      );
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlleMD();\u0022 " +
            " />Vis alle"
        );
      }
    }

    if (typeof j_udtraek_til_excel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; position: relative; left: 380px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
          "\u0022javascript:submitSoegExcelMD();\u0022 " +
          " value=\u0022Udtræk til Excel\u0022/>"
      );
    }

    if (typeof udskrivRapportExcel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; float: right; margin: 0 20px 0 0\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraekRap\u0022 onClick=" +
          "\u0022javascript:udskrivRapportExcel();\u0022 " +
          " value=\u0022Udskrift til Excel\u0022/>"
      );
    }

    if (typeof udskrivRapportPDF != "undefined") {
      result.push(
        "<input style=\u0022width:100px; float: right; margin: 0 20px 0 0\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraekRap\u0022 onClick=" +
          "\u0022javascript:udskrivRapportPDF();\u0022 " +
          " value=\u0022Udskrift til PDF\u0022/>"
      );
    }

    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  } else if (fejl < 0) {
    alert(
      "Fejl: " + resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue
    );
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = new Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }

  ventOff();
};

exports.showContentsMDproc = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);

    //
    //  Kolonneoverskrift
    //
    var result = new Array();
    result.push('<table cellspacing="1" cellpadding="2">');
    result.push("<tr>");
    result.push("<th style='width: 15px'> </th>");
    for (var j = 0; j < j_kol_tekst_master.length; j++) {
      var k = j + 1;
      if (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) {
        var style_text = "display: none;";
      } else {
        var style_text = "";
      }
      if (j_kol_type_master[j] == null && j < j_sort_master.length) {
        result.push(
          "<th  style='" +
            style_text +
            "'><a href=\u0022javascript:sorterMD(" +
            antal +
            "," +
            RaekkeFra +
            "," +
            RaekkeTil +
            "," +
            k +
            ",'" +
            j_f +
            "')\u0022 title=\u0022Sorter efter denne kolonne\u0022>" +
            j_kol_tekst_master[j] +
            "</th>"
        );
      } else {
        result.push(
          "<th style='" +
            style_text +
            "' title='" +
            j_alt_tekst_master[j] +
            "'>" +
            j_kol_tekst_master[j] +
            "</th>"
        );
      }
    }

    if (typeof retteMulighedS != "undefined") {
      result.push("<th>Ret&nbsp;");
      if (
        typeof j_tabel_master_navn != "undefined" &&
        typeof j_tabel_detail_navn != "undefined"
      ) {
        result.push(
          j_tabel_master_navn + "<br/>Ret/Opret&nbsp;" + j_tabel_detail_navn
        );
      }
    }

    result.push("</th></tr>");

    var x01 = resdom.getElementsByTagName(j_vis_kol_master[0]);
    //
    //  Data
    //
    var c_counter = 0;

    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;
      result.push(
        '<tr class="trNormal" onmouseover="highlightRow(this);" onmouseout="normalRow(this)">'
      );

      if (
        resdom
          .getElementsByTagName("DETAILS")
          [i].getElementsByTagName("DETAIL")[0]
          .getElementsByTagName(j_p_key_detail[0] + "_PK")[0].childNodes[0]
      ) {
        result.push(
          "<td width=\u002210\u0022><a href='#" +
            i +
            "' style='text-decoration: none'"
        );
        result.push(
          "onclick=\u0022showHide('detailid" +
            i +
            "','linkid" +
            i +
            "'); \u0022"
        );
        result.push(
          "><span id='linkid" +
            i +
            "' class='linkspan'><img border=\u00220\u0022 src=\u0022../img/plus.gif\u0022></span></a></td>"
        );
      } else {
        result.push("<td width=\u002210\u0022>");
        result.push("</td>");
      }

      for (var j = 0; j < j_vis_kol_master.length; j++) {
        if (j_kol_type_master[j] == null) {
          if (j_vis_kol_master[j] != null) {
            //
            //     Fremvisning
            //

            var testnull = resdom.getElementsByTagName(j_vis_kol_master[j])[i]
              .childNodes[0];
            if (typeof j_hide_master != "undefined" && j_hide_master[j] == 1) {
              var style_text = "display: none;";
            } else {
              var style_text = "";
            }

            if (
              typeof j_css_class_master != "undefined" &&
              j_css_class_master[j]
            )
              var c_class = j_css_class_master[j];
            else var c_class = "left";

            if (!testnull) {
              result.push(
                "<td id='" +
                  j_vis_kol_master[j] +
                  i +
                  "' class=\u0022" +
                  c_class +
                  "\u0022 style='" +
                  style_text +
                  "'></td>"
              );
            } else {
              result.push(
                "<td id='" +
                  j_vis_kol_master[j] +
                  i +
                  "' class=\u0022" +
                  c_class +
                  "\u0022 style='" +
                  style_text +
                  "'>" +
                  resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                    .childNodes[0].nodeValue +
                  "</td>"
              );
            }
          }
        } else {
          if (j_kol_type_master[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //
            if (
              resdom.getElementsByTagName(j_vis_kol_master[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022 style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022/>" +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input disabled type=\u0022CHECKBOX\u0022  style=\u0022width: 95%; margin: 0\u0022" +
                  " onClick=\u0022" +
                  j_id_funk_master[j] +
                  "('" +
                  j_f +
                  "','" +
                  resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
                  "'," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  ");\u0022 checked/>" +
                  "</td>"
              );
            }
          }

          if (j_kol_type_master[j] == "LINK") {
            //
            //     LINK
            //
            if (typeof link_gif_master != "undefined")
              var gif = link_gif_master(
                j,
                resdom.getElementsByTagName("MASTER")[i]
              );
            else var gif = j_id_gif_master[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom
                .getElementsByTagName("MASTER")
                [i].getElementsByTagName(j_p_key_master[jj] + "_PK");
              result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
            }

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img id='LINK" +
                j +
                "_" +
                i +
                "' src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                j_alt_tekst_master[j] +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }

          if (j_kol_type_master[j] == "INFO") {
            //
            //     INFO
            //
            if (typeof link_gif_master != "undefined")
              var gif = link_gif_master(
                j,
                resdom.getElementsByTagName("MASTER")[i]
              );
            else var gif = j_id_gif_master[j];

            result.push(
              "<td align=\u0022center\u0022>" +
                "<a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom
                .getElementsByTagName("MASTER")
                [i].getElementsByTagName(j_vis_kol_master[j]);
              if (detiddom[0].childNodes[0])
                result.push(
                  "'" +
                    detiddom[0].childNodes[0].nodeValue.replace(/\\/g, "\\\\") +
                    "',"
                );
              else result.push("'" + "" + "',");
            }

            if (detiddom[0].childNodes[0])
              var temp = detiddom[0].childNodes[0].nodeValue;
            else var temp = "";

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                RaekkeFra +
                "," +
                RaekkeTil +
                "," +
                i +
                ");\u0022>" +
                "<img id='LINK" +
                j +
                "_" +
                i +
                "' src=\u0022" +
                gif +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                temp +
                "\u0022>" +
                "</a>" +
                "</td>"
            );
          }

          if (j_kol_type_master[j] == "SPECIAL") {
            //
            //     SPECIAL
            //

            var z = j_id_funk_master[j] + "(" + i + ")";

            var zz = eval(z);

            result.push("<td>" + zz + "</td>");
          }
        }
      }

      if (typeof retteMulighedS != "undefined") {
        if (!retteMulighedS(i, resdom)) {
          result.push('<td class="center" width="30px"></td>');
        } else {
          result.push(
            "<td  align=\u0022center\u0022 width=\u002230px\u0022><a href=\u0022javascript:retRaekkeMD('M','" +
              j_f +
              "',["
          );
          for (var jj = 0; jj < j_p_key_master.length; jj++) {
            var detiddom = resdom
              .getElementsByTagName("MASTER")
              [i].getElementsByTagName(j_p_key_master[jj] + "_PK");
            result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
          }
          result_str = result.pop();
          result.push(
            result_str.substr(0, result_str.length - 1) + "]," + RaekkeTil
          );
          result.push(
            ');"><img src="' + retIcon + '" border="0"/></a></td></tr>'
          );
        }
      }

      var colsp = j_kol_tekst_master.length + parseInt(1);

      if (typeof j_hide_master != "undefined") {
        for (var k = 0; k < j_hide_master.length; k++)
          if (j_hide_master[k] == 1) colsp = colsp - 1;
      }

      result.push(
        '<tr id="detailid' +
          i +
          '" style="display:none"><td> </td><td colspan="' +
          colsp +
          '"><table cellspacing="1" cellpadding="2" class="detailTable"><tbody>'
      );
      var detdom = resdom
        .getElementsByTagName("DETAILS")
        [i].getElementsByTagName("DETAIL");

      result.push("<tr>");
      for (var a = 0; a < j_kol_tekst_detail.length; a++) {
        if (typeof j_hide_detail != "undefined" && j_hide_detail[a] == 1) {
          var c_style = "style='display:none'";
        } else {
          var c_style = "";
        }
        result.push(
          "<th " +
            c_style +
            " title=\u0022cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst_detail[a] +
            "] body=[" +
            j_alt_tekst_detail[a] +
            "]\u0022>" +
            j_kol_tekst_detail[a] +
            "</th>"
        );
      }
      result.push("</tr>");

      for (var a = 0; a < detdom.length; a++) {
        result.push("<tr>");
        for (var b = 0; b < j_vis_kol_detail.length; b++) {
          var detelmdom = detdom[a].getElementsByTagName(j_vis_kol_detail[b]);

          if (j_kol_type_detail[b] == null) {
            if (typeof j_hide_detail != "undefined" && j_hide_detail[b] == 1) {
              var c_style = "style='display:none'";
            } else {
              var c_style = "";
            }

            if (
              typeof j_css_class_detail != "undefined" &&
              j_css_class_detail[b]
            )
              var c_class = j_css_class_detail[b];
            else var c_class = "left";

            if (!detelmdom[0].childNodes[0]) {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "left\u0022></td>"
              );
            } else {
              result.push(
                "<td " +
                  c_style +
                  " class=\u0022" +
                  c_class +
                  "\u0022>" +
                  detelmdom[0].childNodes[0].nodeValue +
                  "</td>"
              );
            }
          } else {
            if (j_kol_type_detail[b] == "CHECKBOX") {
              //
              //     CHECKBOX
              //
              if (!detelmdom[0].childNodes[0]) {
                result.push(
                  "<td class=\u0022left\u0022>" +
                    "<input disabled type=\u0022CHECKBOX\u0022" +
                    " onClick=\u0022" +
                    j_id_funk_detail[b] +
                    "('" +
                    j_f +
                    "','" +
                    "null" +
                    "'," +
                    RaekkeFra +
                    "," +
                    RaekkeTil +
                    ");\u0022/>" +
                    "</td>"
                );
              } else {
                if (detelmdom[0].childNodes[0].nodeValue == 0) {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022/>" +
                      "</td>"
                  );
                } else {
                  result.push(
                    "<td class=\u0022left\u0022>" +
                      "<input disabled type=\u0022CHECKBOX\u0022" +
                      " onClick=\u0022" +
                      j_id_funk_detail[b] +
                      "('" +
                      j_f +
                      "','" +
                      detelmdom[0].childNodes[0].nodeValue +
                      "'," +
                      RaekkeFra +
                      "," +
                      RaekkeTil +
                      ");\u0022 checked/>" +
                      "</td>"
                  );
                }
              }
            }

            if (j_kol_type_detail[b] == "LINK") {
              if (typeof link_gif_detail != "undefined")
                var gif = link_gif_detail(a, b, detdom[a]);
              else var gif = j_id_gif_detail[b];

              result.push(
                "<td align=\u0022center\u0022>" +
                  "&nbsp;" +
                  "<a title='a' href=\u0022javascript:" +
                  j_id_funk_detail[b] +
                  "('D','" +
                  j_f +
                  "',['"
              );

              for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                var detiddom = detdom[a].getElementsByTagName(
                  j_p_key_detail[jj] + "_PK"
                );

                if (detiddom[0].childNodes[0]) {
                  result.push(detiddom[0].childNodes[0].nodeValue + "','");
                } else {
                  result.push(" ");
                }
              }

              result_str = result.pop();

              if (
                typeof j_id_title_detail != "undefined" &&
                j_id_title_detail[b]
              ) {
                var c_p_key = "'";

                for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                  var detiddom = detdom[a].getElementsByTagName(
                    j_p_key_detail[jj] + "_PK"
                  );

                  if (detiddom[0].childNodes[0]) {
                    c_p_key =
                      c_p_key + detiddom[0].childNodes[0].nodeValue + "','";
                  }
                }

                var c_title = eval(
                  j_id_title_detail[b] +
                    "('D',[" +
                    c_p_key.substr(0, c_p_key.length - 2) +
                    "])"
                );
              } else {
                var c_title = j_alt_tekst_detail[b];
              }

              result.push(
                result_str.substr(0, result_str.length - 2) +
                  "]," +
                  RaekkeFra +
                  "," +
                  RaekkeTil +
                  "," +
                  i +
                  ");\u0022>" +
                  "<img src=\u0022" +
                  gif +
                  "\u0022 border=\u00220\u0022 title=\u0022" +
                  c_title +
                  "\u0022>" +
                  "</a>" +
                  "</td>"
              );
            }
          }
        }

        result.push("</tr>");
      }

      result.push("</tbody></table></td></tr>");
    }

    result.push("</table>");

    result.push(
      "Viser " +
        Math.min(RaekkeFra, antal) +
        "-" +
        Math.min(RaekkeTil, antal) +
        " af " +
        antal
    );

    if (antal == 0) {
      result.push(" (Ingen data fundet)");
    }

    result.push("<div class=\u0022resultsNavigation\u0022>");
    if (
      (Math.min(RaekkeFra, antal) != 1 ||
        Math.min(RaekkeTil, antal) != antal) &&
      antal > 0
    ) {
      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
          "\u0022javascript:foersteSaetMDproc(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022<< Første\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
          "\u0022javascript:forrigeSaetMDproc(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022< Forrige sæt\u0022/>"
      );

      result.push(
        "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
          "\u0022javascript:naesteSaetMDproc(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Næste sæt >\u0022/>"
      );

      result.push(
        "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
          "\u0022javascript:sidsteSaetMDproc(" +
          antal +
          "," +
          RaekkeFra +
          "," +
          RaekkeTil +
          ",'" +
          j_f +
          "');\u0022 " +
          " value=\u0022Sidste >>\u0022/>"
      );

      result.push(
        "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 onClick=" +
          "\u0022javascript: visAlleMDproc();\u0022 " +
          " />Vis alle"
      );
    } else {
      if (j_vis_raekker == j_vis_raekker_alle) {
        result.push(
          "<input class='checkbox' type=\u0022checkbox\u0022 id=\u0022alle\u0022 checked onClick=" +
            "\u0022javascript: visAlleMDproc();\u0022 " +
            " />Vis alle"
        );
      }
    }

    if (typeof j_udtraek_til_excel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; position: relative; left: 380px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraek\u0022 onClick=" +
          "\u0022javascript:submitSoegExcelMDproc();\u0022 " +
          " value=\u0022Udtræk til Excel\u0022/>"
      );
    }

    if (typeof udskrivRapportExcel != "undefined") {
      result.push(
        "<input style=\u0022width:100px; float: right; margin: 0 20px 0 0\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraekRap\u0022 onClick=" +
          "\u0022javascript:udskrivRapportExcelproc();\u0022 " +
          " value=\u0022Udskrift til Excel\u0022/>"
      );
    }

    if (typeof udskrivRapportPDF != "undefined") {
      result.push(
        "<input style=\u0022width:100px; float: right; margin: 0 20px 0 0\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022udtraekRap\u0022 onClick=" +
          "\u0022javascript:udskrivRapportPDFproc();\u0022 " +
          " value=\u0022Udskrift til PDF\u0022/>"
      );
    }

    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  } else if (fejl < 0) {
    alert(
      "Fejl: " + resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue
    );
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = new Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }

  ventOff();
};

exports.visAlle = () => {
  if (document.getElementById("alle").checked) {
    j_vis_raekker = j_vis_raekker_alle;
  } else {
    visAntal();
  }
  startSoeg_f1();
};

exports.visAlleMD = () => {
  if (document.getElementById("alle").checked) {
    j_vis_raekker = j_vis_raekker_alle;
  } else {
    visAntal();
  }
  startSoegMD_f1();
};

exports.visAlleW = () => {
  if (document.getElementById("alle").checked) {
    j_vis_raekker = j_vis_raekker_alle;
  } else {
    visAntal();
  }
  startSoegW_f1();
};

exports.visAlleWMD = () => {
  if (document.getElementById("alle").checked) {
    j_vis_raekker = j_vis_raekker_alle;
  } else {
    visAntal();
  }
  startSoegWMD_f1();
};

exports.visAlleRet = () => {
  if (document.getElementById("alle").checked) {
    j_vis_raekker = j_vis_raekker_alle;
  } else {
    visAntal();
  }
  startSoegRet_f1();
};

exports.normalRowS = (t, s) => {
  if (!s) {
    normalRow(t);
  }
};

exports.escapePling = (c_val) => {
  return c_val.replace(/\"/g, "&#148;").replace(/\'/g, "&#146;");
};

exports.showContentsRetMD = (c_result) => {
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  var a = 0;
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    var RaekkeFra = getRaekkeFra(resdom);
    var RaekkeTil = getRaekkeTil(resdom);
    var Browser = navigator.appName;

    //
    //  Kolonneoverskrift
    //
    var result = new Array();
    result.push(
      '<div class="componentColumn outlined column12345 queryBox" id="BoxRoadSelect" style="width: 900px; z-index: 10"><div class="boxHeader">' +
        j_tabel_master_navn +
        '</div><div class="results">'
    );

    result.push(
      '<table style="width: 900px;" frame cellspacing="1" cellpadding="1"  border="0">'
    );

    result.push("<tr>");

    for (var j = 0; j < j_kol_tekst_master.length; j++) {
      var k = j + 1;
      if (j_kol_tekst_width_master[j]) {
        var style_text = "width: " + j_kol_tekst_width_master[j] + ";";
      } else {
        var style_text = "";
      }

      if (j_hide_master[j]) {
        style_text = style_text + " display: none;";
      }

      if (j_kol_type_master[j] == null && j < j_sort_master.length) {
        //                result = result + '<th style="'+style_text+'; z-index: 12" title="'+j_alt_tekst_master[j]+'">' + j_kol_tekst_master[j];
        //
        // Nedenstående laver JS-popup på overskrifterne, men den ligger sig under de andre divs !!!!
        result.push(
          '<th style="' +
            style_text +
            '" title="cssbody=[dvbdy1] cssheader=[dvhdr1] header=[' +
            j_kol_tekst_master[j] +
            "] body=[" +
            j_alt_tekst_master[j] +
            ']">' +
            j_kol_tekst_master[j]
        );

        if (j_combo_master[j] == 1) {
          result.push(
            '<span id="' +
              j_vis_kol_master[j].toLowerCase() +
              '_indicator" style="display: none"><img src="../img/indicator_arrows.gif" alt="Vent..." />'
          );
        }

        result.push("</th>");
      } else {
        result.push("<th>" + j_kol_tekst_master[j] + "</th>");
      }
    }

    result.push("<td> </td>");
    result.push("</tr>");

    if (resdom.getElementsByTagName("MASTER").length == 0) {
      resdom = tomtRetteBillede();
    }

    var x01 = resdom
      .getElementsByTagName("MASTER")[0]
      .getElementsByTagName(j_vis_kol_master[0]);
    //
    //  Data
    //
    var c_counter = 0;
    for (var i = 0; i < x01.length; i++) {
      c_counter = c_counter + 1;

      if (
        !resdom.getElementsByTagName(j_p_key_master[0] + "_PK")[i].childNodes[0]
      ) {
        result.push(
          '<tr class="trRetHighlight" onmouseout="normalRowS(this,!document.getElementById(' +
            "'GEM'" +
            ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
            "'GEM'" +
            ').style.display);">'
        );
      } else {
        result.push(
          '<tr class="trNormal" onmouseout="normalRowS(this,!document.getElementById(' +
            "'GEM'" +
            ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
            "'GEM'" +
            ').style.display);">'
        );
      }

      for (var j = 0; j < j_vis_kol_master.length; j++) {
        var input_style = "";
        if (!j_hide_master[j] && j_kol_tekst_width_master[j]) {
          if (j_kol_tekst_width_master[j].indexOf("px") > 0) {
            input_style =
              "width: " +
              (j_kol_tekst_width_master[j].replace("px", "") -
                parseInt(input_reduktion)) +
              "px;";
          }
        }
        if (j_kol_type_master[j] == null) {
          if (j_vis_kol_master[j] != null) {
            //
            //     Fremvisning
            //

            var testnull = resdom.getElementsByTagName(j_vis_kol_master[j])[i]
              .childNodes[0];

            if (!testnull) {
              var val = "";
            } else {
              var val = resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                .childNodes[0].nodeValue;
              val = escapePling(val);
            }

            if (j_hide_master[j]) {
              var style_text = "display: none;";
            } else {
              var style_text = "";
            }

            if (
              typeof j_onchange_master != "undefined" &&
              j_onchange_master[j] != null
            )
              var onChange =
                " onChange=\u0022" +
                j_onchange_master[j] +
                "(" +
                i +
                ");\u0022";
            else var onChange = "";

            result.push(
              '<td title="' +
                val +
                '" style="' +
                style_text +
                '" class="left">' +
                "<div><input " +
                onChange +
                ' type="text" style="' +
                input_style +
                '" id="' +
                j_vis_kol_master[j] +
                '" name="' +
                j_vis_kol_master[j] +
                '" readonly  value="' +
                val +
                '"/>'
            );

            if (j_combo_master[j] == 1) {
              if (j_combo_width_master[j]) {
                var c_style =
                  'style="z-index: 2; width: ' + j_combo_width_master[j] + ';"';
              } else {
                var c_style = 'style="z-index: 2"';
              }
              result.push(
                '<div id="' +
                  j_vis_kol_master[j] +
                  '_CHOICES" class="autocomplete" ' +
                  c_style +
                  "></div>"
              );
            }
            result.push("</div></td>");
          }
        } else {
          if (j_kol_type_master[j] == "CHECKBOX") {
            //
            //     CHECKBOX
            //
            if (
              resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                .childNodes[0] &&
              resdom.getElementsByTagName(j_vis_kol_master[j])[i].childNodes[0]
                .nodeValue == 0
            ) {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input value='0' disabled type=\u0022CHECKBOX\u0022 id='" +
                  j_vis_kol_master[j] +
                  "'" +
                  " style=\u0022" +
                  input_style +
                  "\u0022" +
                  ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" unchecked />' +
                  "</td>"
              );
            } else {
              result.push(
                "<td class=\u0022left\u0022>" +
                  "<input value='1' disabled type=\u0022CHECKBOX\u0022 id='" +
                  j_vis_kol_master[j] +
                  "'" +
                  " style=\u0022" +
                  input_style +
                  "\u0022" +
                  ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" checked/>' +
                  "</td>"
              );
            }
          }
          if (j_kol_type_master[j] == "LINK") {
            //
            //     LINK
            //

            result.push(
              "<td class=\u0022center\u0022 " +
                ' name="' +
                j_vis_kol_master[j] +
                '" id="' +
                j_vis_kol_master[j] +
                '"' +
                ">" +
                "<div id='LINK_" +
                j +
                "'><a href=\u0022javascript:" +
                j_id_funk_master[j] +
                "('M','" +
                j_f +
                "',["
            );

            for (var jj = 0; jj < j_p_key_master.length; jj++) {
              var detiddom = resdom.getElementsByTagName(
                j_p_key_master[jj] + "_PK"
              );
              if (detiddom[0].childNodes[0]) {
                result.push("'" + detiddom[0].childNodes[0].nodeValue + "',");
              } else {
                result.push("'',");
              }
            }

            result_str = result.pop();
            result.push(
              result_str.substr(0, result_str.length - 1) +
                "]," +
                "0" +
                "," +
                "0" +
                ");\u0022>" +
                "<img src=\u0022" +
                j_id_gif_master[j] +
                "\u0022 border=\u00220\u0022 title=\u0022" +
                j_alt_tekst_master[j] +
                "\u0022>" +
                "</a></div>" +
                "</td>"
            );
          }

          if (j_kol_type_detail[j] == "LOV") {
            if (j_kol_tekst_width_master[j]) {
              var width_text = 'width: "' + j_kol_tekst_width_master[j] + '"';
            } else {
              var width_text = "";
            }
            if (
              !resdom.getElementsByTagName(j_vis_kol_master[j])[i].childNodes[0]
            ) {
              result.push(
                '<td><div id="' +
                  j_vis_kol_master[j] +
                  '" style="position:relative; ' +
                  width_text +
                  '">' +
                  "</div></td>"
              );
            } else {
              result.push(
                '<td><div id="' +
                  j_vis_kol_master[j] +
                  '" style="position:relative; ' +
                  width_text +
                  '">' +
                  resdom.getElementsByTagName(j_vis_kol_master[j])[i]
                    .childNodes[0].nodeValue +
                  "</div></td>"
              );
            }
          }
        }
      }

      result.push('<td width="65px"><div style="margin: 0px 0px 0px 2px">');

      if (!retteMulighedM(resdom)) {
        result.push(
          '<a name="RET" id="RET" > <img  src="' + blankIcon + '"/></a>'
        );
        result.push('<a name="SLET" id="SLET" ');

        result.push('"> <img src="' + blankIcon + '"/></a>');

        result.push('<a style="display: none;"name="GEM" id="GEM" ');

        result.push('"> <img  src="' + blankIcon + '"/></a>');
        result.push(
          '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
            fortrydIcon +
            '"/></a></div></td>'
        );
      } else {
        result.push(
          '<a style="display: none;"name="GEM" id="GEM" title="Gem" href="javascript:'
        );

        result.push("GemM([");
        for (var jj = 0; jj < j_p_key_master.length; jj++) {
          var detiddom = resdom.getElementsByTagName(
            j_p_key_master[jj] + "_PK"
          );
          if (detiddom[i].childNodes[0]) {
            result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
          } else {
            result.push(" ");
          }
        }
        result_str = result.pop();
        result.push(result_str.substr(0, result_str.length - 1) + "]);");

        result.push('"> <img src="' + gemIcon + '"/></a>');

        result.push(
          '<a name="RET" id="RET" title="Ret" href="javascript: RetM();"> <img src="' +
            retIcon +
            '"/></a>'
        );
        result.push(
          '<a style="display: none" name="SLET" id="SLET" title="Slet" href="javascript: '
        );

        result.push("SletM([ ");
        for (var jj = 0; jj < j_p_key_master.length; jj++) {
          var detiddom = resdom.getElementsByTagName(
            j_p_key_master[jj] + "_PK"
          );
          if (detiddom[i].childNodes[0]) {
            result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
          } else {
            result.push(" ");
          }
        }
        result_str = result.pop();
        result.push(result_str.substr(0, result_str.length - 1) + "]);");

        result.push('"> <img src="' + sletIcon + '"/></a>');

        result.push(
          '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
            fortrydIcon +
            '"/></a></div></td>'
        );
      }

      result.push("</div></td>");

      result.push("</tr></table></div>");

      result.push(
        '</div> <div class="componentRowSpace"></div> <div class="componentColumn outlined column12345 queryBox" id="BoxRoadSelect1" style="width: 900px;"><div class="boxHeader">' +
          j_tabel_detail_navn
      );

      if (typeof extra_header_detail != "undefined") {
        result.push(extra_header_detail(resdom));
      }

      result.push('</div><div class="results">');

      result.push(
        '<table style="width: 900px;"   cellspacing="1" cellpadding="1" border="0">'
      );

      var detdom = resdom
        .getElementsByTagName("DETAILS")
        [i].getElementsByTagName("DETAIL");

      result.push("<tr>");

      for (var a = 0; a < j_kol_tekst_detail.length; a++) {
        if (j_kol_tekst_width_detail[a]) {
          var style_text = "width: " + j_kol_tekst_width_detail[a] + "; ";
        } else {
          var style_text = "";
        }

        if (j_hide_detail[a]) {
          style_text = style_text + "display: none; ";
        }

        result.push(
          '<th style="' +
            style_text +
            '" id="H_' +
            j_vis_kol_detail[a] +
            '" title="' +
            "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
            j_kol_tekst_detail[a] +
            "] body=[" +
            j_alt_tekst_detail[a] +
            ']">' +
            j_kol_tekst_detail[a]
        );
        if (j_combo_detail[a] == 1) {
          result.push(
            '<span id="' +
              j_vis_kol_detail[a].toLowerCase() +
              '_d_indicator" style="display: none"><img src="../img/indicator_arrows.gif" alt="Vent..." />'
          );
        }

        result.push("</th>");
      }
      result.push('<td width="65px"></td>'); //  knap-kolonne
      result.push("</tr>");

      var a = 0;

      if (
        detdom[0].getElementsByTagName(j_p_key_detail[0] + "_PK")[0]
          .childNodes[0] &&
        detdom[0].getElementsByTagName(j_p_key_detail[0] + "_PK")[0]
          .childNodes[0].nodeValue
      ) {
        for (var a = 0; a < detdom.length; a++) {
          result.push(
            '<tr class="trNormal" onmouseout="normalRowS(this,!document.getElementById(' +
              "'GEM" +
              a +
              "'" +
              ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
              "'GEM" +
              a +
              "'" +
              ').style.display);">'
          );
          for (var b = 0; b < j_vis_kol_detail.length; b++) {
            var detelmdom = detdom[a].getElementsByTagName(j_vis_kol_detail[b]);

            var input_style = "";
            if (!j_hide_detail[b] && j_kol_tekst_width_detail[b]) {
              if (j_kol_tekst_width_detail[b].indexOf("px") > 0) {
                input_style =
                  "width: " +
                  (j_kol_tekst_width_detail[b].replace("px", "") -
                    parseInt(input_reduktion)) +
                  "px;";
              }
            }

            if (j_kol_type_detail[b] == null) {
              if (j_hide_detail[b] == 1) {
                var type = "hidden";
                var style = 'style="display: none"';
              } else {
                var type = "text";
                var style = "";
              }

              if (
                typeof j_onchange_detail != "undefined" &&
                j_onchange_detail[b] != null
              )
                var onChange =
                  " onChange=\u0022" +
                  j_onchange_detail[b] +
                  "(" +
                  a +
                  ");\u0022";
              else var onChange = "";

              if (!detelmdom[0].childNodes[0]) {
                result.push(
                  "<td " +
                    style +
                    "class=\u0022left\u0022><input " +
                    onChange +
                    " style=\u0022" +
                    input_style +
                    ' border: none; background: transparent\u0022 type="' +
                    type +
                    '" name="' +
                    j_vis_kol_detail[b] +
                    a +
                    '" id="' +
                    j_vis_kol_detail[b] +
                    a +
                    '"  readonly    value=""/>'
                );
                if (j_combo_detail[b] == 1) {
                  if (j_combo_width_detail[b]) {
                    var c_style =
                      'style="width: ' + j_combo_width_detail[b] + ';"';
                  } else {
                    var c_style = 'style=""';
                  }

                  result.push(
                    '<div id="' +
                      j_vis_kol_detail[b] +
                      a +
                      '_CHOICES" class="autocomplete" ' +
                      c_style +
                      "></div>"
                  );
                }
                result.push("</td>");
              } else {
                var value = detelmdom[0].childNodes[0].nodeValue;

                result.push(
                  "<td " +
                    style +
                    'class="left">' +
                    "<input " +
                    onChange +
                    ' title="' +
                    escapePling(detelmdom[0].childNodes[0].nodeValue) +
                    '" type="' +
                    type +
                    '" id="' +
                    j_vis_kol_detail[b] +
                    a +
                    '" name="' +
                    j_vis_kol_detail[b] +
                    a +
                    '"'
                );
                if (
                  typeof j_name_attr_detail != "undefined" &&
                  j_name_attr_detail[b] == 1
                ) {
                  result.push(' name="' + j_vis_kol_detail[b] + a + '" ');
                }

                result.push(
                  ' style="' +
                    input_style +
                    '" readonly value="' +
                    escapePling(detelmdom[0].childNodes[0].nodeValue) +
                    '"/>'
                );

                if (j_combo_detail[b] == 1) {
                  if (j_combo_width_detail[b]) {
                    var c_style =
                      'style="width: ' + j_combo_width_detail[b] + ';"';
                  } else {
                    var c_style = 'style=""';
                  }

                  result.push(
                    '<div id="' +
                      j_vis_kol_detail[b] +
                      a +
                      '_CHOICES" class="autocomplete" ' +
                      c_style +
                      "></div>"
                  );
                }

                result.push("</td>");
              }
            } else {
              if (j_kol_type_detail[b] == "CHECKBOX") {
                //
                //     CHECKBOX
                //
                if (!detelmdom[0].childNodes[0]) {
                  result.push(
                    "<td class=\u0022left\u0022 >" +
                      "<input value='0' type=\u0022CHECKBOX\u0022 disabled" +
                      " style=\u0022" +
                      input_style +
                      "\u0022" +
                      ' name="' +
                      j_vis_kol_detail[b] +
                      a +
                      '" id="' +
                      j_vis_kol_detail[b] +
                      a +
                      '"' +
                      ' unchecked onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" />' +
                      "</td>"
                  );
                } else {
                  if (detelmdom[0].childNodes[0].nodeValue == 0) {
                    result.push(
                      "<td class=\u0022left\u0022  >" +
                        "<input value='0' type=\u0022CHECKBOX\u0022 disabled" +
                        " style=\u0022" +
                        input_style +
                        "\u0022" +
                        ' name="' +
                        j_vis_kol_detail[b] +
                        a +
                        '" id="' +
                        j_vis_kol_detail[b] +
                        a +
                        '"' +
                        ' unchecked  onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};"/>' +
                        "</td>"
                    );
                  } else {
                    result.push(
                      "<td class=\u0022left\u0022 >" +
                        "<input value='1' type=\u0022CHECKBOX\u0022 disabled" +
                        " style=\u0022" +
                        input_style +
                        "\u0022" +
                        ' name="' +
                        j_vis_kol_detail[b] +
                        a +
                        '" id="' +
                        j_vis_kol_detail[b] +
                        a +
                        '"' +
                        ' checked onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};"/>' +
                        "</td>"
                    );
                  }
                }
              }

              if (j_kol_type_detail[b] == "LOV") {
                if (j_kol_tekst_width_detail[b]) {
                  var width_text =
                    'width: "' + j_kol_tekst_width_detail[b] + '"';
                } else {
                  var width_text = "";
                }
                if (!detelmdom[0].childNodes[0]) {
                  result.push(
                    '<td><div id="' +
                      j_vis_kol_detail[b] +
                      a +
                      '" style="position:relative; ' +
                      width_text +
                      '">' +
                      "</div></td>"
                  );
                } else {
                  result.push(
                    '<td><div id="' +
                      j_vis_kol_detail[b] +
                      a +
                      '" style="position:relative; ' +
                      width_text +
                      '">' +
                      detelmdom[0].childNodes[0].nodeValue +
                      "</div></td>"
                  );
                }
              }
            }

            if (j_kol_type_detail[b] == "LINK") {
              //
              //     LINK
              //

              if (j_hide_detail[b] == 1) {
                var type = "hidden";
                var style = 'style="display: none; "';
              } else {
                var type = "text";
                var style = "";
              }

              result.push(
                "<td " +
                  style +
                  " align=\u0022center\u0022 " +
                  ' name="' +
                  j_vis_kol_detail[b] +
                  a +
                  '" id="' +
                  j_vis_kol_detail[b] +
                  a +
                  '"' +
                  ">" +
                  "&nbsp;" +
                  "<a href=\u0022javascript:" +
                  j_id_funk_detail[b] +
                  "('D'," +
                  a +
                  ",['"
              );

              for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                for (var jj = 0; jj < j_p_key_detail.length; jj++) {
                  var detiddom = detdom[a].getElementsByTagName(
                    j_p_key_detail[jj] + "_PK"
                  );

                  if (detiddom[0].childNodes[0]) {
                    result.push(detiddom[0].childNodes[0].nodeValue + "','");
                  } else {
                    result.push(" ");
                  }
                }
              }
              var gif_name = link_gif_detail(a, b, detdom[a]); //row, column
              result_str = result.pop();
              result.push(
                result_str.substr(0, result_str.length - 2) +
                  "]);\u0022>" +
                  "<img style='margin: 2px 0px 0px 0px;' src=\u0022" +
                  gif_name +
                  "\u0022 border=\u00220\u0022 title=\u0022" +
                  j_alt_tekst_detail[b] +
                  "\u0022>" +
                  "</a>" +
                  "</td>"
              );
            }

            if (j_kol_type_detail[b] == "SPECIAL") {
              //
              //     SPECIAL
              //

              var z = j_id_funk_detail[b] + "(" + a + ",detdom)";

              var zz = eval(z);

              result.push("<td>" + zz + "</td>");
            }
          }

          result.push('<td width="65px">');

          if (!retteMulighedD(a, detdom)) {
            result.push('<div style="margin: 0px 0px 0px 2px">');

            result.push(
              '<a name="RET' +
                a +
                '" id="RET' +
                a +
                '"> <img src="' +
                blankIcon +
                '"/></a>'
            );

            result.push(
              '<a style="display: none;" name="GEM' + a + '" id="GEM' + a + '" '
            );

            result.push('"> <img src="' + blankIcon + '"/></a>');
          } else {
            result.push('<div style="margin: 0px 0px 0px 2px">');

            result.push(
              '<a style="display: none;"name="GEM' +
                a +
                '" id="GEM' +
                a +
                '" title="Gem" href="javascript:'
            );

            result.push("GemD(" + a + ",['");

            for (var jj = 0; jj < j_p_key_detail.length; jj++) {
              var detiddom = detdom[a].getElementsByTagName(
                j_p_key_detail[jj] + "_PK"
              );

              if (detiddom[0].childNodes[0]) {
                result.push(detiddom[0].childNodes[0].nodeValue + "','");
              } else {
                result.push(" ");
              }
            }

            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 2) + "]);");

            result.push('"> <img src="' + gemIcon + '"/></a>');

            result.push(
              '<a name="RET' +
                a +
                '" id="RET' +
                a +
                '" title="Ret" href="javascript:RetD(' +
                a +
                ');"> <img src="' +
                retIcon +
                '"/></a>'
            );

            result.push(
              '<a  style="display: none" name="SLET' +
                a +
                '" id="SLET' +
                a +
                '" title="Slet" href="javascript:'
            );

            result.push("SletD(" + a + ",['");

            for (var jj = 0; jj < j_p_key_detail.length; jj++) {
              var detiddom = detdom[a].getElementsByTagName(
                j_p_key_detail[jj] + "_PK"
              );

              if (detiddom[0].childNodes[0]) {
                result.push(detiddom[0].childNodes[0].nodeValue + "','");
              } else {
                result.push(" ");
              }
            }

            result_str = result.pop();
            result.push(result_str.substr(0, result_str.length - 2) + "]);");

            result.push('"> <img src="' + sletIcon + '"/></a>');
          }

          result.push(
            '<a style="display: none;" name="KOPIER' +
              a +
              '" id="KOPIER' +
              a +
              '" title="Kopier" href="javascript:KopierD(' +
              a +
              "," +
              detdom.length +
              ');"> <img src="' +
              kopierIcon +
              '"/></a>'
          );

          result.push(
            '<a style="display: none;" name="FORTRYD' +
              a +
              '" id="FORTRYD' +
              a +
              '" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
              fortrydIcon +
              '"/></a></div></td>'
          );

          result.push("</div></td>");

          result.push("</tr>");
        }
      }
    }

    if (typeof retteMulighedDNy == "undefined" || retteMulighedDNy(a, resdom)) {
      var hide_style = "";
    } else {
      var hide_style = 'style="display: none"';
    }

    result.push(
      "<tr " +
        hide_style +
        ' onmouseout="normalRowS(this,!document.getElementById(' +
        "'GEM" +
        a +
        "'" +
        ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
        "'GEM" +
        a +
        "'" +
        ').style.display);">'
    );
    for (var c = 0; c < j_vis_kol_detail.length; c++) {
      var input_style = "";
      if (!j_hide_detail[c] && j_kol_tekst_width_detail[c]) {
        if (j_kol_tekst_width_detail[c].indexOf("px") > 0) {
          input_style =
            "width: " +
            (j_kol_tekst_width_detail[c].replace("px", "") -
              parseInt(input_reduktion)) +
            "px;";
        }
      }

      if (j_kol_type_detail[c] == null) {
        if (j_hide_detail[c] == 1) {
          var type = "hidden";
          var style = 'style="display: none"';
        } else {
          var type = "text";
          var style = "";
        }

        if (
          typeof j_onchange_detail != "undefined" &&
          j_onchange_detail[c] != null
        )
          var onChange =
            " onChange=\u0022" + j_onchange_detail[c] + "(" + a + ");\u0022";
        else var onChange = "";

        if (j_vis_default_detail[c]) {
          var val = j_vis_default_detail[c];
        } else {
          var val = "";
        }

        result.push(
          "<td " +
            style +
            "class=\u0022left\u0022><input " +
            onChange +
            " readonly style=\u0022" +
            input_style +
            '; border: none;\u0022 type="' +
            type +
            '" name="' +
            j_vis_kol_detail[c] +
            a +
            '" id="' +
            j_vis_kol_detail[c] +
            a +
            '"  value="' +
            val +
            '"/>'
        );
        if (j_combo_detail[c] == 1) {
          if (j_combo_width_detail[c]) {
            var c_style = 'style="width: ' + j_combo_width_detail[c] + ';"';
          } else {
            var c_style = 'style=""';
          }

          result.push(
            '<div id="' +
              j_vis_kol_detail[c] +
              a +
              '_CHOICES" class="autocomplete" ' +
              c_style +
              "></div>"
          );
        }
        result.push("</td>");
      } else {
        if (j_kol_type_detail[c] == "CHECKBOX") {
          result.push(
            "<td class=\u0022left\u0022>" +
              "<input type=\u0022CHECKBOX\u0022 " +
              " style=\u0022" +
              input_style +
              "\u0022" +
              ' name="' +
              j_vis_kol_detail[c] +
              a +
              '" id="' +
              j_vis_kol_detail[c] +
              a +
              '"' +
              ' unchecked disabled value="0" onClick="javascript:if (this.value==0) {this.value=1;  }else{this.value=0;};" />' +
              "</td>"
          );
        } else {
          if (j_kol_type_detail[c] == "LOV") {
            if (j_kol_tekst_width_detail[c]) {
              var width_text = 'width: "' + j_kol_tekst_width_detail[c] + '"';
            } else {
              var width_text = "";
            }
            result.push(
              '<td><div id="' +
                j_vis_kol_detail[c] +
                a +
                '" style="position:relative;' +
                width_text +
                '"></div></td>'
            );
          }
          if (j_kol_type_detail[c] == "LINK") {
            if (j_hide_detail[c] == 1) {
              var style = 'style="display: none"';
            } else {
              var style = "";
            }

            result.push(
              '<td align=center id="' +
                j_vis_kol_detail[c] +
                a +
                '" name="' +
                j_vis_kol_detail[c] +
                a +
                '" ' +
                style +
                "></td>"
            );
          }

          if (j_kol_type_detail[c] == "SPECIAL") {
            //
            //     SPECIAL
            //
            var z = j_id_funk_detail[c] + "(" + a + ",null)";

            var zz = eval(z);

            result.push("<td>" + zz + "</td>");
          }
        }
      }
    }
    result.push('<td width="65px"><div style="margin: 0px 0px 0px 2px">');
    result.push(
      '<a style="display: none;" name="GEM' +
        a +
        '" id="GEM' +
        a +
        '" title="Gem" href="javascript:GemDNy(' +
        a +
        ');"> <img src="' +
        gemIcon +
        '"/></a>'
    );

    result.push(
      '<a name="RET' +
        a +
        '" id="RET' +
        a +
        '" title="Ret" href="javascript:RetDNy(' +
        a +
        ');"> <img src="' +
        retIcon +
        '"/></a>'
    );

    result.push(
      '<a style="display: none;" name="FORTRYD' +
        a +
        '" id="FORTRYD' +
        a +
        '" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
        fortrydIcon +
        '"/></a></div></td>'
    );

    result.push("</div></td>");

    result.push("</tr>");

    result.push("</table>");

    j_detail_count = a;
    j_antal_gemXX = a;

    if (j_detail_count > 9) {
      result.push("<br/><br/><br/><br/><br/>");
    }

    document.getElementById(j_span).innerHTML = result.join("");
  } else if (fejl < 0) {
    ventOff();
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = Array();
    result.push("<div>");
    result.push(
      "SQL:" +
        resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push(
      "FEJL:" +
        resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
        "<br><br>"
    );
    result.push("</div>");
    document.getElementById(j_span).innerHTML = result.join("");
  }

  autocompleterMaster();

  if (
    detdom[0].getElementsByTagName(j_p_key_detail[0] + "_PK")[0].childNodes[0]
  ) {
    for (var a = 0; a < detdom.length + 1; a++) {
      autocompleterDetail(a);
    }
  } else {
    autocompleterDetail(0);
  }

  lovSetupMaster();

  if (
    detdom[0].getElementsByTagName(j_p_key_detail[0] + "_PK")[0].childNodes[0]
  ) {
    for (var a = 0; a < detdom.length + 1; a++) {
      // for each detail row
      lovSetupDetail(a, detdom.length);
    }
  } else {
    lovSetupDetail(0, 0);
  }
};

exports.fillContents = (c_result) => {
  //
  //  Opdater opdater-skærmens felter med rækkens værdier
  //
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var antal = getResultSize(resdom);
    if (antal == 0) {
      alert("Der er sket en fejl, id findes ikke");
      return;
    }
    if (antal > 1) {
      alert("Der er sket en fejl, for mange rækkere med givet ID");
      return;
    }
    for (var i = 0; i < j_vis_kol.length; i++) {
      var testnull = resdom.getElementsByTagName(j_vis_kol[i])[0].childNodes[0];
      if (!testnull) {
        document.getElementById(j_vis_kol[i]).value = "";
      } else {
        document.getElementById(j_vis_kol[i]).value =
          resdom.getElementsByTagName(j_vis_kol[i])[0].childNodes[0].nodeValue;
      }
    }
    //
    //  Husk ID til brug ved INSERT statement
    //
    if (document.getElementById(j_id).value == "") {
      var testnull = resdom.getElementsByTagName(j_id)[0].childNodes[0];
      if (testnull) {
        document.getElementById(j_id).value =
          resdom.getElementsByTagName(j_id)[0].childNodes[0].nodeValue;
      }
    }

    document.getElementById(j_span).innerHTML = j_message;
  } else {
    alert("Der opstod en fejl, fejl=" + fejl + exports.getErrorMessage(resdom));
  }
};

exports.quickContents = (c_q_id, c_result) => {
  //
  //  Opdater div-feltet c_q_id felter med database-værdi
  //
  if (
    (document.getElementById(c_q_id).type == "hidden") |
    (document.getElementById(c_q_id).type == "text")
  ) {
    document.getElementById(c_q_id).value = c_result;
  } else {
    document.getElementById(c_q_id).innerHTML = c_result;
  }
};

exports.cookieContents = (c_q_id, c_result) => {
  exports.SetCookie("session_id", Number(c_result.trim()));
  exports.makeRequest('/ords/mastra/osa.im_trans.get_quickrequest?i_q_id=', "password_age_ny", "f1", 31);
};

exports.checkPasswordAge = (c_result) => {
  if (c_result == -1) {
    alert("Din adgangskode er udløbet. Tryk OK for at skifte adgangskode");

    var pw = window.open("../system/SkiftAdgangskode.html?type=1", "_self");
    window.pw.reload();
  }

  if (c_result > 0) {
    alert(
      "Din adgangskode udløber om " +
        c_result +
        " dag(e).\n\nAdgangskoden kan ændres via et skærmbillede\nunder System-menuen.\n"
    );
  }
};

exports.lovContents = (c_lov_id, c_result, c_field_id, c_rownum) => {
  //
  //  Opdater div-feltet c_lov_id felter med list-of-values
  //
  if (typeof c_rownum != "undefined") var idx = c_rownum;
  else var idx = 0;

  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var result = '<select id="' + c_field_id + '" name="' + c_field_id + '">';

    var x01 = resdom.getElementsByTagName(j_lov[idx]);
    //
    //  Data
    //
    result = result + "<option value=\u0022\u0022></option>";
    for (var i = 0; i < x01.length; i++) {
      //
      //  Vi går ud fra, at IDen ikke kan blive null, kun beskrivelsen
      //
      var testnull = resdom.getElementsByTagName(
        j_lov[Number(idx) + Number(1)]
      )[i].childNodes[0];
      if (!testnull) {
        result =
          result +
          "<option value=\u0022" +
          resdom.getElementsByTagName(j_lov[idx])[i].childNodes[0].nodeValue +
          "\u0022>" +
          "</option>";
      } else {
        result =
          result +
          "<option value=\u0022" +
          resdom.getElementsByTagName(j_lov[idx])[i].childNodes[0].nodeValue +
          "\u0022>" +
          resdom.getElementsByTagName(j_lov[Number(idx) + Number(1)])[i]
            .childNodes[0].nodeValue +
          "</option>";
      }
    }
    result = result + "</select>";
  } else {
    var c_resstr = exports.serializeDom(resdom);
    var result = "<div>";
    result =
      result +
      "SQL:" +
      resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result =
      result +
      "FEJL:" +
      resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result = result + "</div>";
  }

  if (c_lov_id.indexOf("&") > 0)
    document.getElementById(
      c_lov_id.substr(0, c_lov_id.indexOf("&"))
    ).innerHTML = result;
  else document.getElementById(c_lov_id).innerHTML = result;
};

exports.lovContentsMD = (c_lov_id, c_result, c_field_id) => {
  //
  //  Opdater div-feltet c_lov_id felter med list-of-values
  //
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var result =
      "<select value='" +
      document.getElementById(c_field_id).innerHTML +
      "'  disabled style='width: 100%' id='" +
      c_field_id +
      "LOV' name='" +
      c_field_id +
      "LOV'>";
    var x01 = resdom.getElementsByTagName(j_lov[0]);
    //
    //  Data
    //
    result = result + "<option value=\u0022\u0022></option>";
    for (var i = 0; i < x01.length; i++) {
      //
      //  Vi går ud fra, at IDen ikke kan blive null, kun beskrivelsen
      //
      var testnull = resdom.getElementsByTagName(j_lov[1])[i].childNodes[0];
      if (!testnull) {
        result =
          result +
          "<option value=\u0022" +
          resdom.getElementsByTagName(j_lov[0])[i].childNodes[0].nodeValue +
          "\u0022>" +
          "</option>";
      } else {
        if (
          document.getElementById(c_field_id).innerHTML ==
          resdom.getElementsByTagName(j_lov[0])[i].childNodes[0].nodeValue
        ) {
          var selected = "Selected='Selected'";
        } else {
          var selected = "";
        }
        result =
          result +
          "<option " +
          selected +
          "value=\u0022" +
          resdom.getElementsByTagName(j_lov[0])[i].childNodes[0].nodeValue +
          "\u0022>" +
          resdom.getElementsByTagName(j_lov[1])[i].childNodes[0].nodeValue +
          "</option>";
      }
    }
    result = result + "</select>";
  } else {
    var c_resstr = exports.serializeDom(resdom);
    var result = "<div>";
    result =
      result +
      "SQL:" +
      resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result =
      result +
      "FEJL:" +
      resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result = result + "</div>";
  }
  document.getElementById(c_field_id).innerHTML = result;
};

exports.beskedContents = (c_result) => {
  //
  //  Opdater span felt (c_div_id) med menu
  //

  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    if (resdom.getElementsByTagName("BESKED")[0]) {
      for (var i = 0; i < resdom.getElementsByTagName("BESKED").length; i++) {
        alert(resdom.getElementsByTagName("BESKED")[i].childNodes[0].nodeValue);
      }
    }
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = "<div>";
    result =
      result +
      "SQL:" +
      resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result =
      result +
      "FEJL:" +
      resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result = result + "</div>";

    alert(result);
  }
};

exports.menuContents = (c_div_id, c_result, c_field_id) => {
  //
  //  Opdater span felt (c_div_id) med menu
  //
  var resdom = parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl == 0) {
    var result = '<ul id="dd">';
    var x01 = resdom.getElementsByTagName(c_div_id);
    for (var i = 0; i < x01.length; i++) {
      var aktNiveau =
        resdom.getElementsByTagName("NIVEAU")[i].childNodes[0].nodeValue;
      var aktId =
        resdom.getElementsByTagName("MENU_ID")[i].childNodes[0].nodeValue;
      var aktBeskrivelse =
        resdom.getElementsByTagName("BESKRIVELSE")[i].childNodes[0].nodeValue;

      if (aktNiveau == 1) {
        if (!resdom.getElementsByTagName("URL")[i].childNodes[0]) {
          result = result + "<li>";
          result =
            result +
            '<a href="javascript:mopen(' +
            aktId +
            ');" class="menu" id="mmenu"' +
            aktId +
            '" onmouseover="mopen(' +
            aktId +
            ');" onmouseout="mclosetime();">' +
            aktBeskrivelse +
            "</a>";
          if (i < 3) {
            result =
              result +
              '<div class="submenuRight" id="menu' +
              aktId +
              '" onmouseover="mcancelclosetime()" onmouseout="mclosetime();">';
          } else {
            result =
              result +
              '<div class="submenu" id="menu' +
              aktId +
              '" onmouseover="mcancelclosetime()" onmouseout="mclosetime();">';
          }

          var x02 = resdom.getElementsByTagName(c_div_id);
          for (var i2 = 0; i2 < x02.length; i2++) {
            if (resdom.getElementsByTagName("URL")[i2].childNodes[0]) {
              var aktSubUrl =
                resdom.getElementsByTagName("URL")[i2].childNodes[0].nodeValue;
            } else {
              var aktSubUrl = "";
            }

            var aktSubFarId =
              resdom.getElementsByTagName("FAR_MENU_ID")[i2].childNodes[0]
                .nodeValue;
            var aktSubBeskrivelse =
              resdom.getElementsByTagName("BESKRIVELSE")[i2].childNodes[0]
                .nodeValue;
            if (aktSubFarId == aktId) {
              result =
                result +
                '<a onClick="(menuLog(' +
                "'" +
                aktSubBeskrivelse.replace("'", "") +
                "'" +
                '))" href="' +
                aktSubUrl +
                '" >' +
                aktSubBeskrivelse +
                "</a>";
            }
          }
          result = result + "</div>";
        } else {
          result = result + "<li>";
          result =
            result +
            '<a onclick="menuLog(' +
            "'" +
            aktBeskrivelse +
            "'" +
            ');" href="' +
            resdom.getElementsByTagName("URL")[i].childNodes[0].nodeValue +
            '" class="menu">' +
            aktBeskrivelse +
            "</a>";
        }

        result = result + "</li>";
      }
    }
    result = result + "</ul>";

    if (resdom.getElementsByTagName("BESKED")[0]) {
      for (var i = 0; i < resdom.getElementsByTagName("BESKED").length; i++) {
        alert(resdom.getElementsByTagName("BESKED")[i].childNodes[0].nodeValue);
      }
    }
  } else if (fejl == -1) {
    alert(resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue);
    result = "";
  } else {
    var c_resstr = exports.serializeDom(resdom);
    alert("Modtaet XML " + c_resstr);
    var result = "<div>";
    result =
      result +
      "SQL:" +
      resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result =
      result +
      "FEJL:" +
      resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
      "<br><br>";
    result = result + "</div>";
  }
  document.getElementById(c_div_id).innerHTML = result;
};

// exports.sletContents(c_result) {
//   //
//   //  Checker at sletningen er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert(
//       "Der opstod en fejl ved sletningen, fejlnr=" +
//         fejl +
//         "," +
//         exports.getErrorMessage(resdom)
//     );
//   }
// }

// exports.sletContentsMD(c_result) {
//   //
//   //  Checker at sletningen er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved sletningen: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key_master.length; j++) {
//       vars.push(j_p_key_master[j]);
//       vars[j_p_key_master[j]] = document.getElementById(
//         j_p_key_master[j]
//       ).value;
//     }
//     initSide(vars);
//   }
// }

// exports.sletContentsMDM(c_result) {
//   //
//   //  Checker at sletningen er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved sletningen: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key_master.length; j++) {
//       vars.push(j_p_key_master[j]);
//       vars[j_p_key_master[j]] = "";
//     }
//     initSide(vars);
//   }
// }

// exports.opretContentsMD(c_result) {
//   //
//   //  Checker at opret er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved oprettelse: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key_master.length; j++) {
//       vars.push(j_p_key_master[j]);
//       vars[j_p_key_master[j]] = document.getElementById(
//         j_p_key_master[j]
//       ).value;
//     }
//     initSideSpinner(vars);
//   }
// }

// exports.opretContentsMDM(c_result) {
//   //
//   //  Checker at opret er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved oprettelse: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key_master.length; j++) {
//       vars.push(j_p_key_master[j]);
//       vars[j_p_key_master[j]] = resdom.getElementsByTagName(
//         j_p_key_master[j]
//       )[0].childNodes[0].nodeValue;
//     }
//     initSideSpinner(vars);
//   }
// }

// exports.opdaterContentsMD(c_result, row) {
//   //
//   //  Checker at sletningen er gået godt
//   //

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved ændring: " + exports.getErrorMessage(resdom));
//   } else {
//     if (row >= 0) {
//       for (var i = 0; i < j_vis_kol_detail.length; i++) {
//         if (j_kol_type_detail[i] == "CHECKBOX") {
//           document.getElementById(j_vis_kol_detail[i] + row).disabled = true;
//         }
//         if (j_kol_type_detail[i] == "LOV") {
//           document.getElementById(
//             j_vis_kol_detail[i] + row + "LOV"
//           ).disabled = true;
//         }
//         if (j_kol_type_detail[i] == "SPECIAL") {
//           document.getElementById(j_vis_kol_detail[i] + row).disabled = true;
//         }
//         if (!j_kol_type_detail[i]) {
//           document.getElementById(j_vis_kol_detail[i] + row).readOnly = true;
//         }

//         document.getElementById(j_vis_kol_detail[i] + row).style.background =
//           "transparent";
//       }

//       document.getElementById("RET").style.display = "";

//       document.getElementById("GEM" + row).style.display = "none";
//       document.getElementById("SLET" + row).style.display = "none";
//       document.getElementById("KOPIER" + row).style.display = "none";
//       document.getElementById("FORTRYD" + row).style.display = "none";

//       for (var i = 0; i < j_detail_count; i++) {
//         document.getElementById("RET" + i).style.display = "";
//       }

//       if (
//         (typeof retteMulighedDNy != "undefined" &&
//           retteMulighedDNy(j_detail_count, resdom)) ||
//         typeof retteMulighedDNy == "undefined"
//       ) {
//         document.getElementById("RET" + j_detail_count).style.display = "";
//       }
//     } else {
//       for (var i = 0; i < j_vis_kol_master.length; i++) {
//         if (j_kol_type_master[i] == "CHECKBOX") {
//           document.getElementById(j_vis_kol_master[i]).disabled = true;
//         }

//         if (j_kol_type_master[i] == "LOV") {
//           document.getElementById(
//             j_vis_kol_master[i] + "LOV"
//           ).disabled = true;
//         }

//         if (!j_kol_type_master[i]) {
//           document.getElementById(j_vis_kol_master[i]).readOnly = true;
//         }

//         document.getElementById(j_vis_kol_master[i]).style.background =
//           "transparent";
//       }

//       document.getElementById("RET").style.display = "";

//       document.getElementById("GEM").style.display = "none";
//       document.getElementById("SLET").style.display = "none";
//       document.getElementById("FORTRYD").style.display = "none";

//       for (var i = 0; i < j_detail_count; i++) {
//         document.getElementById("RET" + i).style.display = "";
//       }
//       if (
//         (typeof retteMulighedDNy != "undefined" &&
//           retteMulighedDNy(j_detail_count, resdom)) ||
//         typeof retteMulighedDNy == "undefined"
//       ) {
//         document.getElementById("RET" + j_detail_count).style.display = "";
//       }
//     }
//   }
// }

// exports.eksekverContents(c_result) {
//   //
//   //  Checker at eksekveringen af proceduren er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert(
//       "Der opstod en fejl ved eksekveringen, fejlnr=" +
//         fejl +
//         "," +
//         exports.getErrorMessage(resdom)
//     );
//   }
// }

// exports.showContentsUpd(c_result) {
//   //
//   //   Viser resultatet i et skærmbillede, hvor der kan være input-felter
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl == 0) {
//     var antal = getResultSize(resdom);
//     var RaekkeFra = getRaekkeFra(resdom);
//     var RaekkeTil = getRaekkeTil(resdom);

//     //
//     //  Kolonneoverskrift
//     //
//     var result = '<table cellspacing="1" cellpadding="2">';
//     result = result + "<tr>";
//     for (var j = 0; j < j_kol_tekst.length; j++) {
//       if (typeof j_hide_kol != "undefined" && j_hide_kol[j] == 1) {
//         var style = "display: none;";
//       } else {
//         var style = "";
//       }

//       if (typeof j_kol_tekst_width != "undefined" && j_kol_tekst_width[j]) {
//         style = style + " width: " + j_kol_tekst_width[j] + ";";
//       }

//       var k = j + 1;
//       if (j_kol_type[j] == null && j < j_sort.length) {
//         result =
//           result +
//           '<th style="' +
//           style +
//           '" title="' +
//           "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
//           j_kol_tekst[j] +
//           "] body=[" +
//           j_alt_tekst[j] +
//           ']">' +
//           "<a href=\u0022javascript:sorter(" +
//           antal +
//           "," +
//           RaekkeFra +
//           "," +
//           RaekkeTil +
//           "," +
//           k +
//           ",'" +
//           j_f +
//           "')\u0022>" +
//           j_kol_tekst[j] +
//           "</th>";
//       } else {
//         result =
//           result +
//           '<th style="' +
//           style +
//           '" title="' +
//           "cssbody=[dvbdy1] cssheader=[dvhdr1] header=[" +
//           j_kol_tekst[j] +
//           "] body=[" +
//           j_alt_tekst[j] +
//           ']">' +
//           j_kol_tekst[j] +
//           "</th>";
//       }
//     }
//     result = result + "</tr>";

//     var x01 = resdom.getElementsByTagName(j_vis_kol[0]);
//     //
//     //  Data
//     //
//     var c_counter = 0;
//     for (var i = 0; i < x01.length; i++) {
//       c_counter = c_counter + 1;
//       result =
//         result +
//         '<tr class="trNormal" onmouseover="highlightRow(this);" onmouseout="normalRow(this)">';
//       for (var j = 0; j < j_vis_kol.length; j++) {
//         if (j_kol_type[j] == null) {
//           if (j_vis_kol[j] != null) {
//             //
//             //     Fremvisning
//             //

//             var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
//               .childNodes[0];

//             var style = '""';
//             if (typeof j_hide_kol != "undefined" && j_hide_kol[j] == 1) {
//               style = 'style="display: none;"';
//             } else {
//               style = '""';
//             }

//             if (!testnull) {
//               if (j_input_kol[j] == null) {
//                 result =
//                   result + "<td class=\u0022left\u0022" + style + "></td>";
//               } else {
//                 result =
//                   result +
//                   "<td class=\u0022left inputBg\u0022" +
//                   style +
//                   ">" +
//                   "<input value='' " +
//                   "style='background:white; width:" +
//                   j_input_size[j] +
//                   "px;' " +
//                   "onChange=\u0022javascript:" +
//                   j_input_kol[j] +
//                   resdom.getElementsByTagName("ID")[i].childNodes[0]
//                     .nodeValue +
//                   "," +
//                   j_opener_id +
//                   "," +
//                   "this.value" +
//                   ")\u0022" +
//                   "/></td>";
//               }
//             } else {
//               if (j_input_kol[j] == null) {
//                 result =
//                   result +
//                   "<td class=\u0022left\u0022" +
//                   style +
//                   ">" +
//                   resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//                     .nodeValue +
//                   "</td>";
//               } else {
//                 result =
//                   result +
//                   "<td class=\u0022left inputBg\u0022" +
//                   style +
//                   ">" +
//                   "<input value='" +
//                   resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//                     .nodeValue +
//                   "' style='background:white; width:" +
//                   j_input_size[j] +
//                   "px' " +
//                   "onChange=\u0022javascript:" +
//                   j_input_kol[j] +
//                   resdom.getElementsByTagName("ID")[i].childNodes[0]
//                     .nodeValue +
//                   "," +
//                   j_opener_id +
//                   "," +
//                   "this.value" +
//                   ")\u0022" +
//                   "/></td>";
//               }
//             }
//           }
//         } else {
//           if (j_kol_type[j] == "CHECKBOX") {
//             //
//             //     CHECKBOX
//             //
//             if (
//               resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//                 .nodeValue == 0
//             ) {
//               result =
//                 result +
//                 "<td class=\u0022left\u0022>" +
//                 "<input type=\u0022CHECKBOX\u0022" +
//                 " onClick=\u0022" +
//                 j_id_funk[j] +
//                 "('" +
//                 j_f +
//                 "','" +
//                 resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
//                 "'," +
//                 RaekkeFra +
//                 "," +
//                 RaekkeTil +
//                 ");\u0022/>" +
//                 "</td>";
//             } else {
//               result =
//                 result +
//                 "<td class=\u0022left\u0022>" +
//                 "<input type=\u0022CHECKBOX\u0022" +
//                 " onClick=\u0022" +
//                 j_id_funk[j] +
//                 "('" +
//                 j_f +
//                 "','" +
//                 resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
//                 "'," +
//                 RaekkeFra +
//                 "," +
//                 RaekkeTil +
//                 ");\u0022 checked/>" +
//                 "</td>";
//             }
//           }
//           if (j_kol_type[j] == "LINK") {
//             //
//             //     LINK
//             //
//             result =
//               result +
//               "<td align=\u0022center\u0022>" +
//               "&nbsp;" +
//               "<a href=\u0022javascript:" +
//               j_id_funk[j] +
//               "('" +
//               j_f +
//               "','" +
//               resdom.getElementsByTagName("ID")[i].childNodes[0].nodeValue +
//               "'," +
//               RaekkeFra +
//               "," +
//               RaekkeTil +
//               ");\u0022>" +
//               "<img src=\u0022" +
//               j_id_gif[j] +
//               "\u0022 border=\u00220\u0022 alt=\u0022" +
//               j_alt_tekst[j] +
//               "\u0022>" +
//               "</a>" +
//               "</td>";
//           }
//         }
//       }
//       result = result + "</tr>";
//     }

//     result = result + "</table>";

//     result =
//       result +
//       "Viser " +
//       Math.min(RaekkeFra, antal) +
//       "-" +
//       Math.min(RaekkeTil, antal) +
//       " af " +
//       antal;

//     if (antal == 0) {
//       result = result + " (Ingen data fundet)";
//     }

//     result = result + "<div class=\u0022resultsNavigation\u0022>";

//     result =
//       result +
//       "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022foerste\u0022 onClick=" +
//       "\u0022javascript:foersteSaetUpd(" +
//       antal +
//       "," +
//       RaekkeFra +
//       "," +
//       RaekkeTil +
//       ",'" +
//       j_f +
//       "');\u0022 " +
//       " value=\u0022<< Første\u0022/>";

//     result =
//       result +
//       "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022forrige\u0022 onClick=" +
//       "\u0022javascript:forrigeSaetUpd(" +
//       antal +
//       "," +
//       RaekkeFra +
//       "," +
//       RaekkeTil +
//       ",'" +
//       j_f +
//       "');\u0022 " +
//       " value=\u0022< Forrige sæt\u0022/>";

//     result =
//       result +
//       "<input style=\u0022width:90px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022naeste\u0022 onClick=" +
//       "\u0022javascript:naesteSaetUpd(" +
//       antal +
//       "," +
//       RaekkeFra +
//       "," +
//       RaekkeTil +
//       ",'" +
//       j_f +
//       "');\u0022 " +
//       " value=\u0022Næste sæt >\u0022/>";

//     result =
//       result +
//       "<input style=\u0022width:60px;\u0022 class=\u0022button\u0022 type=\u0022button\u0022 id=\u0022sidste\u0022 onClick=" +
//       "\u0022javascript:sidsteSaetUpd(" +
//       antal +
//       "," +
//       RaekkeFra +
//       "," +
//       RaekkeTil +
//       ",'" +
//       j_f +
//       "');\u0022 " +
//       " value=\u0022Sidste >>\u0022/>";

//     result = result + "</div>";
//   } else {
//     var c_resstr = exports.serializeDom(resdom);
//     alert("Modtaet XML " + c_resstr);
//     var result = "<div>";
//     result =
//       result +
//       "SQL:" +
//       resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
//       "<br><br>";
//     result =
//       result +
//       "FEJL:" +
//       resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
//       "<br><br>";
//     result = result + "</div>";
//   }

//   document.getElementById(j_span).innerHTML = result;
// }

// exports.submitSoeg(
//   i_raekker_ialt,
//   i_raekke_fra,
//   i_raekke_til,
//   i_start_soeg,
//   c_f,
//   c_q_id
// ) {
//   //  Sæt globale værdier
//   j_reakker_ialt = i_raekker_ialt;
//   j_raekke_fra = i_raekke_fra;
//   j_raekke_til = i_raekke_til;
//   j_f = c_f;
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);
//   //         Søgekriterier

//   var el = document.forms[c_f].getElementsByTagName("input");
//   for (var i = 0; i < el.length; i++) {
//     //  Forbered checkboxe
//     if (el[i].type == "checkbox") {
//       if (el[i].checked) {
//         el[i].value = 1;
//       } else {
//         el[i].value = "";
//       }
//     }
//     if (
//       (el[i].type == "text") |
//         (el[i].type == "hidden") |
//         (el[i].type == "checkbox") &&
//       el[i].name != "" &&
//       el[i].name.indexOf("_new_value") == -1 &&
//       el[i].value != ""
//     ) {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].name);
//       var t = dom.createTextNode(
//         escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //        List-of-values
//   var el = document.forms[c_f].getElementsByTagName("select");
//   for (var i = 0; i < el.length; i++) {
//     if (el[i].name != "") {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].id);
//       var t = dom.createTextNode(
//         escape(
//           "<![CDATA[" +
//             encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//             "]]>"
//         )
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //       Parametre
//   var trans = dom.createElement("raekke_fra");
//   var t = dom.createTextNode(escape("<![CDATA[" + i_raekke_fra + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekke_til");
//   var t = dom.createTextNode(escape("<![CDATA[" + i_raekke_til + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekker_ialt");
//   var t = dom.createTextNode(escape("<![CDATA[" + i_raekker_ialt + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("start_soeg");
//   var t = dom.createTextNode(escape("<![CDATA[" + i_start_soeg + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("session_id");
//   var t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   var node2 = dom.createElement("ud_spec");
//   for (var i = 0; i < j_vis_kol.length; i++) {
//     if (j_vis_kol[i] != null && j_kol_type[i] != "LINK") {
//       if (visFelt(j_vis_kol[i])) {
//         var trans = dom.createElement("kolonne");
//         trans.setAttribute("navn", j_vis_kol[i]);
//         if (j_kol_vaerdier[i] != null) {
//           t = dom.createTextNode(
//             escape("<![CDATA[" + j_kol_vaerdier[i] + "]]>")
//           );
//           trans.appendChild(t);
//         }
//         node2.appendChild(trans);
//       }
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("kolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node2.appendChild(trans);
//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort.length; i++) {
//     if (j_sort[i] != null) {
//       var trans = dom.createElement("sortkolonne");
//       trans.setAttribute("navn", j_sort[i]);
//       trans.setAttribute("sortposition", i);
//       node2.appendChild(trans);
//     }
//   }
//   node.appendChild(node2);
//   root.appendChild(node);

//   var j_datotid = new Date();
//   var j_timer = j_datotid.getHours();
//   var j_minutter = j_datotid.getMinutes();
//   var j_sekunder = j_datotid.getSeconds();
//   var j_millisekunder = j_datotid.getMilliseconds();
//   var j_timestamp =
//     j_timer + ":" + j_minutter + ":" + j_sekunder + ":" + j_millisekunder;

//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//   makeRequest(
//     j_url + j_timestamp + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitSoegUdenId(
//   i_raekker_ialt,
//   i_raekke_fra,
//   i_raekke_til,
//   i_start_soeg,
//   c_f,
//   c_q_id
// ) {
//   //  Sæt globale værdier
//   j_reakker_ialt = i_raekker_ialt;
//   j_raekke_fra = i_raekke_fra;
//   j_raekke_til = i_raekke_til;
//   j_f = c_f;
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);
//   //         Søgekriterier

//   var el = document.forms[c_f].getElementsByTagName("input");
//   for (var i = 0; i < el.length; i++) {
//     //  Forbered checkboxe
//     if (el[i].type == "checkbox") {
//       if (el[i].checked) {
//         el[i].value = 1;
//       } else {
//         el[i].value = "";
//       }
//     }
//     if (
//       (el[i].type == "text") |
//         (el[i].type == "hidden") |
//         (el[i].type == "checkbox") &&
//       el[i].name != "" &&
//       el[i].name.indexOf("_new_value") == -1 &&
//       el[i].value != ""
//     ) {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].name);
//       var t = dom.createTextNode(
//         escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //        List-of-values
//   var el = document.forms[c_f].getElementsByTagName("select");
//   for (var i = 0; i < el.length; i++) {
//     if (el[i].name != "") {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].id);
//       var t = dom.createTextNode(
//         escape(
//           "<![CDATA[" +
//             encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//             "]]>"
//         )
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //       Parametre
//   var trans = dom.createElement("raekke_fra");
//   var t = dom.createTextNode(escape("<![CDATA[" + i_raekke_fra + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekke_til");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_til + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekker_ialt");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekker_ialt + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("start_soeg");
//   t = dom.createTextNode(escape("<![CDATA[" + i_start_soeg + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   var node2 = dom.createElement("ud_spec");
//   for (var i = 0; i < j_vis_kol.length; i++) {
//     if (j_vis_kol[i] != null && j_kol_type[i] != "LINK") {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", j_vis_kol[i]);
//       if (j_kol_vaerdier[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node2.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort.length; i++) {
//     if (j_sort[i] != null) {
//       var trans = dom.createElement("sortkolonne");
//       trans.setAttribute("navn", j_sort[i]);
//       trans.setAttribute("sortposition", i);
//       node2.appendChild(trans);
//     }
//   }

//   node.appendChild(node2);
//   root.appendChild(node);

//   var j_datotid = new Date();
//   var j_timer = j_datotid.getHours();
//   var j_minutter = j_datotid.getMinutes();
//   var j_sekunder = j_datotid.getSeconds();
//   var j_millisekunder = j_datotid.getMilliseconds();
//   var j_timestamp =
//     j_timer + ":" + j_minutter + ":" + j_sekunder + ":" + j_millisekunder;

//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//   makeRequest(
//     j_url + j_timestamp + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitSoegMD(
//   i_raekker_ialt,
//   i_raekke_fra,
//   i_raekke_til,
//   i_start_soeg,
//   c_f,
//   c_q_id
// ) {
//   //  Sæt globale værdier
//   j_reakker_ialt = i_raekker_ialt;
//   j_raekke_fra = i_raekke_fra;
//   j_raekke_til = i_raekke_til;
//   j_f = c_f;
//   //

//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   if (typeof j_tabelview != "undefined")
//     node1.setAttribute("navn", j_tabelview);

//   if (typeof j_tabelview_m != "undefined")
//     node1.setAttribute("navn", j_tabelview_m);

//   if (typeof j_tabelview_d != "undefined")
//     node1.setAttribute("navn_d", j_tabelview_d);

//   //         Søgekriterier
//   var el = document.forms[c_f].getElementsByTagName("input");
//   for (var i = 0; i < el.length; i++) {
//     //  Forbered checkboxe
//     if (el[i].type == "checkbox") {
//       if (el[i].checked) {
//         el[i].value = 1;
//       } else {
//         el[i].value = "";
//       }
//     }
//     if (
//       (el[i].type == "text") |
//         (el[i].type == "hidden") |
//         (el[i].type == "checkbox") &&
//       el[i].name != "" &&
//       el[i].name.indexOf("_new_value") == -1 &&
//       el[i].value != ""
//     ) {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].name);
//       var t = dom.createTextNode(
//         escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //        List-of-values
//   var el = document.forms[c_f].getElementsByTagName("select");
//   for (var i = 0; i < el.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", el[i].id);
//     t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }
//   //       Parametre
//   var trans = dom.createElement("raekke_fra");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_fra + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekke_til");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_til + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekker_ialt");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekker_ialt + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("start_soeg");
//   t = dom.createTextNode(escape("<![CDATA[" + i_start_soeg + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   node2 = dom.createElement("ud_spec");

//   node3 = dom.createElement("master");
//   for (var i = 0; i < j_vis_kol_master.length; i++) {
//     if (j_vis_kol_master[i] != null && j_kol_type_master[i] != "LINK") {
//       var trans = dom.createElement("mkolonne");
//       trans.setAttribute("navn", j_vis_kol_master[i]);
//       if (j_kol_vaerdier_master[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier_master[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node3.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("mkolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node3.appendChild(trans);

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort_master.length; i++) {
//     if (j_sort_master[i] != null) {
//       var trans = dom.createElement("msortkolonne");
//       trans.setAttribute("navn", j_sort_master[i]);
//       trans.setAttribute("sortposition", i);
//       node3.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_master.length; i++) {
//     if (j_p_key_master[i] != null) {
//       var trans = dom.createElement("mpkeykolonne");
//       trans.setAttribute("navn", j_p_key_master[i]);
//       node3.appendChild(trans);
//     }
//   }

//   node4 = dom.createElement("link");
//   for (var i = 0; i < j_link.length; i++) {
//     if (j_link[i] != null) {
//       var trans = dom.createElement("lkolonne");
//       trans.setAttribute("navn", j_link[i]);
//       node4.appendChild(trans);
//     }
//   }

//   node5 = dom.createElement("detail");
//   for (var i = 0; i < j_vis_kol_detail.length; i++) {
//     if (j_vis_kol_detail[i] != null && j_kol_type_detail[i] != "LINK") {
//       var trans = dom.createElement("dkolonne");
//       trans.setAttribute("navn", j_vis_kol_detail[i]);
//       if (j_kol_vaerdier_detail[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier_detail[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node5.appendChild(trans);
//     }
//   }

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort_detail.length; i++) {
//     if (j_sort_detail[i] != null) {
//       var trans = dom.createElement("dsortkolonne");
//       trans.setAttribute("navn", j_sort_detail[i]);
//       trans.setAttribute("sortposition", i);
//       node5.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_detail.length; i++) {
//     if (j_p_key_detail[i] != null) {
//       var trans = dom.createElement("dpkeykolonne");
//       trans.setAttribute("navn", j_p_key_detail[i]);
//       node5.appendChild(trans);
//     }
//   }

//   node2.appendChild(node3),
//     node2.appendChild(node4),
//     node2.appendChild(node5),
//     node.appendChild(node2);
//   root.appendChild(node);

//   var j_datotid = new Date();
//   var j_timer = j_datotid.getHours();
//   var j_minutter = j_datotid.getMinutes();
//   var j_sekunder = j_datotid.getSeconds();
//   var j_millisekunder = j_datotid.getMilliseconds();
//   var j_timestamp =
//     j_timer + ":" + j_minutter + ":" + j_sekunder + ":" + j_millisekunder;

//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//   makeRequest(
//     j_url + j_timestamp + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitEnkeltSoeg(
//   c_f,
//   i_tabelview,
//   i_resultat_felt,
//   i_soege_felt,
//   i_soege_vaerdi
// ) {
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", i_tabelview);
//   //         Søgekriterier
//   var trans = dom.createElement("felt");
//   trans.setAttribute("navn", i_soege_felt);
//   var t = dom.createTextNode(
//     escape("<![CDATA[" + encodeURIComponent(i_soege_vaerdi) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);
//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   node2 = dom.createElement("ud_spec");
//   var trans = dom.createElement("kolonne");
//   trans.setAttribute("navn", i_resultat_felt);
//   node2.appendChild(trans);
//   node.appendChild(node2);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//   var resultat = makeRequest(
//     j_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     9,
//     i_resultat_felt
//   );
//   return resultat;
// }

// exports.submitEnkeltSoegMD(
//   c_f,
//   i_tabelview,
//   i_resultat_felt,
//   i_soege_felt,
//   i_soege_vaerdi
// ) {
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", i_tabelview);
//   //         Søgekriterier

//   for (var i = 0; i < i_soege_vaerdi.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", i_soege_felt[i]);
//     var t = dom.createTextNode(
//       escape("<![CDATA[" + encodeURIComponent(i_soege_vaerdi[i]) + "]]>")
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   node2 = dom.createElement("ud_spec");
//   var trans = dom.createElement("mkolonne");
//   trans.setAttribute("navn", i_resultat_felt);
//   node2.appendChild(trans);
//   node.appendChild(node2);
//   root.appendChild(node);

//   var j_datotid = new Date();
//   var j_timer = j_datotid.getHours();
//   var j_minutter = j_datotid.getMinutes();
//   var j_sekunder = j_datotid.getSeconds();
//   var j_millisekunder = j_datotid.getMilliseconds();
//   var j_timestamp =
//     j_timer + ":" + j_minutter + ":" + j_sekunder + ":" + j_millisekunder;

//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//   var resultat = makeRequest(
//     j_url + j_timestamp + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     9,
//     i_resultat_felt
//   );
//   return resultat;
// }

exports.serializeDom = (dom) => {
  var xml = dom.xml;
  if (xml == undefined) {
    try {
      var serializer = new XMLSerializer();
      xml = serializer.serializeToString(dom);
      // delete serializer;
    } catch (e) {
      alert("DOM serialization er ikke understøttet.");
    }
  }
  return xml;
}

exports.implementFirefoxSelect = () => {
  if (document.implementation.hasFeature("XPath", "3.0")) {
    XMLDocument.prototype.selectNodes = function(cXPathString, xNode) {
      if (!xNode) {
        xNode = this;
      }
      var oNSResolver = this.createNSResolver(this.documentElement);
      var aItems = this.evaluate(
        cXPathString,
        xNode,
        oNSResolver,
        XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
        null
      );
      var aResult = [];
      for (var i = 0; i < aItems.snapshotLength; i++) {
        aResult[i] = aItems.snapshotItem(i);
      }
      return aResult;
    };

    XMLDocument.prototype.selectSingleNode = function(cXPathString, xNode) {
      if (!xNode) {
        xNode = this;
      }
      var xItems = this.selectNodes(cXPathString, xNode);
      if (xItems.length > 0) {
        return xItems[0];
      } else {
        return null;
      }
    };
    Element.prototype.selectNodes = function(cXPathString) {
      if (this.ownerDocument.selectNodes) {
        return this.ownerDocument.selectNodes(cXPathString, this);
      } else {
        throw "For XML Elements Only";
      }
    };
    Element.prototype.selectSingleNode = function(cXPathString) {
      if (this.ownerDocument.selectSingleNode) {
        return this.ownerDocument.selectSingleNode(cXPathString, this);
      } else {
        throw "For XML Elements Only";
      }
    };
    Node.prototype.transformNode = function(oXslDom) {
      var oProcessor = new XSLTProcessor();
      oProcessor.importStylesheet(oXslDom);
      var oResultDom = oProcessor.transformToDocument(this);
      var sResult = exports.serializeDom(oResultDom);
      if (sResult.indexOf("<transformiix:result") > -1) {
        sResult = sResult.substring(
          sResult.indexOf(">") + 1,
          sResult.lastIndexOf("<")
        );
      }
      return sResult;
    };
  }
}

exports.initXmlDoc = () => {
  var dom = exports.parseXml("<mastra/>");
  return dom;
}

exports.parseXml = (xml) => {
  dom = exports.createXmlDom();
  if (window.ActiveXObject) {
    dom.loadXML(xml);
  } else if (
    typeof document.implementation != "undefined" &&
    typeof document.implementation.createDocument != "undefined"
  ) {
    var parser = new DOMParser();
    dom = parser.parseFromString(xml, "text/xml");
  //   delete parser;
  } else {
    alert("XMLDom ikke understøttet.");
  }
  return dom;
}

exports.createXmlDom = () => {
  var dom = null;
  if (window.ActiveXObject) {
    dom = new ActiveXObject("Microsoft.XMLDOM");
    dom.setProperty("SelectionLanguage", "XPath");
    dom.async = false;
  } else if (
    typeof document.implementation != "undefined" &&
    typeof document.implementation.createDocument != "undefined"
  ) {
    exports. implementFirefoxSelect();
    dom = document.implementation.createDocument("", "doc", null);
    dom.async = false;
  } else {
    alert("XMLDom ikke understøttet.");
  }
  return dom;
}

// exports.forrigeSaet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }

//   j = i + j_vis_raekker - 1;

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',1)",
//     1
//   );
// }

// exports.naesteSaet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i_raekke_til < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }
//   if (i > i_raekker_ialt - j_vis_raekker) {
//     i = i_raekker_ialt - j_vis_raekker + 1;
//     j = i + j_vis_raekker - 1;
//   }
//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',1)",
//     1
//   );
// }

// exports.foersteSaet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',1)",
//     1
//   );
// }

// exports.sidsteSaet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',1)",
//     1
//   );
// }

// exports.forrigeSaetRet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }

//   j = i + j_vis_raekker - 1;

//   submitSoegUdenId(i_raekker_ialt, i, j, 0, i_f, 24);
// }

// exports.naesteSaetRet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i_raekke_til < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }
//   if (i > i_raekker_ialt - j_vis_raekker) {
//     i = i_raekker_ialt - j_vis_raekker + 1;
//     j = i + j_vis_raekker - 1;
//   }

//   submitSoegUdenId(i_raekker_ialt, i, j, 0, i_f, 24);
// }

// exports.foersteSaetRet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   submitSoegUdenId(i_raekker_ialt, i, j, 0, i_f, 24);
// }

// exports.sidsteSaetRet(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   submitSoegUdenId(i_raekker_ialt, i, j, 0, i_f, 24);
// }

// exports.forrigeSaetMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }
//   j = i + j_vis_raekker - 1;

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',11)",
//     1
//   );
// }

// exports.naesteSaetMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i_raekke_til < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }
//   if (i > i_raekker_ialt - j_vis_raekker) {
//     i = i_raekker_ialt - j_vis_raekker + 1;
//     j = i + j_vis_raekker - 1;
//   }
//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',11)",
//     1
//   );
// }

// exports.foersteSaetMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',11)",
//     1
//   );
// }

// exports.sidsteSaetMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',11)",
//     1
//   );
// }

// exports.forrigeSaetWMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }
//   j = i + j_vis_raekker - 1;

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',32)",
//     1
//   );
// }

// exports.naesteSaetWMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i_raekke_til < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }
//   if (i > i_raekker_ialt - j_vis_raekker) {
//     i = i_raekker_ialt - j_vis_raekker + 1;
//     j = i + j_vis_raekker - 1;
//   }
//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',32)",
//     1
//   );
// }

// exports.foersteSaetWMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',32)",
//     1
//   );
// }

// exports.sidsteSaetWMD(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   ventOn();
//   window.setTimeout(
//     "submitSoegMD(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',32)",
//     1
//   );
// }

// exports.forrigeSaetUpd(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }
//   j = i + j_vis_raekker - 1;

//   submitSoeg(i_raekker_ialt, i, j, 0, i_f, 10);
// }

// exports.naesteSaetUpd(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i + j_vis_raekker < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }

//   submitSoeg(i_raekker_ialt, i, j, 0, i_f, 10);
// }

// exports.foersteSaetUpd(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   submitSoeg(i_raekker_ialt, i, j, 0, i_f, 10);
// }

// exports.sidsteSaetUpd(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   submitSoeg(i_raekker_ialt, i, j, 0, i_f, 10);
// }

exports.getError = (i_dom) => {
  return i_dom
    .getElementsByTagName("MASTRARESULTAT")[0]
    .getAttributeNode("FEJL").value;
}

exports.getErrorMessage = (i_dom) => {
  return i_dom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue;
}

// exports.getResultSize(i_dom) {
//   return i_dom
//     .getElementsByTagName("MASTRARESULTAT")[0]
//     .getAttributeNode("ANTAL").value;
// }

// exports.getRaekkeFra(i_dom) {
//   return i_dom
//     .getElementsByTagName("MASTRARESULTAT")[0]
//     .getAttributeNode("RAEKKE_FRA").value;
// }

// exports.getRaekkeTil(i_dom) {
//   return i_dom
//     .getElementsByTagName("MASTRARESULTAT")[0]
//     .getAttributeNode("RAEKKE_TIL").value;
// }

// exports.gemOpdater(c_f, c_id) {
//   //
//   //     Kan både være INSERT/UPDATE
//   //
//   if (document.getElementById(j_id).value != "") {
//     // UPDATE
//     //
//     //     Gemmer de opdaterede oplysninger i databasen
//     //

//     var dom = initXmlDoc();
//     var el = document.forms[c_f].getElementsByTagName("input");
//     // var root = dom.selectSingleNode("/mastra");
//     var root = selectSingleNodeMastra(dom);
//     var node = null;
//     node = dom.createElement("opdater");
//     var node1 = dom.createElement("tabel");
//     node1.setAttribute("navn", j_tabelview);

//     var el = document.forms[c_f].getElementsByTagName("input");
//     for (var i = 0; i < el.length; i++) {
//       if (
//         (el[i].type == "text") | (el[i].type == "hidden") &&
//         el[i].id != j_id &&
//         el[i].name != "" &&
//         el[i].name.indexOf("_new_value") == -1
//       ) {
//         var trans = dom.createElement("kolonne");
//         trans.setAttribute("navn", el[i].name);
//         var t = dom.createTextNode(
//           escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//         );
//         trans.appendChild(t);
//         node1.appendChild(trans);
//       }

//       //
//       //  Tilføj ID
//       //
//       if (el[i].type == "text" && el[i].id == j_id) {
//         var trans = dom.createElement("id");
//         trans.setAttribute("navn", j_id);
//         t = dom.createTextNode(
//           escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//         );
//         trans.appendChild(t);
//         node1.appendChild(trans);
//       }
//     }
//     var el = document.forms[c_f].getElementsByTagName("select");
//     for (var i = 0; i < el.length; i++) {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", el[i].id);
//       t = dom.createTextNode(
//         escape(
//           "<![CDATA[" +
//             encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//             "]]>"
//         )
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }

//     node.appendChild(node1);
//     root.appendChild(node);
//     var c_str =
//       "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//     //
//     //  Sæt besked til efter udførelse
//     //
//     var j_message = "Rækken er nu opdateret";
//     makeRequest(
//       j_opdater_url + "?i_output_type=XML&i_opdater_xml=",
//       c_str,
//       c_f,
//       2
//     );
//     if (window.opener) {
//       window.opener.remoteSubmitSoeg(jj_raekke_fra, jj_raekke_til, jj_f);
//     }
//   } else {
//     // INSERT
//     var dom = initXmlDoc();
//     var el = document.forms[c_f].getElementsByTagName("input");
//     // var root = dom.selectSingleNode("/mastra");
//     var root = selectSingleNodeMastra(dom);
//     var node = null;
//     node = dom.createElement("insert");
//     var node1 = dom.createElement("tabel");
//     node1.setAttribute("navn", j_tabelview);

//     var el = document.forms[c_f].getElementsByTagName("input");
//     for (var i = 0; i < el.length; i++) {
//       if (
//         (el[i].type == "text") | (el[i].type == "hidden") &&
//         el[i].id != j_id &&
//         el[i].name != "" &&
//         el[i].name.indexOf("_new_value") == -1
//       ) {
//         var trans = dom.createElement("kolonne");
//         trans.setAttribute("navn", el[i].name);
//         var t = dom.createTextNode(
//           escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//         );
//         trans.appendChild(t);
//         node1.appendChild(trans);
//       }

//       //
//       //  Tilføj ID
//       //
//       if (el[i].type == "text" && el[i].id == j_id) {
//         var trans = dom.createElement("id");
//         trans.setAttribute("navn", j_id);
//         t = dom.createTextNode(
//           escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//         );
//         trans.appendChild(t);
//         node1.appendChild(trans);
//       }
//     }
//     var el = document.forms[c_f].getElementsByTagName("select");
//     for (var i = 0; i < el.length; i++) {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", el[i].id);
//       t = dom.createTextNode(
//         escape(
//           "<![CDATA[" +
//             encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//             "]]>"
//         )
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//     node.appendChild(node1);
//     root.appendChild(node);
//     var c_str =
//       "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//     //
//     //  Sæt besked til efter udførelse
//     //
//     j_message = "Rækken er nu oprettet";
//     makeRequest(
//       j_opret_url + "?i_output_type=XML&i_opret_xml=",
//       c_str,
//       c_f,
//       2
//     );
//     if (window.opener) {
//       window.opener.remoteSubmitSoeg(jj_raekke_fra, jj_raekke_til, jj_f);
//     }
//   }
//   document.getElementById("beep").src = "img/beep.wav";
//   ventOff();
// }

// exports.gemMultiValg(
//   c_f,
//   c_procedure,
//   c_par1_navn,
//   c_par1_vaerdi,
//   c_par2_navn,
//   c_par2_vaerdi
// ) {
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("eksekver");
//   var node1 = dom.createElement("procedure");
//   node1.setAttribute("navn", c_procedure);
//   //
//   //   Tilføj parametre
//   //
//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", c_par1_navn);
//   trans.setAttribute("position", "1");
//   var t = dom.createTextNode(
//     escape("<![CDATA[" + encodeURIComponent(c_par1_vaerdi) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", c_par2_navn);
//   trans.setAttribute("position", "2");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + encodeURIComponent(c_par2_vaerdi) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   makeRequest(
//     j_eksekver_url + "?i_output_type=XML&i_eksekver_xml=",
//     c_str,
//     "f1",
//     7
//   );
// }

// exports.remoteSubmitSoeg(j_raekke_fra, j_raekke_til, j_f) {
//   submitSoeg(0, j_raekke_fra, j_raekke_til, 1, j_f, 1);
// }

// exports.sletRaekke(c_f, c_id, c_raekke_fra, c_raekke_til) {
//   //
//   //     Sletter rækker i databasen
//   //
//   if (confirm("Er du sikker?")) {
//     var dom = initXmlDoc();
//     // var root = dom.selectSingleNode("/mastra");
//     var root = selectSingleNodeMastra(dom);
//     var node = null;
//     node = dom.createElement("slet");
//     var node1 = dom.createElement("tabel");
//     node1.setAttribute("navn", j_tabelview);
//     //
//     //  Tilføj ID
//     //
//     var trans = dom.createElement("id");
//     trans.setAttribute("navn", j_id);
//     var t = dom.createTextNode(
//       escape("<![CDATA[" + encodeURIComponent(c_id) + "]]>")
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//     node.appendChild(node1);
//     root.appendChild(node);
//     var c_str =
//       "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);
//     makeRequest(j_slet_url + "?i_output_type=XML&i_slet_xml=", c_str, c_f, 5);
//     submitSoeg(0, c_raekke_fra, c_raekke_til, 1, c_f, 1);
//   }
// }

// exports.getUrlParametre() {
//   var vars = [],
//     ParmVal;
//   var hashes = window.location.href
//     .slice(window.location.href.indexOf("?") + 1)
//     .split("&");
//   for (var i = 0; i < hashes.length; i++) {
//     ParmVal = hashes[i].split("=");
//     vars.push(ParmVal[0]);

//     if (ParmVal[1])
//       vars[ParmVal[0]] = ParmVal[1].replace("#0", "").replace("#", "");
//     else vars[ParmVal[0]] = ParmVal[1];
//   }
//   return vars;
// }

// exports.standardFotter() {
//   document.write('<div id="bottomContainer">');
//   document.write(
//     '<img src="../img/vdlogo.gif" alt="Vejdirektoratet" style="float: right" />'
//   );
//   document.write("</div>");
// }

// exports.standardTop(overskrift, bred) {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k") {
//     if (bred == 1)
//       document.write(
//         '<div id="topImage"><img src="/mastra/nytui/img/kmastra_bred.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//     else
//       document.write(
//         '<div id="topImage"><img src="/mastra/nytui/img/kmastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//   }

//   if (mastra_type == "n")
//     if (1 == 1)
//       document.write(
//         '<div id="topImage"><img src="../img/noegletaldb_bred.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//     else
//       document.write(
//         '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar">');
//   document.write('<div class="overskriftFelt">');
//   document.write('<div class="overskriftTekst">' + overskrift + "</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTop2(overskrift, bred) {
//   document.write('<div id="topContainer2">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra2.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k") {
//     if (bred == 1)
//       document.write(
//         '<div id="topImage"><img src="/mastra/nytui/img/kmastra_bred.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//     else
//       document.write(
//         '<div id="topImage"><img src="/mastra/nytui/img/kmastra.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//   }

//   if (mastra_type == "n")
//     if (1 == 1)
//       document.write(
//         '<div id="topImage"><img src="../img/noegletaldb_bred.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );
//     else
//       document.write(
//         '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//       );

//   document.write(
//     '<div id="topMenu2"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar2">');
//   document.write('<div class="overskriftFelt">');
//   document.write('<div class="overskriftTekst">' + overskrift + "</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopK(overskrift) {
//   document.write('<div id="topContainer">');

//   var mastra_type = "k";

//   document.write(
//     '<div id="topImage"><img src="/mastra/nytui/img/kmastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//   );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar">');
//   document.write('<div class="overskriftFelt">');
//   document.write('<div class="overskriftTekst">' + overskrift + "</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopPop(overskrift) {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra3pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar" >');
//   document.write(
//     '<div class="overskriftFelt" style="position: relative; width: 100%">'
//   );
//   document.write(
//     '<div id="overskriftTekst" class="overskriftTekst">' + overskrift
//   ); // + '</div>');
//   document.write(
//     '<input id="close" class="button" type="button" value="Luk vindue" onclick="javascript: window.close();" style="width: 75px; position: absolute; bottom: 0px; right:10px;"/>'
//   );
//   document.write("</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopPop2(overskrift) {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra2pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu2"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar2" >');
//   document.write(
//     '<div class="overskriftFelt" style="position: relative; width: 100%">'
//   );
//   document.write(
//     '<div id="overskriftTekst" class="overskriftTekst">' + overskrift
//   ); // + '</div>');
//   document.write(
//     '<input id="close" class="button" type="button" value="Luk vindue" onclick="javascript: window.close();" style="width: 75px; position: absolute; bottom: 0px; right:10px;"/>'
//   );
//   document.write("</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopPopUdenLuk() {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra3pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar" >');
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopPopUdenLuk2() {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra2pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu2"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar2" >');
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopUdenLuk(overskrift) {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra3pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra3.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar" >');
//   document.write(
//     '<div class="overskriftFelt" style="position: relative; width: 100%">'
//   );
//   document.write('<div class="overskriftTekst">' + overskrift); // + '</div>');
//   document.write("</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

// exports.standardTopUdenLuk2(overskrift) {
//   document.write('<div id="topContainer">');

//   var mastra_type = GetCookieExt("mastra_type");

//   if (mastra_type == "i")
//     document.write(
//       '<div id="topImage"><img src="../img/imastra2pop.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "k")
//     document.write(
//       '<div id="topImage"><img src="/mastra/nytui/img/kmastra.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   if (mastra_type == "n")
//     document.write(
//       '<div id="topImage"><img src="../img/noegletaldb.gif" border="0" alt="" usemap="#appTop_m_logo_Map" /><map id="appTop_m_logo_Map"><area shape="rect" alt="Til vejsektoren.dk" coords="13,0,84,12" href="http://www.vejsektoren.dk" /><area shape="rect" alt="Til forsiden" coords="13,14,122,39" href="http://www.imastra.dk" /></map></div>'
//     );

//   document.write(
//     '<div id="topMenu"><b><span id="bruger"></span></b><div><a class="helpLink" onClick="javascript: hjaelpStart();">Hjælp</a> <a  class="helpLink" title="Lukker forbindelsen til Mastra" onClick="logoff()">Log af</a> </div></div>'
//   );
//   document.write('<div class="Mmenubar" >');
//   document.write(
//     '<div class="overskriftFelt" style="position: relative; width: 100%">'
//   );
//   document.write('<div class="overskriftTekst">' + overskrift); // + '</div>');
//   document.write("</div>");
//   document.write("</div>");
//   document.write('<span id="mainMenu">');
//   document.write("</span>");
//   document.write("</div> <!-- Mmenubar -->");
//   document.write("</div><!-- topContainer -->");
// }

exports.getCookieVal = (offset) => {
  var endstr = document.cookie.indexOf(";", offset);
  if (endstr == -1) {
    endstr = document.cookie.length;
  }
  return unescape(document.cookie.substring(offset, endstr));
}

exports.GetCookie = (name) => {
  var arg = name + "=";
  var alen = arg.length;
  var clen = document.cookie.length;
  var i = 0;
  while (i < clen) {
    var j = i + alen;
    if (document.cookie.substring(i, j) == arg) {
      return exports.getCookieVal(j);
    }
    i = document.cookie.indexOf(" ", i) + 1;
    if (i == 0) break;
  }
  return null;
}

exports.SetCookie = (name, value, path, domain, secure) => {
  document.cookie =
    name +
    "=" +
    escape(value) +
    ";path=/" +
    (domain ? "; domain=" + domain : "") +
    (secure ? "; secure" : "");
}

// exports.setSelected(c_id, c_vaerdi) {
//   for (var i = 0; i < document.getElementById(c_id).length; i++) {
//     if (document.getElementById(c_id).options[i].value == c_vaerdi) {
//       document.getElementById(c_id).selectedIndex = i;
//     }
//   }
// }

// exports.submitSoegRetMD(Parametre, c_f, c_q_id) {
//   //  Sæt globale værdier
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soegret");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);

//   for (var i = 0; i < j_p_key_master.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", j_p_key_master[i]);
//     var t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(unescape(Parametre[j_p_key_master[i]])) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   var node2 = dom.createElement("ud_spec");

//   var node3 = dom.createElement("master");
//   for (var i = 0; i < j_vis_kol_master.length; i++) {
//     if (j_vis_kol_master[i] != null) {
//       var trans = dom.createElement("mkolonne");
//       trans.setAttribute("navn", j_vis_kol_master[i]);
//       node3.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("mkolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node3.appendChild(trans);

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_master.length; i++) {
//     if (j_p_key_master[i] != null) {
//       var trans = dom.createElement("mpkeykolonne");
//       trans.setAttribute("navn", j_p_key_master[i]);
//       node3.appendChild(trans);
//     }
//   }

//   var node4 = dom.createElement("link");
//   for (var i = 0; i < j_link.length; i++) {
//     if (j_link[i] != null) {
//       var trans = dom.createElement("lkolonne");
//       trans.setAttribute("navn", j_link[i]);
//       node4.appendChild(trans);
//     }
//   }

//   var node5 = dom.createElement("detail");
//   for (var i = 0; i < j_vis_kol_detail.length; i++) {
//     if (j_vis_kol_detail[i] != null) {
//       var trans = dom.createElement("dkolonne");
//       trans.setAttribute("navn", j_vis_kol_detail[i]);
//       node5.appendChild(trans);
//     }
//   }

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort_detail.length; i++) {
//     if (j_sort_detail[i] != null) {
//       var trans = dom.createElement("dsortkolonne");
//       trans.setAttribute("navn", j_sort_detail[i]);
//       trans.setAttribute("sortposition", i);
//       node5.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_detail.length; i++) {
//     if (j_p_key_detail[i] != null) {
//       var trans = dom.createElement("dpkeykolonne");
//       trans.setAttribute("navn", j_p_key_detail[i]);
//       node5.appendChild(trans);
//     }
//   }

//   node2.appendChild(node3),
//     node2.appendChild(node4),
//     node2.appendChild(node5),
//     node.appendChild(node2);
//   root.appendChild(node);

//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   makeRequest(
//     j_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

exports.retteMulighedCheck = (c_rolle) => {
  var j_eksekver_func_url = "/ords/mastra/osa.im_trans.dataeksekver_func";

  var dom = exports.initXmlDoc();
  // var root = dom.selectSingleNode("/mastra");
  var root = exports.selectSingleNodeMastra(dom);
  var node = null;
  node = dom.createElement("eksekver");
  var node1 = dom.createElement("procedure");
  node1.setAttribute("navn", "UTILS_PKG.ROLLE_CHECK");

  var trans = dom.createElement("parameter");
  trans.setAttribute("navn", "c_rolle");
  trans.setAttribute("position", "1");
  var t = dom.createTextNode(c_rolle);
  trans.appendChild(t);
  node1.appendChild(trans);
  node.appendChild(node1);
  root.appendChild(node);
  var c_str =
    "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

  result = exports.makeRequest(
    j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
    c_str,
    "f1",
    19
  );

  var resdom = exports.parseXml(result);
  var fejl = exports.getError(resdom);
  if (fejl != 0) {
    alert(
      "Der opstod en fejl ved eksekveringen, fejlnr=" +
        fejl +
        "," +
        exports.getErrorMessage(resdom)
    );
  }

  if (
    resdom.getElementsByTagName("RESULTAT")[0].childNodes[0].nodeValue == 1
  ) {
    return true;
  } else {
    return false;
  }
}

// exports.showContentsRet(c_result) {
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   var a = 0;
//   if (fejl == 0) {
//     var antal = getResultSize(resdom);
//     var RaekkeFra = getRaekkeFra(resdom);
//     var RaekkeTil = getRaekkeTil(resdom);
//     var Browser = navigator.appName;

//     //
//     //  Kolonneoverskrift
//     //
//     var result = new Array();
//     result.push(
//       '<div class="componentColumn outlined column12345 queryBox" id="BoxRoadSelect" style="width: 900px; z-index: 10"><div class="boxHeader">' +
//         j_tabel_navn +
//         '</div><div id="resultTable" class="results">'
//     );

//     result.push(
//       '<table id="resultTableRet" sstyle="width: 900px;" frame cellspacing="1" cellpadding="1"  border="0">'
//     );

//     result.push("<tr>");

//     for (var j = 0; j < j_kol_tekst.length; j++) {
//       var k = j + 1;
//       if (j_kol_tekst_width[j]) {
//         var style_text = "width: " + j_kol_tekst_width[j] + ";";
//       } else {
//         var style_text = "";
//       }

//       if (j_hide[j]) {
//         style_text = style_text + " display: none;";
//       }

//       if (j_kol_type[j] == null && j < j_sort.length) {
//         //
//         // Nedenstående laver JS-popup på overskrifterne, men den ligger sig under de andre divs !!!!
//         result.push(
//           '<th style="' +
//             style_text +
//             '" title="cssbody=[dvbdy1] cssheader=[dvhdr1] header=[' +
//             j_kol_tekst[j] +
//             "] body=[" +
//             j_alt_tekst[j] +
//             ']">' +
//             j_kol_tekst[j]
//         );

//         if (j_combo[j] == 1) {
//           result.push(
//             '<span id="' +
//               j_vis_kol[j].toLowerCase() +
//               '_indicator" style="display: none"><img src="../img/indicator_arrows.gif" alt="Vent..." />'
//           );
//         }

//         result.push("</th>");
//       } else {
//         result.push("<th>" + j_kol_tekst[j] + "</th>");
//       }
//     }

//     result.push("<td> </td>");
//     result.push("</tr>");

//     var x01 = resdom.getElementsByTagName(j_vis_kol[0]);
//     //
//     //  Data
//     //
//     var c_counter = 0;
//     for (var i = 0; i < x01.length; i++) {
//       c_counter = c_counter + 1;

//       if (!resdom.getElementsByTagName(j_p_key[0])[i].childNodes[0]) {
//         result.push(
//           '<tr class="trRetHighlight" onmouseout="normalRowS(this,!document.getElementById(' +
//             "'GEM'" +
//             ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
//             "'GEM'" +
//             ').style.display);">'
//         );
//       } else {
//         result.push(
//           '<tr class="trNormal" onmouseout="normalRowS(this,!document.getElementById(' +
//             "'GEM'" +
//             ').style.display)" onmouseover="highlightRowMD(this,!document.getElementById(' +
//             "'GEM'" +
//             ').style.display);">'
//         );
//       }

//       for (var j = 0; j < j_vis_kol.length; j++) {
//         var input_style = "";
//         if (!j_hide[j] && j_kol_tekst_width[j]) {
//           if (j_kol_tekst_width[j].indexOf("px") > 0) {
//             input_style =
//               "width: " +
//               (j_kol_tekst_width[j].replace("px", "") -
//                 parseInt(input_reduktion)) +
//               "px;";
//           }
//         }
//         if (j_kol_type[j] == null) {
//           if (j_vis_kol[j] != null) {
//             //
//             //     Fremvisning
//             //
//             var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
//               .childNodes[0];

//             if (!testnull) {
//               var val = "";
//             } else {
//               var val = resdom.getElementsByTagName(j_vis_kol[j])[i]
//                 .childNodes[0].nodeValue;
//               val = escapePling(val);
//             }

//             if (j_hide[j]) {
//               var style_text = "display: none;";
//             } else {
//               var style_text = "";
//             }

//             if (typeof j_onchange != "undefined" && j_onchange[j] != null)
//               var onChange =
//                 " onChange=\u0022" + j_onchange[j] + "(" + i + ");\u0022";
//             else var onChange = "";

//             if (j_kol_tekst_width[j]) {
//               style_text =
//                 style_text + " width:" + j_kol_tekst_width[j] + ";";
//             }
//             result.push(
//               '<td title="' +
//                 val +
//                 '" style="' +
//                 style_text +
//                 '" class="left">' +
//                 "<div><input " +
//                 onChange +
//                 ' type="text" style="' +
//                 input_style +
//                 '" id="' +
//                 j_vis_kol[j] +
//                 '" name="' +
//                 j_vis_kol[j] +
//                 '" readonly  value="' +
//                 val +
//                 '"/>'
//             );

//             if (j_combo[j] == 1) {
//               if (j_combo_width[j]) {
//                 var c_style =
//                   'style="z-index: 2; width: ' + j_combo_width[j] + ';"';
//               } else {
//                 var c_style = 'style="z-index: 2"';
//               }
//               result.push(
//                 '<div id="' +
//                   j_vis_kol[j] +
//                   '_CHOICES" class="autocomplete" ' +
//                   c_style +
//                   "></div>"
//               );
//             }
//             result.push("</div></td>");
//           }
//         } else {
//           if (j_kol_type[j] == "CHECKBOX") {
//             //
//             //     CHECKBOX
//             //
//             if (
//               !resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0] ||
//               resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//                 .nodeValue == 0
//             ) {
//               result.push(
//                 "<td class=\u0022left\u0022>" +
//                   "<input value='0' disabled type=\u0022CHECKBOX\u0022 id='" +
//                   j_vis_kol[j] +
//                   "' " +
//                   " style=\u0022" +
//                   input_style +
//                   "\u0022" +
//                   ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" unchecked />' +
//                   "</td>"
//               );
//             } else {
//               result.push(
//                 "<td class=\u0022left\u0022>" +
//                   "<input value='1' disabled type=\u0022CHECKBOX\u0022 id='" +
//                   j_vis_kol[j] +
//                   "' " +
//                   " style=\u0022" +
//                   input_style +
//                   "\u0022" +
//                   ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" checked/>' +
//                   "</td>"
//               );
//             }
//           }
//           if (j_kol_type[j] == "LOV_FAST") {
//             if (resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]) {
//               var selected_value = resdom.getElementsByTagName(j_vis_kol[j])[
//                 i
//               ].childNodes[0].nodeValue;
//             } else {
//               var selected_value = "";
//             }

//             result.push(
//               "<td>" +
//                 j_html[j].replace(
//                   'value="' + selected_value + '"',
//                   'value="' + selected_value + '" SELECTED '
//                 ) +
//                 "</td>"
//             );
//           }

//           if (j_kol_type[j] == "LINK") {
//             //
//             //     LINK
//             //
//             if (j_hide[j]) {
//               style_text = style_text + " display: none;";
//             } else {
//               style_text = "";
//             }

//             if (j_kol_tekst_width[j]) {
//               style_text = style_text + " width:" + j_kol_tekst_width[j];
//             }

//             if (typeof link_gif != "undefined") {
//               var gif_file = link_gif(j, resdom);
//             } else {
//               var gif_file = j_id_gif[j];
//             }

//             result.push(
//               '<td align=\u0022center\u0022 style="' +
//                 style_text +
//                 '" name="' +
//                 j_vis_kol[j] +
//                 '" id="' +
//                 j_vis_kol[j] +
//                 '"' +
//                 ">" +
//                 "&nbsp;" +
//                 "<a href=\u0022javascript:" +
//                 j_id_funk[j] +
//                 "('M','" +
//                 j_f +
//                 "',["
//             );

//             var fundet = false;
//             for (var jj = 0; jj < j_p_key.length; jj++) {
//               var detiddom = resdom.getElementsByTagName(j_p_key[jj]);
//               if (detiddom[i].childNodes[0]) {
//                 result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
//                 fundet = true;
//               }
//             }

//             if (!fundet) result.push("[");

//             result_str = result.pop();
//             result.push(
//               result_str.substr(0, result_str.length - 1) +
//                 "]," +
//                 "0" +
//                 "," +
//                 "0" +
//                 ");\u0022>" +
//                 "<img src=\u0022" +
//                 gif_file +
//                 "\u0022 border=\u00220\u0022 title=\u0022" +
//                 j_alt_tekst[j] +
//                 "\u0022>" +
//                 "</a>" +
//                 "</td>"
//             );
//           }

//           if (j_kol_type[j] == "LOV") {
//             if (j_kol_tekst_width[j]) {
//               var width_text = " width: " + j_kol_tekst_width[j];
//             } else {
//               var width_text = "";
//             }
//             if (!resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]) {
//               result.push(
//                 '<td><div id="' +
//                   j_vis_kol[j] +
//                   '" style="position:relative; ' +
//                   width_text +
//                   '">' +
//                   "</div></td>"
//               );
//             } else {
//               result.push(
//                 '<td><div id="' +
//                   j_vis_kol[j] +
//                   '" style="position:relative; ' +
//                   width_text +
//                   '">' +
//                   resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//                     .nodeValue +
//                   "</div></td>"
//               );
//             }
//           }

//           if (j_kol_type[j] == "SPECIAL") {
//             //
//             //     SPECIAL
//             //

//             var z = j_id_funk[j] + "(" + i + ",resdom)";

//             var zz = eval(z);

//             result.push("<td>" + zz + "</td>");
//           }
//         }
//       }

//       result.push('<td width="65px"><div style="margin: 0px 0px 0px 2px">');

//       if (!retteMulighedM(resdom)) {
//         result.push(
//           '<a name="RET" id="RET" > <img  src="' + blankIcon + '"/></a>'
//         );
//         result.push('<a name="SLET" id="SLET" ');

//         result.push('"> <img src="' + blankIcon + '"/></a>');

//         result.push('<a style="display: none;"name="GEM" id="GEM" ');

//         result.push('"> <img  src="' + blankIcon + '"/></a>');
//         result.push(
//           '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
//             fortrydIcon +
//             '"/></a></div></td>'
//         );
//       } else {
//         result.push(
//           '<a style="display: none;"name="GEM" id="GEM" title="Gem" href="javascript: '
//         );

//         result.push("GemM([ ");
//         for (var jj = 0; jj < j_p_key.length; jj++) {
//           var detiddom = resdom.getElementsByTagName(j_p_key[jj]);
//           if (detiddom[i].childNodes[0]) {
//             result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
//           } else {
//             result.push(" ");
//           }
//         }
//         result_str = result.pop();
//         result.push(result_str.substr(0, result_str.length - 1) + "]);");

//         result.push('"> <img src="' + gemIcon + '"/></a>');

//         result.push(
//           '<a name="RET" id="RET" title="Ret" href="javascript: RetM();"> <img src="' +
//             retIcon +
//             '"/></a>'
//         );

//         result.push(
//           '<a style="display: none" name="SLET" id="SLET" title="Slet" href="javascript: '
//         );

//         result.push("SletM([ ");
//         for (var jj = 0; jj < j_p_key.length; jj++) {
//           var detiddom = resdom.getElementsByTagName(j_p_key[jj]);
//           if (detiddom[i].childNodes[0]) {
//             result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
//           } else {
//             result.push(" ");
//           }
//         }
//         result_str = result.pop();
//         result.push(result_str.substr(0, result_str.length - 1) + "]);");

//         result.push('"> <img src="' + sletIcon + '"/></a>');

//         result.push(
//           '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
//             fortrydIcon +
//             '"/></a></div></td>'
//         );
//       }

//       result.push("</div></td>");

//       result.push("</tr>");
//     }
//     result.push("</table></div>");

//     result.push("</div>");
//   } else {
//     var c_resstr = exports.serializeDom(resdom);
//     alert("Modtaet XML " + c_resstr);
//     var result = new Array();
//     result.push("<div>");
//     result.push(
//       "SQL:" +
//         resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
//         "<br><br>"
//     );
//     result.push(
//       "FEJL:" +
//         resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
//         "<br><br>"
//     );
//     result.push("</div>");
//   }

//   document.getElementById(j_span).innerHTML = result.join("");

//   if (navigator.appName != "Netscape") {
//     if (document.getElementById("resultTable").scrollWidth > 900) {
//       document.getElementById("resultTable").style.height =
//         document.getElementById("resultTable").offsetHeight +
//         parseInt(16) +
//         "px";
//     }
//   }

//   autocompleterMaster();

//   lovSetupMaster();
// }

// exports.showContentsRetForm(c_result) {
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   var a = 0;
//   if (fejl == 0) {
//     var antal = getResultSize(resdom);
//     var RaekkeFra = getRaekkeFra(resdom);
//     var RaekkeTil = getRaekkeTil(resdom);
//     var Browser = navigator.appName;

//     var i = 0;

//     var result = new Array();
//     result.push(
//       '<div class="componentColumn outlined column12345 queryBox" id="BoxRoadSelect" style="width: 900px; z-index: 10"><div class="boxHeader">' +
//         j_tabel_navn +
//         '</div><div id="resultTable" class="results">'
//     );

//     result.push("<div style='float:right'>");
//     if (!retteMulighedM(resdom)) {
//       result.push(
//         '<a name="RET" id="RET" > <img  src="' + blankIcon + '"/></a>'
//       );
//       result.push('<a name="SLET" id="SLET" ');

//       result.push('"> <img src="' + blankIcon + '"/></a>');

//       result.push('<a style="display: none;"name="GEM" id="GEM" ');

//       result.push('"> <img  src="' + blankIcon + '"/></a>');
//       result.push(
//         '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
//           fortrydIcon +
//           '"/></a></div></td>'
//       );
//     } else {
//       result.push(
//         '<a style="display: none;"name="GEM" id="GEM" title="Gem" href="javascript: '
//       );

//       result.push("GemM([ ");
//       for (var jj = 0; jj < j_p_key.length; jj++) {
//         var detiddom = resdom.getElementsByTagName(j_p_key[jj]);
//         if (detiddom[i].childNodes[0]) {
//           result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
//         } else {
//           result.push(" ");
//         }
//       }

//       result_str = result.pop();
//       result.push(result_str.substr(0, result_str.length - 1) + "]);");

//       result.push('"> <img src="' + gemIcon + '"/></a>');

//       result.push(
//         '<a name="RET" id="RET" title="Ret" href="javascript: RetM();"> <img src="' +
//           retIcon +
//           '"/></a>'
//       );

//       result.push(
//         '<a style="display: none" name="SLET" id="SLET" title="Slet" href="javascript: '
//       );

//       result.push("SletM([ ");
//       for (var jj = 0; jj < j_p_key.length; jj++) {
//         var detiddom = resdom.getElementsByTagName(j_p_key[jj]);
//         if (detiddom[i].childNodes[0]) {
//           result.push("'" + detiddom[i].childNodes[0].nodeValue + "',");
//         } else {
//           result.push(" ");
//         }
//       }

//       result_str = result.pop();
//       result.push(result_str.substr(0, result_str.length - 1) + "]);");

//       result.push('"> <img src="' + sletIcon + '"/></a>');

//       result.push(
//         '<a style="display: none;"name="FORTRYD" id="FORTRYD" title="Fortryd" href="javascript: FortrydM();"> <img src="' +
//           fortrydIcon +
//           '"/></a></div></td>'
//       );
//     }

//     result.push("</div>");

//     var j_block = Math.floor(j_kol_tekst.length / 2);

//     result.push('<div style="float: left;">');
//     result.push(
//       '<table id="resultTableRett" sstyle="width: 900px;" frame cellspacing="1" cellpadding="1"  border="0">'
//     );

//     for (var j = 0; j < j_block; j++) {
//       var style_text = "";
//       if (j_hide[j]) {
//         style_text = style_text + " display: none;";
//       }

//       var input_style = "";
//       if (!j_hide[j] && j_kol_tekst_width[j]) {
//         if (j_kol_tekst_width[j].indexOf("px") > 0) {
//           input_style =
//             "width: " +
//             (j_kol_tekst_width[j].replace("px", "") -
//               parseInt(input_reduktion)) +
//             "px;";
//         }
//       }

//       if (j_kol_type[j] == null) {
//         var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
//           .childNodes[0];

//         if (!testnull) {
//           var val = "";
//         } else {
//           var val = resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//             .nodeValue;
//           val = escapePling(val);
//         }

//         result.push(
//           "<tr style='" +
//             style_text +
//             "'><td style='width: 20%;'>" +
//             j_kol_tekst[j] +
//             "</td><td><input style='" +
//             input_style +
//             "' readonly class='input_class' id='" +
//             j_vis_kol[j] +
//             "' name='" +
//             j_vis_kol[j] +
//             "' value='" +
//             val +
//             "'  />"
//         );

//         if (j_combo[j] == 1) {
//           if (j_combo_width[j]) {
//             var c_style =
//               'style="z-index: 2; width: ' + j_combo_width[j] + ';"';
//           } else {
//             var c_style = 'style="z-index: 2"';
//           }
//           result.push(
//             '<div id="' +
//               j_vis_kol[j] +
//               '_CHOICES" class="autocomplete" ' +
//               c_style +
//               "></div>"
//           );
//         }

//         if (j_combo[j] == 1) {
//           result.push(
//             '<span id="' +
//               j_vis_kol[j].toLowerCase() +
//               '_indicator" style="display: none"><img src="../img/indicator_arrows.gif" alt="Vent..." />'
//           );
//         }

//         result.push("</td></tr>");
//       }

//       if (j_kol_type[j] == "CHECKBOX") {
//         //
//         //     CHECKBOX
//         //
//         result.push(
//           "<tr><td style='width: 20%;'>" + j_kol_tekst[j] + "</td>"
//         );
//         if (
//           !resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0] ||
//           resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//             .nodeValue == 0
//         ) {
//           result.push(
//             "<td class=\u0022left\u0022>" +
//               "<input value='0' disabled type=\u0022CHECKBOX\u0022 id='" +
//               j_vis_kol[j] +
//               "' " +
//               " style=\u0022" +
//               input_style +
//               "\u0022" +
//               ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" unchecked />' +
//               "</td>"
//           );
//         } else {
//           result.push(
//             "<td class=\u0022left\u0022>" +
//               "<input value='1' disabled type=\u0022CHECKBOX\u0022 id='" +
//               j_vis_kol[j] +
//               "' " +
//               " style=\u0022" +
//               input_style +
//               "\u0022" +
//               ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" checked/>' +
//               "</td>"
//           );
//         }
//         result.push("</tr>");
//       }
//     }

//     result.push("</table>");
//     result.push("</div>");

//     result.push('<div style="float: left; margin-left: 150px">');
//     result.push(
//       '<table id="resultTableRett" sstyle="width: 900px;" frame cellspacing="1" cellpadding="1"  border="0">'
//     );

//     for (var j = j_block; j < j_kol_tekst.length; j++) {
//       var input_style = "";
//       if (!j_hide[j] && j_kol_tekst_width[j]) {
//         if (j_kol_tekst_width[j].indexOf("px") > 0) {
//           input_style =
//             "width: " +
//             (j_kol_tekst_width[j].replace("px", "") -
//               parseInt(input_reduktion)) +
//             "px;";
//         }
//       }

//       var style_text = "";
//       if (j_hide[j]) {
//         style_text = style_text + " display: none;";
//       }

//       if (j_kol_type[j] == null) {
//         var testnull = resdom.getElementsByTagName(j_vis_kol[j])[i]
//           .childNodes[0];

//         if (!testnull) {
//           var val = "";
//         } else {
//           var val = resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//             .nodeValue;
//           val = escapePling(val);
//         }

//         result.push(
//           "<tr style='" +
//             style_text +
//             "'><td style='width: 30%;'>" +
//             j_kol_tekst[j] +
//             "</td><td><input style='" +
//             input_style +
//             "' class='input_class' id='" +
//             j_vis_kol[j] +
//             "'  name='" +
//             j_vis_kol[j] +
//             "' value='" +
//             val +
//             "'  /></td></tr>"
//         );
//       }

//       if (j_kol_type[j] == "CHECKBOX") {
//         //
//         //     CHECKBOX
//         //
//         result.push(
//           "<tr><td style='width: 20%;'>" + j_kol_tekst[j] + "</td>"
//         );
//         if (
//           !resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0] ||
//           resdom.getElementsByTagName(j_vis_kol[j])[i].childNodes[0]
//             .nodeValue == 0
//         ) {
//           result.push(
//             "<td class=\u0022left\u0022>" +
//               "<input value='0' disabled type=\u0022CHECKBOX\u0022 id='" +
//               j_vis_kol[j] +
//               "' " +
//               " style=\u0022" +
//               input_style +
//               "\u0022" +
//               ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" unchecked />' +
//               "</td>"
//           );
//         } else {
//           result.push(
//             "<td class=\u0022left\u0022>" +
//               "<input value='1' disabled type=\u0022CHECKBOX\u0022 id='" +
//               j_vis_kol[j] +
//               "' " +
//               " style=\u0022" +
//               input_style +
//               "\u0022" +
//               ' onClick="javascript:if (this.value==0) {this.value=1;}else{this.value=0;};" checked/>' +
//               "</td>"
//           );
//         }
//         result.push("</tr>");
//       }
//     }

//     result.push("</table></div>");
//     result.push("</div>");
//   } else {
//     var c_resstr = exports.serializeDom(resdom);
//     alert("Modtaet XML " + c_resstr);
//     var result = new Array();
//     result.push("<div>");
//     result.push(
//       "SQL:" +
//         resdom.getElementsByTagName("SQL")[0].childNodes[0].nodeValue +
//         "<br><br>"
//     );
//     result.push(
//       "FEJL:" +
//         resdom.getElementsByTagName("FEJL")[0].childNodes[0].nodeValue +
//         "<br><br>"
//     );
//     result.push("</div>");
//   }

//   document.getElementById(j_span).innerHTML = result.join("");

//   if (navigator.appName != "Netscape") {
//     if (document.getElementById("resultTable").scrollWidth > 900) {
//       document.getElementById("resultTable").style.height =
//         document.getElementById("resultTable").offsetHeight +
//         parseInt(16) +
//         "px";
//     }
//   }

//   autocompleterMaster();

//   lovSetupMaster();
// }

// exports.submitSoegRet(Parametre, c_f, c_q_id) {
//   //  Sæt globale værdier
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);

//   for (var i = 0; i < j_p_key.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", j_p_key[i]);
//     var t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(unescape(Parametre[j_p_key[i]])) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation

//   var node3 = dom.createElement("ud_spec");
//   for (var i = 0; i < j_vis_kol.length; i++) {
//     if (j_vis_kol[i] != null) {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", j_vis_kol[i]);
//       node3.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("kolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node3.appendChild(trans);

//   //      Nøglekolonner

//   node.appendChild(node3);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   makeRequest(
//     j_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitSoegRetForm(Parametre, c_f, c_q_id) {
//   //  Sæt globale værdier
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);

//   for (var i = 0; i < j_p_key.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", j_p_key[i]);
//     var t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(unescape(Parametre[j_p_key[i]])) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation

//   var node3 = dom.createElement("ud_spec");
//   for (var i = 0; i < j_vis_kol.length; i++) {
//     if (j_vis_kol[i] != null) {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", j_vis_kol[i]);
//       node3.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("kolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node3.appendChild(trans);

//   node.appendChild(node3);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   makeRequest(
//     j_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.opdaterContents(c_result, row) {
//   //
//   //  Checker at sletningen er gået godt
//   //

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved ændring: " + exports.getErrorMessage(resdom));
//   } else {
//     for (var i = 0; i < j_vis_kol.length; i++) {
//       if (visFelt(j_vis_kol[i])) {
//         if (j_kol_type[i] == "CHECKBOX") {
//           document.getElementById(j_vis_kol[i] + row).disabled = true;
//         }
//         if (j_kol_type[i] == "LOV_FAST") {
//           document.getElementById(j_vis_kol[i] + row).disabled = true;
//         }
//         if (j_kol_type[i] == "SPECIAL") {
//           document.getElementById(j_vis_kol[i] + row).disabled = true;
//         }
//         if (!j_kol_type[i]) {
//           document.getElementById(j_vis_kol[i] + row).readOnly = true;
//         }
//         document.getElementById(j_vis_kol[i] + row).style.background =
//           "transparent";
//       }
//     }

//     document.getElementById("RET" + row).style.display = "";
//     document.getElementById("GEM" + row).style.display = "none";
//     document.getElementById("SLET" + row).style.display = "none";
//     document.getElementById("FORTRYD" + row).style.display = "none";

//     for (var i = 0; i < j_count; i++) {
//       document.getElementById("RET" + i).style.display = "";
//     }
//   }
// }

// exports.opdaterContentsM(c_result, row) {
//   //
//   //  Checker at sletningen er gået godt
//   //

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved ændring: " + exports.getErrorMessage(resdom));
//   } else {
//     for (var i = 0; i < j_vis_kol_master.length; i++) {
//       if (visFelt(j_vis_kol_master[i])) {
//         if (j_kol_type_master[i] == "CHECKBOX") {
//           document.getElementById(j_vis_kol_master[i] + row).disabled = true;
//         }
//         if (j_kol_type_master[i] == "LOV_FAST") {
//           document.getElementById(j_vis_kol_master[i] + row).disabled = true;
//         }
//         if (!j_kol_type_master[i]) {
//           document.getElementById(j_vis_kol_master[i] + row).readOnly = true;
//         }
//         document.getElementById(j_vis_kol_master[i] + row).style.background =
//           "transparent";
//       }
//     }

//     document.getElementById("RET" + row).style.display = "";
//     document.getElementById("GEM" + row).style.display = "none";
//     document.getElementById("SLET" + row).style.display = "none";
//     document.getElementById("FORTRYD" + row).style.display = "none";

//     for (var i = 0; i < j_count; i++) {
//       document.getElementById("RET" + i).style.display = "";
//     }
//   }
// }

// exports.opretContents(c_result) {
//   //
//   //  Checker at opret er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved oprettelse: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key.length; j++) {
//       vars.push(j_p_key[j]);
//       vars[j_p_key[j]] = resdom.getElementsByTagName(
//         j_p_key[j]
//       )[0].childNodes[0].nodeValue;
//     }
//     initSideSpinner(vars);
//   }
// }

// exports.sletContentsM(c_result) {
//   //
//   //  Checker at sletningen er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved sletningen: " + exports.getErrorMessage(resdom));
//   } else {
//     var vars = [];
//     for (var j = 0; j < j_p_key.length; j++) {
//       vars.push(j_p_key[j]);
//       vars[j_p_key[j]] = "";
//     }
//     initSide(vars);
//   }
// }

// exports.sletContentsMM(c_result) {
//   //
//   //  Checker at sletningen er gået godt
//   //
//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl != 0) {
//     alert("Der opstod en fejl ved sletningen: " + exports.getErrorMessage(resdom));
//   }
// }

// exports.visAntal() {
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("eksekver");
//   var node1 = dom.createElement("procedure");
//   node1.setAttribute("navn", "UTILS_PKG.IMASTRA_VIS_ANTAL");

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", "I_DUMMY");
//   trans.setAttribute("position", "1");
//   var t = dom.createTextNode("0");
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   c_result = makeRequest(
//     j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
//     c_str,
//     "f1",
//     19
//   );

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);

//   if (fejl == 0) {
//     c_antal = resdom.getElementsByTagName("VIS_ANTAL")[0].childNodes[0]
//       .nodeValue;

//     if (c_antal > 0) {
//       if (typeof j_vis_raekker_fast != "undefined") {
//         j_vis_raekker = j_vis_raekker_fast;
//         j_raekke_til = j_vis_raekker_fast;
//       } else {
//         j_vis_raekker = parseInt(c_antal);
//         j_raekke_til = parseInt(c_antal);
//       }
//     }
//   }
// }

// exports.hentDefaultVaerdier() {
//   var resdom = hentDefaultXML();

//   var c_modulnavn = resdom.getElementsByTagName("MODUL")[0].childNodes[0]
//     .nodeValue;

//   if (typeof document.forms["f1"] != "undefined") {
//     var el = document.forms["f1"].getElementsByTagName("input");
//     for (var i = 0; i < el.length; i++) {
//       for (
//         var j = 0;
//         j < resdom.getElementsByTagName("DEFAULT").length;
//         j++
//       ) {
//         if (
//           resdom
//             .getElementsByTagName("DEFAULT")
//             [j].attributes.getNamedItem("ID").value == el[i].id
//         ) {
//           el[i].value = resdom.getElementsByTagName("DEFAULT")[
//             j
//           ].childNodes[0].nodeValue;
//         }
//       }
//     }

//     var el = document.forms["f1"].getElementsByTagName("select");
//     for (var i = 0; i < el.length; i++) {
//       for (
//         var j = 0;
//         j < resdom.getElementsByTagName("DEFAULT").length;
//         j++
//       ) {
//         if (
//           resdom
//             .getElementsByTagName("DEFAULT")
//             [j].attributes.getNamedItem("ID").value == el[i].id
//         ) {
//           var k1 = -1;
//           for (var k = 0; k < el[i].options.length; k++) {
//             if (
//               el[i].options[k].value ==
//               resdom.getElementsByTagName("DEFAULT")[j].childNodes[0]
//                 .nodeValue
//             ) {
//               k1 = k;
//             }
//           }
//           if (k1 != -1) {
//             el[i].selectedIndex = k1;
//           }
//         }
//       }
//     }
//   }
// }

// exports.hentDefaultXML() {
//   var j_eksekver_func_url = "/ords/mastra/osa.im_trans.dataeksekver_func";

//   var c_path = document.location.pathname;
//   var c_pos = c_path.indexOf("/", c_path.indexOf("nytui"));
//   var c_url = ".." + c_path.substring(c_pos);

//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("eksekver");
//   var node1 = dom.createElement("procedure");
//   node1.setAttribute("navn", "SYSTEM_PKG.HENT_DEFAULT");

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", "I_URL");
//   trans.setAttribute("position", "1");
//   var t = dom.createTextNode(c_url);
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   c_result = makeRequest(
//     j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
//     c_str,
//     "f1",
//     19
//   );

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl > 0) {
//     alert(getErrorMessage(resdom));
//   } else {
//     return resdom;
//   }
// }

// exports.retDefaultVaerdier(c_modulnavn) {
//   var url = j_ret_default_url + "?MODUL=" + c_modulnavn;
//   var retvindue = window.open(
//     url,
//     "defvindue",
//     "status=1,menubar=1,toolbar=1,resizable=1,scrollbars=1,height=500,width=1000"
//   );
// }

// exports.menuLog(c_menupunkt) {
//   j_eksekver_func_url = "/ords/mastra/osa.im_trans.dataeksekver_func";
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("eksekver");
//   var node1 = dom.createElement("procedure");
//   node1.setAttribute("navn", "UTILS_PKG.MENU_LOG");

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", "I_MENUPUNKT");
//   trans.setAttribute("position", "1");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + encodeURIComponent(c_menupunkt) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", "I_MASTRA");
//   trans.setAttribute("position", "2");
//   t = dom.createTextNode("i");
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   var c_result = makeRequest(
//     j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
//     c_str,
//     "f1",
//     19
//   );

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl > 0) {
//     alert(getErrorMessage(resdom));
//   }
// }

exports.gemBrugerInfo = () => {
  var j_eksekver_func_url = "/ords/mastra/osa.im_trans.dataeksekver_func";
  var dom = exports.initXmlDoc();
  // var root = dom.selectSingleNode("/mastra");
  var root = exports.selectSingleNodeMastra(dom);
  var node = null;
  node = dom.createElement("eksekver");
  var node1 = dom.createElement("procedure");
  node1.setAttribute("navn", "UTILS_PKG.INFO_LOG");

  //alert('Navn:' +navigator.appName + ', Version:' + navigator.appVersion);

  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) var OSName = "Windows";

  if (navigator.appVersion.indexOf("Mac") != -1) var OSName = "MacOS";

  if (navigator.appVersion.indexOf("X11") != -1) var OSName = "UNIX";

  if (navigator.appVersion.indexOf("Linux") != -1) var OSName = "Linux";

  var trans = dom.createElement("parameter");
  trans.setAttribute("navn", "I_STYRESYSTEM");
  trans.setAttribute("position", "1");

  var t = dom.createTextNode(OSName);
  trans.appendChild(t);
  node1.appendChild(trans);

  var trans = dom.createElement("parameter");
  trans.setAttribute("navn", "I_SKAERM");
  trans.setAttribute("position", "2");
  t = dom.createTextNode(screen.width + "x" + screen.height);
  trans.appendChild(t);
  node1.appendChild(trans);

  var trans = dom.createElement("parameter");
  trans.setAttribute("navn", "I_BROWSER");
  trans.setAttribute("position", "3");
  t = dom.createTextNode(
    navigator.appName + " v." + navigator.appVersion.substr(0, 25)
  );
  trans.appendChild(t);
  node1.appendChild(trans);

  node.appendChild(node1);
  root.appendChild(node);
  var c_str = "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

  var c_result = exports.makeRequest(
    j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
    c_str,
    "f1",
    19
  );

  var resdom = exports.parseXml(c_result);
  var fejl = exports.getError(resdom);
  if (fejl > 0) {
    alert(exports.getErrorMessage(resdom));
  }
};

// exports.hentPopPosition() {
//   var c_text = "";

//   if (typeof window.screenLeft == "number") {
//     c_text =
//       ", left=" + (parseInt(window.screenLeft) + parseInt(j_x_popup_offset));
//   } else {
//     c_text =
//       c_text +
//       ", screenx=" +
//       (parseInt(window.screenX) + parseInt(j_x_popup_offset));
//   }

//   if (typeof window.screenTop == "number") {
//     c_text =
//       c_text +
//       ", top=" +
//       (parseInt(window.screenTop) + parseInt(j_y_popup_offset));
//   } else {
//     c_text =
//       c_text +
//       ", screenY=" +
//       (parseInt(window.screenY) + parseInt(j_y_popup_offset));
//   }

//   return c_text;
// }

// exports.focusPop(pop) {
//   if (window.focus) {
//     pop.focus();
//   }
// }

// exports.visSnitInfo(i_snit_id) {
//   var snitvindue = window.open(
//     "../utils/SnitInfo.html?i_snit_id=" + i_snit_id,
//     "retvindue",
//     "status=1,menubar=1,toolbar=1,resizable=1,scrollbars=1,height=200,width=1000"
//   );

//   if (window.focus) {
//     snitvindue.focus();
//   }
// }

// exports.submitSoegExcelGo(
//   i_raekker_ialt,
//   i_raekke_fra,
//   i_raekke_til,
//   i_start_soeg,
//   c_f,
//   c_q_id
// ) {
//   //  Sæt globale værdier
//   j_reakker_ialt = i_raekker_ialt;
//   j_raekke_fra = i_raekke_fra;
//   j_raekke_til = i_raekke_til;
//   j_f = c_f;
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);
//   //         Søgekriterier
//   var el = document.forms[c_f].getElementsByTagName("input");
//   for (var i = 0; i < el.length; i++) {
//     //  Forbered checkboxe
//     if (el[i].type == "checkbox") {
//       if (el[i].checked) {
//         el[i].value = 1;
//       } else {
//         el[i].value = "";
//       }
//     }
//     if (
//       (el[i].type == "text") |
//         (el[i].type == "hidden") |
//         (el[i].type == "checkbox") &&
//       el[i].name != "" &&
//       el[i].name.indexOf("_new_value") == -1 &&
//       el[i].value != ""
//     ) {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].name);
//       var t = dom.createTextNode(
//         escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //        List-of-values
//   var el = document.forms[c_f].getElementsByTagName("select");
//   for (var i = 0; i < el.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", el[i].id);
//     t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }
//   //       Parametre
//   var trans = dom.createElement("raekke_fra");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_fra + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekke_til");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_til + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekker_ialt");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekker_ialt + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("start_soeg");
//   t = dom.createTextNode(escape("<![CDATA[" + i_start_soeg + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   node2 = dom.createElement("ud_spec");

//   for (var i = 0; i < j_vis_kol.length; i++) {
//     if (j_vis_kol[i] != null && visFelt(j_vis_kol[i])) {
//       var trans = dom.createElement("kolonne");
//       trans.setAttribute("navn", j_vis_kol[i]);
//       if (j_kol_vaerdier[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node2.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("kolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node2.appendChild(trans);

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort.length; i++) {
//     if (j_sort[i] != null) {
//       var trans = dom.createElement("sortkolonne");
//       trans.setAttribute("navn", j_sort[i]);
//       trans.setAttribute("sortposition", i);
//       node2.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key.length; i++) {
//     if (j_p_key[i] != null) {
//       var trans = dom.createElement("pkeykolonne");
//       trans.setAttribute("navn", j_p_key[i]);
//       node2.appendChild(trans);
//     }
//   }

//   node.appendChild(node2);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   var j_excel_url =
//     "/ords/mastra/osa.im_trans.datatrans_excel_ny?i_timestamp=";

//   makeRequest(
//     j_excel_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitSoegExcelMDGo(
//   i_raekker_ialt,
//   i_raekke_fra,
//   i_raekke_til,
//   i_start_soeg,
//   c_f,
//   c_q_id
// ) {
//   ventOn();
//   //  Sæt globale værdier
//   j_reakker_ialt = i_raekker_ialt;
//   j_raekke_fra = i_raekke_fra;
//   j_raekke_til = i_raekke_til;
//   j_f = c_f;
//   //
//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("soeg");
//   var node1 = dom.createElement("tabel");
//   node1.setAttribute("navn", j_tabelview);
//   //         Søgekriterier
//   var el = document.forms[c_f].getElementsByTagName("input");
//   for (var i = 0; i < el.length; i++) {
//     //  Forbered checkboxe
//     if (el[i].type == "checkbox") {
//       if (el[i].checked) {
//         el[i].value = 1;
//       } else {
//         el[i].value = "";
//       }
//     }
//     if (
//       (el[i].type == "text") |
//         (el[i].type == "hidden") |
//         (el[i].type == "checkbox") &&
//       el[i].name != "" &&
//       el[i].name.indexOf("_new_value") == -1 &&
//       el[i].value != ""
//     ) {
//       var trans = dom.createElement("felt");
//       trans.setAttribute("navn", el[i].name);
//       var t = dom.createTextNode(
//         escape("<![CDATA[" + encodeURIComponent(el[i].value) + "]]>")
//       );
//       trans.appendChild(t);
//       node1.appendChild(trans);
//     }
//   }
//   //        List-of-values
//   var el = document.forms[c_f].getElementsByTagName("select");
//   for (var i = 0; i < el.length; i++) {
//     var trans = dom.createElement("felt");
//     trans.setAttribute("navn", el[i].id);
//     t = dom.createTextNode(
//       escape(
//         "<![CDATA[" +
//           encodeURIComponent(el[i].options[el[i].selectedIndex].value) +
//           "]]>"
//       )
//     );
//     trans.appendChild(t);
//     node1.appendChild(trans);
//   }
//   //       Parametre
//   var trans = dom.createElement("raekke_fra");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_fra + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekke_til");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekke_til + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("raekker_ialt");
//   t = dom.createTextNode(escape("<![CDATA[" + i_raekker_ialt + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("start_soeg");
//   t = dom.createTextNode(escape("<![CDATA[" + i_start_soeg + "]]>"));
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   var trans = dom.createElement("session_id");
//   t = dom.createTextNode(
//     escape("<![CDATA[" + parseInt(GetCookie("session_id")) + "]]>")
//   );
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   //      Ud-kolonne-specifikation
//   var node2 = dom.createElement("ud_spec");

//   var node3 = dom.createElement("master");
//   for (var i = 0; i < j_vis_kol_master.length; i++) {
//     if (j_vis_kol_master[i] != null) {
//       var trans = dom.createElement("mkolonne");
//       trans.setAttribute("navn", j_vis_kol_master[i]);
//       if (j_kol_vaerdier_master[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier_master[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node3.appendChild(trans);
//     }
//   }
//   //      Tilføj id til ud-specifikation
//   var trans = dom.createElement("mkolonne");
//   trans.setAttribute("navn", "ID");
//   t = dom.createTextNode(escape("<![CDATA[" + j_id + "]]>"));
//   trans.appendChild(t);
//   node3.appendChild(trans);

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort_master.length; i++) {
//     if (j_sort_master[i] != null) {
//       var trans = dom.createElement("msortkolonne");
//       trans.setAttribute("navn", j_sort_master[i]);
//       trans.setAttribute("sortposition", i);
//       node3.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_master.length; i++) {
//     if (j_p_key_master[i] != null) {
//       var trans = dom.createElement("mpkeykolonne");
//       trans.setAttribute("navn", j_p_key_master[i]);
//       node3.appendChild(trans);
//     }
//   }

//   var node4 = dom.createElement("link");
//   for (var i = 0; i < j_link.length; i++) {
//     if (j_link[i] != null) {
//       var trans = dom.createElement("lkolonne");
//       trans.setAttribute("navn", j_link[i]);
//       node4.appendChild(trans);
//     }
//   }

//   var node5 = dom.createElement("detail");
//   for (var i = 0; i < j_vis_kol_detail.length; i++) {
//     if (j_vis_kol_detail[i] != null) {
//       var trans = dom.createElement("dkolonne");
//       trans.setAttribute("navn", j_vis_kol_detail[i]);
//       if (j_kol_vaerdier_detail[i] != null) {
//         t = dom.createTextNode(
//           escape("<![CDATA[" + j_kol_vaerdier_detail[i] + "]]>")
//         );
//         trans.appendChild(t);
//       }
//       node5.appendChild(trans);
//     }
//   }

//   //      Sorteringskolonner
//   for (var i = 0; i < j_sort_detail.length; i++) {
//     if (j_sort_detail[i] != null) {
//       var trans = dom.createElement("dsortkolonne");
//       trans.setAttribute("navn", j_sort_detail[i]);
//       trans.setAttribute("sortposition", i);
//       node5.appendChild(trans);
//     }
//   }

//   //      Nøglekolonner
//   for (var i = 0; i < j_p_key_detail.length; i++) {
//     if (j_p_key_detail[i] != null) {
//       var trans = dom.createElement("dpkeykolonne");
//       trans.setAttribute("navn", j_p_key_detail[i]);
//       node5.appendChild(trans);
//     }
//   }

//   node2.appendChild(node3),
//     node2.appendChild(node4),
//     node2.appendChild(node5),
//     node.appendChild(node2);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   var j_excel_url =
//     "/ords/mastra/osa.im_trans.datatrans_excel_md_ny?i_timestamp=";

//   makeRequest(
//     j_excel_url + new Date() + '"&i_output_type=XML&i_soeg_xml=',
//     c_str,
//     c_f,
//     c_q_id
//   );
// }

// exports.submitSoegExcel() {
//   submitSoegExcelGo(50000, 1, 50000, 0, "f1", 28);
// }

// exports.submitSoegNN() {
//   submitSoegExcelGo(50000, 1, 50000, 0, "f1", 30); // genbrug af Excel-interface
// }

// exports.showContentExcel(result) {
//   var resdom = parseXml(result);
//   var fejl = exports.getError(resdom);

//   if (fejl != 0) {
//     alert(
//       "Der opstod en fejl ved eksekveringen, fejlnr=" +
//         fejl +
//         "," +
//         exports.getErrorMessage(resdom)
//     );
//   } else {
//     var rapkoe_id = resdom.getElementsByTagName("RAPKOE_ID")[0].childNodes[0]
//       .nodeValue;

//     window.open(
//       '/ords/mastra/osa.im_trans.rapkoe_excel?i_timestamp="' +
//         new Date() +
//         '"&i_rapkoe_id=' +
//         rapkoe_id,
//       "konfig",
//       "menubar=1"
//     );
//   }
// }

// exports.showContentExcelMD(result) {
//   var resdom = parseXml(result);
//   var fejl = exports.getError(resdom);

//   ventOff();

//   if (fejl != 0) {
//     alert(
//       "Der opstod en fejl ved eksekveringen, fejlnr=" +
//         fejl +
//         "," +
//         exports.getErrorMessage(resdom)
//     );
//   } else {
//     var rapkoe_id = resdom.getElementsByTagName("RAPKOE_ID")[0].childNodes[0]
//       .nodeValue;

//     window.open(
//       '/ords/mastra/osa.im_trans.rapkoe_excel_md?i_timestamp="' +
//         new Date() +
//         '"&i_rapkoe_id=' +
//         rapkoe_id,
//       "konfig",
//       "menubar=1"
//     );
//   }
// }

// exports.submitSoegExcelMD() {
//   submitSoegExcelMDGo(50000, 1, 50000, 0, "f1", 35);
// }

// exports.forrigeSaetW(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i - j_vis_raekker < 1) {
//     i = 1;
//   } else {
//     i = i - j_vis_raekker;
//   }

//   j = i + j_vis_raekker - 1;

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',25)",
//     1
//   );
// }

// exports.naesteSaetW(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekke_fra;
//   var j = i_raekke_til;
//   if (i_raekke_til < i_raekker_ialt) {
//     i = i + j_vis_raekker;
//     j = i + j_vis_raekker - 1;
//   }
//   if (i > i_raekker_ialt - j_vis_raekker) {
//     i = i_raekker_ialt - j_vis_raekker + 1;
//     j = i + j_vis_raekker - 1;
//   }
//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',25)",
//     1
//   );
// }

// exports.foersteSaetW(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = 1;
//   var j = j_vis_raekker;

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',25)",
//     1
//   );
// }

// exports.sidsteSaetW(i_raekker_ialt, i_raekke_fra, i_raekke_til, i_f) {
//   var i = i_raekker_ialt - j_vis_raekker + 1;
//   var j = i_raekker_ialt;
//   if (i <= 0) {
//     i = 1;
//   }

//   ventOn();
//   window.setTimeout(
//     "submitSoeg(" +
//       i_raekker_ialt +
//       "," +
//       i +
//       "," +
//       j +
//       "," +
//       0 +
//       ",'" +
//       i_f +
//       "',25)",
//     1
//   );
// }

// exports.shortcutKeys(i_soeg) {
//   shortcut("page_up", function() {
//     testPageUp();
//   });
//   shortcut("page_down", function() {
//     testPageDown();
//   });
//   shortcut("home", function() {
//     testHome();
//   });
//   shortcut("end", function() {
//     testEnd();
//   });
//   if (typeof i_soeg != "undefined")
//     shortcut("F9", function() {
//       i_soeg();
//     });
//   shortcut("Esc", function() {
//     window.close();
//   });
//   shortcut("F10", function() {
//     gemX();
//   });
// }

// exports.gemX() {
//   var fundet = false;
//   var i = 0;

//   while (i < j_antal_gemXX && !fundet) {
//     if (document.getElementById("GEM" + i).style.display != "none")
//       fundet = true;
//     else i = parseInt(i) + 1;
//   }

//   if (fundet) eval(unescape(document.getElementById("GEM" + i).href));
//   else if (typeof GemM != "undefined")
//     eval(unescape(document.getElementById("GEM").href));
// }

// exports.testPageUp() {
//   if (document.getElementById("forrige"))
//     document.getElementById("forrige").click();
// }

// exports.testPageDown() {
//   if (document.getElementById("naeste"))
//     document.getElementById("naeste").click();
// }

// exports.testEnd() {
//   if (document.getElementById("sidste"))
//     document.getElementById("sidste").click();
// }

// exports.testHome() {
//   if (document.getElementById("foerste"))
//     document.getElementById("foerste").click();
// }

// exports.hentOverskriftBreadcrumb(
//   i_menupunkt,
//   i_overskrift,
//   i_ret_opret,
//   i_parametre,
//   i_hjaelpetekst
// ) {
//   if (i_ret_opret) {
//     var prefix = "Ret ";
//     var prefix_k = "R: ";
//   } else {
//     var prefix = "";
//     var prefix_k = "";
//   }

//   try {
//     if (
//       window.opener /*&& window.opener.document.getElementById('breadCrumb')*/ &&
//       i_parametre != null &&
//       i_parametre.length > 1
//     )
//       document.getElementById("breadCrumb").innerHTML =
//         window.opener.document.getElementById("breadCrumb").innerHTML +
//         " > " +
//         prefix +
//         i_overskrift;
//     else
//       document.getElementById("breadCrumb").innerHTML =
//         i_menupunkt + " > " + i_overskrift;
//   } catch (err) {
//     document.getElementById("breadCrumb").innerHTML = i_overskrift;
//   }

//   document.getElementById("overskriftSpan").innerHTML = prefix + i_overskrift;

//   if (i_hjaelpetekst != null)
//     document.getElementById("overskriftSpan").innerHTML +=
//       ": " + i_hjaelpetekst + " ";

//   document.title = prefix_k + i_overskrift;
// }

// exports.checkPassword(pw) {
//   var tal = 0;
//   var az = 0;
//   var AZ = 0;
//   var spec = 0;

//   for (i = 0; i < pw.length; i++) {
//     ch = pw.substr(i, 1);
//     if (ch >= "0" && ch <= "9") {
//       tal = 1;
//     }
//     if (ch >= "a" && ch <= "z") {
//       az = 1;
//     }
//     if (ch >= "A" && ch <= "Z") {
//       AZ = 1;
//     }
//     if (ch == "&" || ch == "+") {
//       spec = 1;
//     }
//   }

//   if (tal + az + AZ + spec < 3 || pw.length < 12) {
//     return "Adgangskoden skal bestå af mindst 12 tegn, og indeholde tegn fra mindst 3 af følgende 4 grupper: a-z, A-Z, 0-9 og &+";
//   } else {
//     return "";
//   }
// }

// exports.hentUdfyldDataejer() {
//   var j_eksekver_func_url = "/ords/mastra/osa.im_trans.dataeksekver_func";

//   var dom = initXmlDoc();
//   // var root = dom.selectSingleNode("/mastra");
//   var root = selectSingleNodeMastra(dom);
//   var node = null;
//   node = dom.createElement("eksekver");
//   var node1 = dom.createElement("procedure");
//   node1.setAttribute("navn", "SYSTEM_PKG.HENT_UDFYLD_DATAEJER");

//   var trans = dom.createElement("parameter");
//   trans.setAttribute("navn", "I_DUMMY");
//   trans.setAttribute("position", "1");
//   var t = dom.createTextNode("0");
//   trans.appendChild(t);
//   node1.appendChild(trans);

//   node.appendChild(node1);
//   root.appendChild(node);
//   var c_str =
//     "<?xml version='1.0' encoding='ISO-8859-1'?>" + exports.serializeDom(dom);

//   c_result = makeRequest(
//     j_eksekver_func_url + "?i_output_type=XML&i_eksekver_xml=",
//     c_str,
//     "f1",
//     19
//   );

//   var resdom = parseXml(c_result);
//   var fejl = exports.getError(resdom);
//   if (fejl > 0) {
//     alert(getErrorMessage(resdom));
//   } else {
//     if (resdom.getElementsByTagName("DATAEJER")[0].childNodes[0])
//       return resdom.getElementsByTagName("DATAEJER")[0].childNodes[0]
//         .nodeValue;
//     else return "";
//   }
// }

// exports.urlEscape(str) {
//   ret = escape(str);
//   ret = ret.replace("+", "%2B");
//   return ret;
// }

// exports.escapeExt(input) {
//   return escape("<![CDATA[" + encodeURIComponent(input) + "]]>");
// }

// exports.hentServerNavn() {
//   return "http://vej08.vd.dk";

//   var c_path = document.URL;

//   c_path = c_path.replace("http://", "");

//   var pos = c_path.indexOf("/");

//   if (pos > 0) return "http://" + c_path.substr(0, pos);
//   else return "http://" + c_path;
// }

// exports.lpad(ContentToSize, PadLength, PadChar) {
//   var PaddedString = ContentToSize.toString();
//   var ContentToSizeTmp = ContentToSize.toString();
//   for (i = ContentToSizeTmp.length + 1; i <= PadLength; i++) {
//     PaddedString = PadChar + PaddedString;
//   }
//   return PaddedString;
// }

exports.setMastraType = (type) => {
  var c_path = document.location.origin;

  if (c_path.indexOf("mastra.vd.dk") >= 0 || c_path.indexOf("vej08.vd.dk") >= 0)
    exports.SetCookie("mastra_udv", "0");
  else 
    exports.SetCookie("mastra_udv", "1");

  exports.SetCookie("mastra_type", type);
}

exports.kmastraMenuFil = (menuText) => {
  if (menuText.indexOf("..") == 0) {
    if (exports.GetCookieExt("mastra_udv") == "1")
      return "/nytui" + menuText.replace("..", "");
    else return "/mastra/nytui" + menuText.replace("..", "");
  }
}

exports.GetCookieExt = (cookieName) => {
  var cookieVal = exports.GetCookie(cookieName);

  if (cookieVal == null) {
    var imastra_se = exports.retteMulighedCheck("IMASTRA_SE");

    if (cookieName == "mastra_type") {
      if (imastra_se) cookieVal = "i";
      else cookieVal = "k";
      exports.SetCookie("mastra_type", cookieVal);
    }

    if (cookieName == "mastra_udv") {
      cookieVal = "0";
      exports.SetCookie("mastra_udv", cookieVal);
    }
  }

  return cookieVal;
}

exports.selectSingleNodeMastra = (dom) => {
  return dom.getElementsByTagName("mastra")[0];
}

// exports.setCookieStd(cname, cvalue, exdays) {
//   var d = new Date();
//   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
//   var expires = "expires=" + d.toUTCString();
//   document.cookie = cname + "=" + cvalue + "; " + expires;
// }

// exports.getCookieStd(cname) {
//   var name = cname + "=";
//   var ca = document.cookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) == " ") c = c.substring(1);
//     if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
//   }
//   return "";
// }

// exports.marker(index, i) {
//   if (document.getElementById("MARKER" + i).checked) {
//     markerListe[markerListe.length] = { index: index };
//   } else {
//     for (var j = 0; j < markerListe.length; j++) {
//       if ((markerListe[j].index = index)) {
//         markerListe.splice(j, 1);
//         break;
//       }
//     }
//   }
// }
// }

module.exports = exports;
