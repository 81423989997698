export const OrdsApi = Object.freeze({
    QuickRequest: '/ords/mastra/osa.im_trans.get_quickrequest?i_q_id=', //j_q_url
    MenuStructure: '/ords/mastra/osa.im_trans.get_menu',
})

const XmlVariables = Object.freeze({
    MenuNodeName: 'MASTRARESULTAT'
})

export default {
    OrdsApi,
    XmlVariables
}