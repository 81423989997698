export const AppTypeEnum = Object.freeze({
    iMastra: 'imastra',
    kMastra: 'kmastra',
    Noegletal: 'noegletal',
    Hastrid: 'hastrid'
});

export const AppTypeQuerySafeLabel = new Map([
    [AppTypeEnum.iMastra, 'iMastra'],
    [AppTypeEnum.kMastra, 'kMastra'],
    [AppTypeEnum.Noegletal, 'Noegletal'],
    [AppTypeEnum.Hastrid, 'Hastrid']
])

export const AppTypeQuerySafeLabelToEnum = new Map([
    ['iMastra', AppTypeEnum.iMastra],
    ['kMastra', AppTypeEnum.kMastra],
    ['Noegletal', AppTypeEnum.Noegletal],
    ['Hastrid', AppTypeEnum.Hastrid]
])

export const AppTypeLabel = new Map([
    [AppTypeEnum.iMastra, 'iMastra'],
    [AppTypeEnum.kMastra, 'kMastra'],
    [AppTypeEnum.Noegletal, 'Nøgletalsdatabasen'],
    [AppTypeEnum.Hastrid, 'Hastrid']
])

export const AppTypeToLink = new Map([
    [AppTypeEnum.iMastra, '../main/mastra.html'],
    [AppTypeEnum.kMastra, '../main/kmastra.html'],
    [AppTypeEnum.Noegletal, '../main/noegletal.html'],
    [AppTypeEnum.Hastrid, '../main/hastrid.html']
]);