<template>
    <div>
        <b-row class="header">
            <b-col class="text-white col-sm-6">
                <a v-bind:href="AppTypeToLink.get(app)" class=" text-reset">
                    <div class="mt-1 header__sub-title">
                        Vejsektoren.dk
                    </div>
                    <div class="header__title">
                        {{AppTypeLabel.get(app)}}
                    </div>
                </a>
            </b-col>

            <b-col class="col-sm-6 ">
                <div class="float-right">
                    <div class="header__sub-title">
                        <div class="mt-1">
                            <span id="bruger"></span>
                        </div>
                        <div class="d-flex justify-content-end mt-2" v-if="app != AppTypeEnum.Hastrid">
                            <a class="helpLink mr-2 text-reset" @click="hjaelpStart" href="#">Hjælp</a>
                            <a class="helpLink text-reset" title="Lukker forbindelsen til Mastra"  @click="logoff" href="#">Log af</a>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <vd-menu :app="app" />
    </div>
</template>

<script>
    import { AppTypeEnum, AppTypeLabel, AppTypeToLink } from '@shared/enums/appTypeEnum';
    import vdMenu from '@shared/components/layout/vdMenu';
    import { setMastraType, makeRequest, GetCookieExt } from '@shared/utils/AjaxUtil.js';

    export default {
        name: 'vdHeader',
        props: {
            app: {
                type: String,
                required: true
            }
        },
        components: {
            vdMenu
        },
        data: function() {
            return {
                AppTypeEnum,
                AppTypeLabel,
                AppTypeToLink
            }
        },
        methods: {
            initPage() {
                switch (this.app){
                    case this.AppTypeEnum.iMastra:
                        setMastraType('i');
                        break;
                    case this.AppTypeEnum.kMastra:
                        setMastraType('k');
                        break;
                    case this.AppTypeEnum.Noegletal:
                        setMastraType('n');
                        break;
                    default:
                        break;
                }

                makeRequest(this.$variables.OrdsApi.QuickRequest, "bruger", "f1", 3);
            },
            logoff() {
                if (
                    window.navigator.userAgent.indexOf("Android") > -1 ||
                    window.navigator.userAgent.indexOf("iOS") > -1
                ) {
                    alert("Funktionen virker ikke på en mobil enhed.");
                    return;
                }

                window.location = "/ords/mastra/osa.logoff";
            },
            hjaelpStart() {
                var mastra_type = GetCookieExt("mastra_type");

                if (['i', 'k', 'n'].includes(mastra_type)){
                    window.open(
                        "../main/hjaelp.html",
                        "hjaelp",
                        "status=0, toolbar=0, menubar=0, height=350, width=500, resizable=1"
                    );
                } else {
                    alert("Hjælp menuen er ikke tilgængelig på " + this.AppTypeLabel.get(this.app));
                }
            }
        },
        created() {
        },
        
        mounted() {
            this.initPage();
        }
    }
</script>

<style lang="scss" scoped>
    @import '@shared/styles/mastra-func.scss';

    .header {
        height: 40px;
        background: url('~@shared/assets/mastra.png') center / cover no-repeat;

        &__title {
            @include font-size(24px);
            line-height: 1;
        }
        &__sub-title {
            @include font-size(1em);
            line-height: 0.8;
        }
    }
</style>
