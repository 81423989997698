const axios = require('axios');
const xmlParser = require('fast-xml-parser');
import { AppTypeEnum, AppTypeQuerySafeLabel } from '@shared/enums/appTypeEnum';
import variables from '@/variables';


const xmlParseOptions = {
    arrayMode: true,
    ignoreAttributes: true,
    ignoreNameSpace: true
};

export default class MenuUtil {
    async GetMenuStructure(appType) {
        var url = variables.OrdsApi.MenuStructure;
        var i_app = null;
        var xmlNodeName = variables.XmlVariables.MenuNodeName;

        if (appType === AppTypeEnum.iMastra ||
            appType === AppTypeEnum.kMastra ||
            appType === AppTypeEnum.Noegletal) {
            i_app = AppTypeQuerySafeLabel.get(appType);
        }

        var parameters = '?i_div_id=mainMenu';

        if (process.env.NODE_ENV === 'debug')
            parameters += '&i_test=1';

        if (i_app)
            parameters += '&i_app=' + i_app;

        const fallbackMenu = [
            {
                "MENU_ID": 1,
                "FAR_MENU_ID": 0,
                "BESKRIVELSE": "Login",
                "URL": "javascript:window.location.href=window.location.href",
            },
            {
                "MENU_ID": 0,
                "FAR_MENU_ID": 0,
                "BESKRIVELSE": "Glemt adgangskode",
                "URL": "../system/GlemtAdgangskode.html",
            }
        ];

        if(typeof loggedin == 'undefined' || loggedin){
        return await axios.get(url + parameters)
            .then(function(response) {
                if(xmlParser.validate(response.data) != true)
                    console.error("Menu XML not valid");

                var json = xmlParser.parse(response.data, xmlParseOptions);
                
                var mainMenuJson = json[xmlNodeName][0][xmlNodeName][0]['mainMenu'];

                //Populate child elemenets
                if (mainMenuJson && mainMenuJson.length > 0) {
                    var mainMenu = [];
                    
                    var apos = new RegExp("&apos;", 'g');
                    var amp = new RegExp("&amp;", 'g');

                    for (var i = 0; i < mainMenuJson.length; i++) {
                        if(mainMenuJson[i].NIVEAU != 0){ // Ignore root element 
                            // Parse special characters
                            mainMenuJson[i].URL = mainMenuJson[i].URL
                                .replace(apos, "'")
                                .replace(amp, "&")

                            if (mainMenuJson[i].NIVEAU === 1){
                                mainMenu.push(mainMenuJson[i])
                            } else {
                                for (var j = 0; j < mainMenu.length; j++){
                                    if(mainMenu[j].MENU_ID === mainMenuJson[i].FAR_MENU_ID) {
                                        if(!mainMenu[j].children){
                                            mainMenu[j].children = [];
                                        }
                                        
                                        mainMenu[j].children.push(mainMenuJson[i])
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    
                    //Reverse order of menu items
                    return mainMenu.slice().reverse();
                } else {
                    return fallbackMenu;
                }
            })
            .catch(function(error) {
                console.error(error);
                loggedin = false;
                return fallbackMenu;
            });
        } else {
            return fallbackMenu;
        }
    }
}