import Vue from 'vue';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// let bootstrapVueConfig = {
//   BDropdown: {
//     variant: 'primary'
//   },
//   BBadge: {
//     variant: 'dark'
//   },
//   BButton: {
//     variant: 'primary'
//   },
//   BModal: {
//     okVariant: 'secondary',
//     cancelVariant: 'dark'
//   }
// };

Vue.use(BootstrapVue); //, bootstrapVueConfig
Vue.use(IconsPlugin);
