<template>
    <b-row>
        <b-col>
            <div>
                <p class="font-weight-bold mb-1">
                    Nyheder
                </p>

                <div class="news-container">
                    <div class="h-100">
                        <div class="accordion mh-100" role="tablist">
                            <b-card no-body class="mb-1" v-for="item in newsItems" :key="item.id">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-link v-b-toggle="'collapse-'+item.id" class="text-dark">
                                        <p class="font-weight-bold my-0 pl-1">{{ item.heading }}</p>
                                        <span class="pl-1">{{ Intl.DateTimeFormat([], {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(item.date)) }}</span>

                                        <span class="when-opened float-right">
                                            <b-icon icon="chevron-down" aria-hidden="true" />
                                        </span>
                                        <span class="when-closed float-right">
                                            <b-icon icon="chevron-right" aria-hidden="true" />
                                        </span>
                                    </b-link>
                                </b-card-header>
                                <b-collapse :id="'collapse-' + item.id" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text v-html="item.text">I start opened because <code>visible</code> is <code>true</code></b-card-text>
                                        
                                        <div v-for="file in item.files" :key="file.id">
                                            <b-button variant="link" :key="file.id" @click="downloadFile(file.id)"> {{file.filename}}</b-button>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
        
    </b-row>
</template>

<script>
    import mime from 'mime'
    import { AppTypeQuerySafeLabel } from '@shared/enums/appTypeEnum'

    export default {
        name: "vdNewsviewer",
        data: function() {
            return {
                AppTypeQuerySafeLabel,
                newsItems: [
                    {
                        id: 1,
                        heading: "Mastra serveren er nede. Prøv venligst igen senere",
                        date: Date(),
                        text: '<p style="margin-top: 0;">Grundet at mastra serveren er nede, kan der ikke hentes nyheder på nuværende tidpunkt.</p>' +
                                '<p style="margin-bottom: 0;">Mvh Team Mastra</p>',
                        files:[]
                    }
                ]
            }    
        },
        methods: {
            fetchNews: function () {
                const newsUrl = "/ords/mastra/apex_public_user.news_pkg.Get_news_items?i_site=" + this.AppTypeQuerySafeLabel.get(this.app);
                this.$http.get(newsUrl, {
                        headers:{ Authorization:""}
                }).then((result) => {
                    this.newsItems = result.data.newsItems;
                })
            },
            downloadFile: function(file_id){
                const downloadUrl = "/ords/mastra/apex_public_user.news_pkg.Get_news_file?i_id=" + file_id;
                this.$http.get(downloadUrl, {
                        headers:{ Authorization:""}
                }).then((result) => {
                    const mimeType = mime.getType(result.data.filename);
                    const downloadLink = document.createElement('a');

                    downloadLink.href = `data:${mimeType};base64,${result.data.file}`;
                    downloadLink.download = result.data.filename;
                    downloadLink.click();
                })
            }
        },
        props: {
            app: {
                type: String,
                required: true
            }
        },
        created: function() {
            // When backend is ready, implement correct url and remove dummy newsitems
            this.fetchNews();
        }
    }
</script>

<style lang="scss" scoped>
    .news-container {
        height: 350px;
        overflow: auto;
    }

    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>