<template>
    <b-row>
        <b-col class="d-flex justify-content-end">
            <vd-icon class="vd-footer-logo"/>
        </b-col>
    </b-row>
</template>

<script>
    import vdIcon from '@shared/components/images/vdLogo';

    export default {
        name: 'vdFooter',
        components: {
            vdIcon
        },
        data: function() {
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .vd-footer-logo {
        max-width: 150px;
        max-height: 50px;
    }
</style>